<template>
  <div class="flex h-[100dvh] overflow-hidden">
    <!-- Sidebar -->
    <Sidebar :sidebarOpen="sidebarOpen" @close-sidebar="sidebarOpen = false" />

    <!-- Content area -->
    <div
      class="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden bg-white dark:bg-slate-900"
    >
      <!-- Site header -->
      <Header
        :sidebarOpen="sidebarOpen"
        @toggle-sidebar="sidebarOpen = !sidebarOpen"
      />

      <main class="grow">
        <div class="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
          <!-- Page header -->
          <div class="sm:flex sm:justify-between sm:items-center mb-8">
            <!-- Left: Title -->
            <div class="mb-4 sm:mb-0">
              <h1
                class="text-2xl md:text-3xl text-slate-800 dark:text-slate-100 font-bold"
              >
                <font-awesome-icon icon="fa-duotone fa-list" class="mr-2" />
                My Lists
              </h1>
            </div>

            <!-- Right: Actions -->
            <div
              v-if="lists && lists.length"
              class="grid grid-flow-col sm:auto-cols-max justify-start sm:justify-end gap-2"
            >
              <!-- Search form -->
              <SearchForm />
              <!-- Add list button -->
              <button class="btn bg-indigo-500 hover:bg-indigo-600 text-white">
                <font-awesome-icon icon="fa-duotone fa-plus" />
                <span class="ml-2">Add List</span>
              </button>
            </div>
          </div>

          <div v-if="lists && lists.length" class="grid grid-cols-12 gap-6">
            <ListTile v-for="list in lists" :key="list.id" :list="list" />
          </div>

          <div v-else class="border-t border-slate-200 dark:border-slate-700">
            <div class="max-w-2xl m-auto mt-16">
              <div class="text-center px-4">
                <div
                  class="inline-flex items-center justify-center w-16 h-16 rounded-full bg-gradient-to-t from-slate-200 to-slate-100 dark:from-slate-700 dark:to-slate-800 mb-4"
                >
                  <font-awesome-icon
                    icon="fa-duotone fa-list"
                    class="w-8 h-8"
                  />
                </div>
                <h2
                  class="text-2xl text-slate-800 dark:text-slate-100 font-bold mb-2"
                >
                  You don't have any lists yet.
                </h2>
                <div class="mb-6">
                  Use lists to organize your shopping experience and get
                  optimized routes.
                </div>
                <button
                  class="btn bg-indigo-500 hover:bg-indigo-600 text-white"
                >
                  <font-awesome-icon icon="fa-duotone fa-plus" />
                  <span class="ml-2">Add List</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  </div>
</template>

<script setup>
import { ref } from "vue";
import { storeToRefs } from "pinia";

import { useUserStore } from "../../stores/user";

import SearchForm from "../../components/SearchForm.vue";
import Sidebar from "../../partials/Sidebar.vue";
import Header from "../../partials/Header.vue";
import ListTile from "../../partials/lists/ListTile.vue";

const userStore = useUserStore();

const { lists } = storeToRefs(userStore);
const sidebarOpen = ref(false);
</script>

<template>
  <router-view />
</template>

<script setup>
import { watch, onMounted } from "vue";
import { useAuth0 } from "@auth0/auth0-vue";
import { storeToRefs } from "pinia";
import { useGeolocation } from "vue-composable";

import { getAccessToken } from "./plugins/auth0";

import { bus } from "./events/bus";
import { reverseGeocode } from "./services/api";
import { socket } from "./services/events";

import { useBackendStore } from "./stores/backend";
import { useCategoryStore } from "./stores/category";
import { useGeolocationStore } from "./stores/geolocation";
import { useUserStore } from "./stores/user";

import "./charts/ChartjsConfig";

// Use stores and composable
const { isAuthenticated } = useAuth0();

const backendStore = useBackendStore();
const categoryStore = useCategoryStore();
const geolocationStore = useGeolocationStore();
const userStore = useUserStore();

const { socketAuthenticated } = storeToRefs(backendStore);

bus.on("socket-connected", async () => {
  console.log("[BUS] Connected to socket");
  if (isAuthenticated.value && !socketAuthenticated.value) {
    console.log("[BUS] Authenticating socket");
    const token = await getAccessToken();
    socket.emit("authenticate", token);
  }
});

bus.on("socket-disconnected", () => {
  console.log("[BUS] Disconnected from socket");
  if (socketAuthenticated.value) {
    console.log("[BUS] Marking socket as unauthenticated");
    socketAuthenticated.value = false;
  }
});

bus.on("socket-authenticated", () => {
  console.log("[BUS] Socket authenticated");
  if (!socketAuthenticated.value) {
    console.log("[BUS] Marking socket as authenticated");
    socketAuthenticated.value = true;
  }
});

const {
  coords: geolocationCoords,
  error: geolocationError,
  refresh: geolocationRefresh,
  supported: geolocationSupported,
} = useGeolocation({ immediate: false });

// Refs from stores
const {
  address: geolocationStoreAddress,
  allowed: geolocationStoreAllowed,
  coords: geolocationStoreCoords,
} = storeToRefs(geolocationStore);
const { details: userStoreDetails } = storeToRefs(userStore);

// Set geolocation supported
geolocationStore.supported = geolocationSupported;

// Watchers
watch(geolocationCoords, async (newVal) => {
  console.log("GeoLocation Coords changed to", newVal);
  if (newVal) {
    geolocationStore.coords = newVal;
  }
});

watch(geolocationError, async (newVal) => {
  console.log("GeoLocation Error changed to", newVal);
  if (newVal) {
    geolocationStore.error = newVal;
  }
});

watch(geolocationStoreAddress, async (newVal) => {
  console.log("GeoLocationStore Address changed to", newVal);
});

watch(geolocationStoreAllowed, async (newVal) => {
  console.log("GeoLocationStore Allowed changed to", newVal);
  if (newVal && geolocationStore.allowed && geolocationStore.supported) {
    geolocationRefresh();
  }
});

watch(geolocationStoreCoords, async (newVal) => {
  console.log("GeoLocationStore Coords changed to", newVal);
  const location = await reverseGeocode(newVal.latitude, newVal.longitude);
  if (
    location &&
    location?.formatted_address &&
    location?.formatted_address?.length > 0
  ) {
    console.log("Reverse Geocoded location", location.formatted_address);
    geolocationStore.address = location.formatted_address;
  }
});

watch(isAuthenticated, async (newVal) => {
  console.log("IsAuthenticated changed to", newVal);
  if (newVal) {
    const tasks = [
      categoryStore.fetchCategories(),
      userStore.fetchDetails(),
      userStore.fetchFavouriteProducts(),
      userStore.fetchFavouriteStores(),
      userStore.fetchLists(),
      userStore.fetchNotifications(),
    ];
    if (socket) {
      const token = await getAccessToken();
      console.log("Authenticating socket with token", token);
      socket.emit("authenticate", token);
    }
    await Promise.all(tasks);
  }
});

watch(userStoreDetails, async (newVal) => {
  // Geolocation is allowed if the user has set the WebappGeoLocation flag
  geolocationStore.allowed = newVal?.UserMetadata?.WebappGeoLocation === true;
});

onMounted(() => {
  console.log(
    "Is GeoLocation supported?",
    geolocationSupported.value ? "Yes" : "No",
  );
});

// Other setup logic if needed
</script>

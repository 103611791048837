import { ref } from "vue";
import axios from "axios";

// Fetch function to load metadata from the server
const fetchMetadata = async (url, metadataRef) => {
  try {
    const response = await axios.get(url);
    metadataRef.value = response.data;
  } catch (error) {
    console.error(`Could not fetch metadata: ${error}`);
  }
};

export const createMetadata = (options = {}) => {
  // Set default options if none provided
  options = Object.assign(
    {
      url: `${import.meta.env.VITE_METADATA_URL}/metadata.json`,
    },
    options,
  );

  // Create a reactive variable
  const metadata = ref({});

  // Fetch metadata from server
  fetchMetadata(options.url, metadata);

  const plugin = {
    install: (app) => {
      // Make the `useMetadata` function available globally
      app.provide("useMetadata", () => metadata);
    },
  };

  return plugin;
};

<template>
  <div
    class="col-span-full sm:col-span-6 xl:col-span-4 bg-white dark:bg-slate-800 shadow-lg rounded-sm border border-slate-200 dark:border-slate-700"
  >
    <div class="flex flex-col h-full">
      <!-- Card top -->
      <div class="grow p-5">
        <div class="flex justify-between items-start">
          <!-- Name -->
          <header>
            <div class="flex mb-2">
              <div class="mt-1 pr-1">
                <router-link
                  class="inline-flex text-slate-800 dark:text-slate-100 hover:text-slate-900 dark:hover:text-white"
                  :to="`/lists/${list.id}`"
                >
                  <h2 class="text-xl leading-snug justify-center font-semibold">
                    {{ list.Name }}
                  </h2>
                </router-link>
                <div class="flex items-center">
                  <span class="text-xs">{{
                    pluralize("product", productsCount, true)
                  }}</span>
                </div>
              </div>
            </div>
          </header>
          <!-- Menu button -->
          <EditMenu align="right" class="relative inline-flex shrink-0">
            <li>
              <router-link
                class="font-medium text-sm text-slate-600 dark:text-slate-300 hover:text-slate-800 dark:hover:text-slate-200 flex py-1 px-3"
                to="#0"
                >Option 1</router-link
              >
            </li>
            <li>
              <router-link
                class="font-medium text-sm text-slate-600 dark:text-slate-300 hover:text-slate-800 dark:hover:text-slate-200 flex py-1 px-3"
                to="#0"
                >Option 2</router-link
              >
            </li>
            <li>
              <router-link
                class="font-medium text-sm text-rose-500 hover:text-rose-600 flex py-1 px-3"
                to="#0"
                >Remove</router-link
              >
            </li>
          </EditMenu>
        </div>
      </div>
      <!-- Card footer -->
      <div class="border-t border-slate-200 dark:border-slate-700">
        <div class="flex divide-x divide-slate-200 dark:divide-slate-700">
          <router-link
            class="block flex-1 text-center text-sm text-indigo-500 hover:text-indigo-600 dark:hover:text-indigo-400 font-medium px-3 py-4"
            :to="`/lists/${list.id}`"
          >
            <div class="flex items-center justify-center">
              <font-awesome-icon
                icon="fa-regular fa-eye"
                class="w-4 h-4 mr-2"
              />
              <span>Show</span>
            </div>
          </router-link>
          <router-link
            class="block flex-1 text-center text-sm text-slate-600 hover:text-slate-800 dark:text-slate-300 dark:hover:text-slate-200 font-medium px-3 py-4 group"
            to="/settings/account"
          >
            <div class="flex items-center justify-center">
              <font-awesome-icon
                icon="fa-regular fa-pencil"
                class="w-4 h-4 mr-2"
              />
              <span>Edit</span>
            </div>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted } from "vue";
import pluralize from "pluralize";

import { countListProducts } from "../../services/api/";
import EditMenu from "../../components/DropdownEditMenu.vue";

// Define props
const props = defineProps({
  list: Object,
});
const productsCount = ref(0);

onMounted(async () => {
  const { count } = await countListProducts(props.list.id);
  productsCount.value = count;
});
</script>

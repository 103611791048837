<template>
  <div class="flex flex-col sm:flex-row sm:items-center sm:justify-between">
    <nav
      class="mb-4 sm:mb-0 sm:order-1"
      role="navigation"
      aria-label="Navigation"
    >
      <ul class="flex justify-center">
        <li class="ml-3 first:ml-0">
          <button
            :class="buttonClasses(currentPage === 1)"
            @click="goToPreviousPage"
            :disabled="currentPage === 1"
          >
            &lt;- Previous
          </button>
        </li>
        <li class="ml-3 first:ml-0">
          <button
            :class="buttonClasses(currentPage === totalPages)"
            @click="goToNextPage"
            :disabled="currentPage === totalPages"
          >
            Next -&gt;
          </button>
        </li>
      </ul>
    </nav>
    <div
      class="text-sm text-slate-500 dark:text-slate-400 text-center sm:text-left"
    >
      Showing
      <span class="font-medium text-slate-600 dark:text-slate-300">{{
        startItem?.toLocaleString()
      }}</span>
      to
      <span class="font-medium text-slate-600 dark:text-slate-300">{{
        endItem?.toLocaleString()
      }}</span>
      of
      <span class="font-medium text-slate-600 dark:text-slate-300">{{
        totalItems?.toLocaleString()
      }}</span>
      results
    </div>
  </div>
</template>

<script>
export default {
  name: "PaginationClassic",
  props: {
    currentPage: {
      type: Number,
      required: true,
    },
    totalItems: {
      type: Number,
      required: true,
    },
    itemsPerPage: {
      type: Number,
      required: true,
    },
  },
  computed: {
    totalPages() {
      return Math.ceil(this.totalItems / this.itemsPerPage);
    },
    startItem() {
      return (this.currentPage - 1) * this.itemsPerPage + 1;
    },
    endItem() {
      return Math.min(this.currentPage * this.itemsPerPage, this.totalItems);
    },
  },
  methods: {
    buttonClasses(isDisabled) {
      return [
        "btn",
        "bg-white",
        "dark:bg-slate-800",
        "border-slate-200",
        "dark:border-slate-700",
        "hover:border-slate-300",
        "dark:hover:border-slate-600",
        "text-indigo-500",
        isDisabled ? "text-slate-300" : "text-indigo-500",
        isDisabled ? "dark:text-slate-600" : "dark:text-indigo-500",
      ];
    },
    goToPreviousPage() {
      if (this.currentPage > 1) {
        this.$emit("change-page", this.currentPage - 1);
      }
    },
    goToNextPage() {
      if (this.currentPage < this.totalPages) {
        this.$emit("change-page", this.currentPage + 1);
      }
    },
  },
};
</script>

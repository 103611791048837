<template>
  <div class="relative inline-flex">
    <button
      ref="trigger"
      class="w-8 h-8 flex items-center justify-center bg-slate-100 hover:bg-slate-200 dark:bg-slate-700 dark:hover:bg-slate-600/80 rounded-full"
      :class="{ 'bg-slate-200': dropdownOpen }"
      aria-haspopup="true"
      @click.prevent="dropdownOpen = !dropdownOpen"
      :aria-expanded="dropdownOpen"
    >
      <span class="sr-only">Notifications</span>
      <font-awesome-icon icon="fa-duotone fa-bell" class="w-4 h-4" />
      <div
        v-if="unreadNotificationsCount > 0"
        class="absolute top-0 right-0 w-2.5 h-2.5 bg-rose-500 border-2 border-white dark:border-[#182235] rounded-full"
      ></div>
    </button>
    <transition
      enter-active-class="transition ease-out duration-200 transform"
      enter-from-class="opacity-0 -translate-y-2"
      enter-to-class="opacity-100 translate-y-0"
      leave-active-class="transition ease-out duration-200"
      leave-from-class="opacity-100"
      leave-to-class="opacity-0"
    >
      <div
        v-show="dropdownOpen"
        class="max-h-[75svh] origin-top-right z-10 absolute top-full -mr-48 sm:mr-0 min-w-80 bg-white dark:bg-slate-800 border border-slate-200 dark:border-slate-700 py-1.5 rounded shadow-lg overflow-auto mt-1"
        :class="align === 'right' ? 'right-0' : 'left-0'"
      >
        <div
          class="text-xs font-semibold text-slate-400 dark:text-slate-500 uppercase pt-1.5 pb-2 px-4"
        >
          Notifications
        </div>
        <ul
          v-if="unreadNotifications.length > 0"
          ref="dropdown"
          @focusin="dropdownOpen = true"
          @focusout="dropdownOpen = false"
        >
          <DropdownNotification
            v-for="notification in unreadNotifications"
            :key="notification.id"
            :notification="notification"
          />
        </ul>
        <div
          v-else
          ref="dropdown"
          class="text-center"
          @focusin="dropdownOpen = true"
          @focusout="dropdownOpen = false"
        >
          <div class="text-sm text-slate-400 dark:text-slate-500 px-4 py-6">
            You're all caught up!
          </div>
          <font-awesome-icon
            icon="fa-duotone fa-island-tropical"
            class="w-16 h-16 text-slate-300 dark:text-slate-500 mx-auto px-4 pb-6"
          />
        </div>
      </div>
    </transition>
  </div>
</template>

<script setup>
import { ref, onMounted, onUnmounted } from "vue";
import { storeToRefs } from "pinia";

import { useUserStore } from "../stores/user";
import DropdownNotification from "./DropdownNotification.vue";

defineProps({
  align: String,
});

const userStore = useUserStore();
const dropdownOpen = ref(false);
const trigger = ref(null);
const dropdown = ref(null);
const { unreadNotifications, unreadNotificationsCount } =
  storeToRefs(userStore);

// close on click outside
const clickHandler = ({ target }) => {
  if (
    !dropdownOpen.value ||
    dropdown.value.contains(target) ||
    trigger.value.contains(target)
  )
    return;
  dropdownOpen.value = false;
};

// close if the esc key is pressed
const keyHandler = ({ keyCode }) => {
  if (!dropdownOpen.value || keyCode !== 27) return;
  dropdownOpen.value = false;
};

onMounted(() => {
  document.addEventListener("click", clickHandler);
  document.addEventListener("keydown", keyHandler);
});

onUnmounted(() => {
  document.removeEventListener("click", clickHandler);
  document.removeEventListener("keydown", keyHandler);
});
</script>

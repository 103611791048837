<template>
  <div class="col-span-full bg-white dark:bg-slate-800 shadow-lg rounded-sm border border-slate-200 dark:border-slate-700">
    <header class="px-5 py-4 border-b border-slate-100 dark:border-slate-700">
      <h2 class="font-semibold text-slate-800 dark:text-slate-100">Market Trends</h2>
    </header>
    <div class="p-3">

      <!-- Table -->
      <div class="overflow-x-auto">
        <table class="table-auto w-full dark:text-slate-300">
          <!-- Table header -->
          <thead class="text-xs uppercase text-slate-400 dark:text-slate-500 bg-slate-50 dark:bg-slate-700 dark:bg-opacity-50 rounded-sm">
            <tr>
              <th class="p-2 whitespace-nowrap">
                <div class="font-semibold text-left">Market</div>
              </th>
              <th class="p-2 whitespace-nowrap">
                <div class="font-semibold text-left">Mkt Cap</div>
              </th>
              <th class="p-2 whitespace-nowrap">
                <div class="font-semibold text-left">Chart</div>
              </th>
              <th class="p-2 whitespace-nowrap">
                <div class="font-semibold text-left">Price</div>
              </th>
              <th class="p-2 whitespace-nowrap">
                <div class="font-semibold text-left">Chg. (24h)</div>
              </th>
            </tr>
          </thead>
          <!-- Table body -->
          <tbody class="text-sm font-medium divide-y divide-slate-100 dark:divide-slate-700">
            <!-- Row -->
            <tr>
              <td class="p-2 whitespace-nowrap md:w-1/2">
                <div class="flex items-center">
                  <svg class="shrink-0 mr-2 sm:mr-3" width="36" height="36" viewBox="0 0 36 36">
                    <circle fill="#1DA1F2" cx="18" cy="18" r="18" />
                    <path d="M26 13.5c-.6.3-1.2.4-1.9.5.7-.4 1.2-1 1.4-1.8-.6.4-1.3.6-2.1.8-.6-.6-1.5-1-2.4-1-1.7 0-3.2 1.5-3.2 3.3 0 .3 0 .5.1.7-2.7-.1-5.2-1.4-6.8-3.4-.3.5-.4 1-.4 1.7 0 1.1.6 2.1 1.5 2.7-.5 0-1-.2-1.5-.4 0 1.6 1.1 2.9 2.6 3.2-.3.1-.6.1-.9.1-.2 0-.4 0-.6-.1.4 1.3 1.6 2.3 3.1 2.3-1.1.9-2.5 1.4-4.1 1.4H10c1.5.9 3.2 1.5 5 1.5 6 0 9.3-5 9.3-9.3v-.4c.7-.5 1.3-1.1 1.7-1.8z" fill="#FFF" fill-rule="nonzero" />
                  </svg>
                  <div>
                    <div class="text-slate-800 dark:text-slate-100 uppercase">Twtr</div>
                    <div class="text-xs text-slate-500">Twitter Inc.</div>
                  </div>
                </div>
              </td>
              <td class="p-2 whitespace-nowrap">
                <div class="font-normal text-left">33.94B</div>
              </td>
              <td class="p-2 whitespace-nowrap">
                <!-- Chart built with Chart.js 3 -->
                <!-- Check out src/js/components/fintech-card-14.js for config -->
                <div class="w-[96px]">
                  <!-- Change the height attribute to adjust the chart height -->
                  <LineChart :data="chartData[0]" width="96" height="32" />
                </div>
              </td>
              <td class="p-2 whitespace-nowrap">
                <div class="text-left">$43.07</div>
              </td>
              <td class="p-2 whitespace-nowrap">
                <div class="text-left text-emerald-500">+$4.20 (4%)</div>
              </td>
            </tr>
            <!-- Row -->
            <tr>
              <td class="p-2 whitespace-nowrap md:w-1/2">
                <div class="flex items-center">
                  <svg class="shrink-0 mr-2 sm:mr-3" width="36" height="36" viewBox="0 0 36 36">
                    <circle fill="#1877F2" cx="18" cy="18" r="18" />
                    <path d="M16.023 26 16 19h-3v-3h3v-2c0-2.7 1.672-4 4.08-4 1.153 0 2.144.086 2.433.124v2.821h-1.67c-1.31 0-1.563.623-1.563 1.536V16H23l-1 3h-2.72v7h-3.257Z" fill="#FFF" fill-rule="nonzero" />
                  </svg>
                  <div>
                    <div class="text-slate-800 dark:text-slate-100 uppercase">Fb</div>
                    <div class="text-xs text-slate-500">Meta Inc.</div>
                  </div>
                </div>
              </td>
              <td class="p-2 whitespace-nowrap">
                <div class="font-normal text-left">903.71B</div>
              </td>
              <td class="p-2 whitespace-nowrap">
                <!-- Chart built with Chart.js 3 -->
                <!-- Check out src/js/components/fintech-card-14.js for config -->
                <div class="w-[96px]">
                  <!-- Change the height attribute to adjust the chart height -->
                  <LineChart :data="chartData[1]" width="96" height="32" />
                </div>
              </td>
              <td class="p-2 whitespace-nowrap">
                <div class="text-left">$324.81</div>
              </td>
              <td class="p-2 whitespace-nowrap">
                <div class="text-left text-emerald-500">+$12.20 (3.7%)</div>
              </td>
            </tr>
            <!-- Row -->
            <tr>
              <td class="p-2 whitespace-nowrap md:w-1/2">
                <div class="flex items-center">
                  <svg class="shrink-0 mr-2 sm:mr-3" width="36" height="36" viewBox="0 0 36 36">
                    <circle fill="#EA4335" cx="18" cy="18" r="18" />
                    <path d="M18 17v2.4h4.1c-.2 1-1.2 3-4 3-2.4 0-4.3-2-4.3-4.4 0-2.4 2-4.4 4.3-4.4 1.4 0 2.3.6 2.8 1.1l1.9-1.8C21.6 11.7 20 11 18.1 11c-3.9 0-7 3.1-7 7s3.1 7 7 7c4 0 6.7-2.8 6.7-6.8 0-.5 0-.8-.1-1.2H18Z" fill="#FFF" fill-rule="nonzero" />
                  </svg>
                  <div>
                    <div class="text-slate-800 dark:text-slate-100 uppercase">Googl</div>
                    <div class="text-xs text-slate-500">Alphabet Inc.</div>
                  </div>
                </div>
              </td>
              <td class="p-2 whitespace-nowrap">
                <div class="font-normal text-left">1.70T</div>
              </td>
              <td class="p-2 whitespace-nowrap">
                <!-- Chart built with Chart.js 3 -->
                <!-- Check out src/js/components/fintech-card-14.js for config -->
                <div class="w-[96px]">
                  <!-- Change the height attribute to adjust the chart height -->
                  <LineChart :data="chartData[2]" width="96" height="32" />
                </div>
              </td>
              <td class="p-2 whitespace-nowrap">
                <div class="text-left">$2,860.96</div>
              </td>
              <td class="p-2 whitespace-nowrap">
                <div class="text-left text-rose-500">-$12.20 (3.7%)</div>
              </td>
            </tr>
            <!-- Row -->
            <tr>
              <td class="p-2 whitespace-nowrap md:w-1/2">
                <div class="flex items-center">
                  <svg class="shrink-0 mr-2 sm:mr-3" width="36" height="36" viewBox="0 0 36 36">
                    <circle fill="#1E293B" cx="18" cy="18" r="18" />
                    <path d="M18.725 10.48C19.853 9.006 21.422 9 21.422 9s.233 1.384-.888 2.717c-1.197 1.424-2.557 1.191-2.557 1.191s-.256-1.12.748-2.429Zm-.605 3.398c.58 0 1.658-.79 3.06-.79 2.414 0 3.364 1.7 3.364 1.7s-1.858.94-1.858 3.221c0 2.573 2.314 3.46 2.314 3.46s-1.617 4.506-3.802 4.506c-1.003 0-1.783-.67-2.84-.67-1.078 0-2.147.695-2.843.695C13.52 26 11 21.725 11 18.29c0-3.381 2.133-5.155 4.134-5.155 1.3 0 2.31.743 2.986.743Z" fill="#FFF" fill-opacity=".9" fill-rule="nonzero" />
                  </svg>
                  <div>
                    <div class="text-slate-800 dark:text-slate-100 uppercase">Aapl</div>
                    <div class="text-xs text-slate-500">Apple Inc.</div>
                  </div>
                </div>
              </td>
              <td class="p-2 whitespace-nowrap">
                <div class="font-normal text-left">2.77T</div>
              </td>
              <td class="p-2 whitespace-nowrap">
                <!-- Chart built with Chart.js 3 -->
                <!-- Check out src/js/components/fintech-card-14.js for config -->
                <div class="w-[96px]">
                  <!-- Change the height attribute to adjust the chart height -->
                  <LineChart :data="chartData[3]" width="96" height="32" />
                </div>
              </td>
              <td class="p-2 whitespace-nowrap">
                <div class="text-left">$168.55</div>
              </td>
              <td class="p-2 whitespace-nowrap">
                <div class="text-left text-emerald-500">+$7.44 (1.4%)</div>
              </td>
            </tr>
            <!-- Row -->
            <tr>
              <td class="p-2 whitespace-nowrap md:w-1/2">
                <div class="flex items-center">
                  <svg class="shrink-0 mr-2 sm:mr-3" width="36" height="36" viewBox="0 0 36 36">
                    <circle fill="#0052FF" cx="18" cy="18" r="18" />
                    <path d="M18.12 13.894c1.673 0 3.002 1.016 3.506 2.528H25C24.389 13.19 21.679 11 18.146 11 14.135 11 11 13.998 11 18.013S14.055 25 18.146 25c3.454 0 6.216-2.19 6.828-5.449h-3.348c-.478 1.512-1.807 2.555-3.48 2.555-2.312 0-3.932-1.747-3.932-4.093 0-2.372 1.595-4.12 3.905-4.12Z" fill="#FFF" fill-rule="nonzero" />
                  </svg>
                  <div>
                    <div class="text-slate-800 dark:text-slate-100 uppercase">Coin</div>
                    <div class="text-xs text-slate-500">Coinbase Global Inc.</div>
                  </div>
                </div>
              </td>
              <td class="p-2 whitespace-nowrap">
                <div class="font-normal text-left">50.89B</div>
              </td>
              <td class="p-2 whitespace-nowrap">
                <!-- Chart built with Chart.js 3 -->
                <!-- Check out src/js/components/fintech-card-14.js for config -->
                <div class="w-[96px]">
                  <!-- Change the height attribute to adjust the chart height -->
                  <LineChart :data="chartData[4]" width="96" height="32" />
                </div>
              </td>
              <td class="p-2 whitespace-nowrap">
                <div class="text-left">$236.48</div>
              </td>
              <td class="p-2 whitespace-nowrap">
                <div class="text-left text-rose-500">-$24,30 (6.2%)</div>
              </td>
            </tr>
          </tbody>
        </table>

      </div>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue'
import LineChart from '../../charts/LineChart09.vue'

// Import utilities
import { tailwindConfig } from '../../utils/Utils'

export default {
  name: 'FintechCard14',
  components: {
    LineChart,
  },
  setup() {
    const miniCharts = ref([
      // Twitter
      {
        data: [
          540, 466, 540, 466, 385, 432, 334,
          334, 289, 289, 200, 289, 222, 289,
          289, 403, 554, 304, 289, 270, 134,
          270, 829, 644, 688, 664,
        ],
        growth: true,
      },
      // Facebook
      {
        data: [
          245, 288, 332, 404, 404, 314, 314,
          314, 314, 314, 234, 314, 234, 234,
          314, 314, 314, 388, 314, 202, 202,
          202, 202, 514, 720, 642,
        ],
        growth: true,
      },
      // Google
      {
        data: [
          732, 610, 610, 504, 504, 504, 349,
          349, 504, 342, 504, 610, 391, 192,
          154, 273, 191, 191, 126, 263, 349,
          252, 323, 322, 270, 232,
        ],
        growth: false,
      },
      // Apple
      {
        data: [
          222, 222, 226, 271, 365, 365, 238,
          324, 288, 206, 324, 324, 500, 409,
          409, 273, 232, 273, 500, 570, 767,
          808, 685, 767, 685, 685,
        ],
        growth: true,
      },
      // Coinbase
      {
        data: [
          632, 510, 610, 404, 504, 404, 449,
          349, 404, 542, 404, 410, 491, 392,
          254, 273, 291, 191, 226, 363, 449,
          252, 223, 222, 170, 132,
        ],
        growth: false,
      },
    ])

    const chartData = ref([])

    const buildChartData = (chart) => {
      var obj = {
        labels: [
          '12-01-2020', '01-01-2021', '02-01-2021',
          '03-01-2021', '04-01-2021', '05-01-2021',
          '06-01-2021', '07-01-2021', '08-01-2021',
          '09-01-2021', '10-01-2021', '11-01-2021',
          '12-01-2021', '01-01-2022', '02-01-2022',
          '03-01-2022', '04-01-2022', '05-01-2022',
          '06-01-2022', '07-01-2022', '08-01-2022',
          '09-01-2022', '10-01-2022', '11-01-2022',
          '12-01-2022', '01-01-2023',
        ],
        datasets: [
          // Line
          {
            data: chart.data,
            borderColor: chart.growth ? tailwindConfig().theme.colors.emerald[500] : tailwindConfig().theme.colors.rose[500],
            borderWidth: 2,
            tension: 0,
            pointRadius: 0,
            pointHoverRadius: 3,
            pointBackgroundColor: chart.growth ? tailwindConfig().theme.colors.emerald[500] : tailwindConfig().theme.colors.rose[500],
            clip: 20,
          },
        ],      
      }    
      return obj
    }

    miniCharts.value.map((miniChart) => {
      chartData.value.push(buildChartData(miniChart))
    })

    return {
      chartData,
    } 
  }
}
</script>
<template>
  <div class="flex h-[100dvh] overflow-hidden">
    <!-- Sidebar -->
    <Sidebar :sidebarOpen="sidebarOpen" @close-sidebar="sidebarOpen = false" />

    <!-- Content area -->
    <div
      class="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden bg-white dark:bg-slate-900"
    >
      <!-- Site header -->
      <Header
        :sidebarOpen="sidebarOpen"
        @toggle-sidebar="sidebarOpen = !sidebarOpen"
      />

      <main>
        <div class="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto h-full">
          <!-- Page header -->
          <div class="sm:flex sm:justify-between sm:items-center">
            <!-- Left: Title -->
            <div class="mb-4 sm:mb-0">
              <h1
                class="text-2xl md:text-3xl text-slate-800 dark:text-slate-100 font-bold"
              >
                Browse Stores in Your Area
              </h1>
              <p
                class="text-sm md:text-base text-slate-600 dark:text-slate-400"
              >
                <font-awesome-icon icon="fa-duotone fa-location-arrow" />
                {{ geolocationStoreAddress }}
              </p>
            </div>

            <!-- Right: Actions -->
            <div
              class="grid grid-flow-col sm:auto-cols-max justify-start sm:justify-end gap-2"
            >
              <!-- Add board button -->
              <button class="btn bg-indigo-500 hover:bg-indigo-600 text-white">
                <svg
                  class="w-4 h-4 fill-current opacity-50 shrink-0"
                  viewBox="0 0 16 16"
                >
                  <path
                    d="M15 7H9V1c0-.6-.4-1-1-1S7 .4 7 1v6H1c-.6 0-1 .4-1 1s.4 1 1 1h6v6c0 .6.4 1 1 1s1-.4 1-1V9h6c.6 0 1-.4 1-1s-.4-1-1-1z"
                  />
                </svg>
                <span class="ml-2">Add Event</span>
              </button>
            </div>
          </div>
        </div>
      </main>

      <div class="grow">
        <mapbox-map
          :accessToken="mapBoxToken"
          :center="[
            geolocationStoreCoords?.longitude || -106.346771,
            geolocationStoreCoords?.latitude || 56.130366,
          ]"
          :zoom="11"
        >
          <mapbox-navigation-control position="top-right" />
          <mapbox-marker
            v-for="store in stores"
            :key="store.id"
            :lngLat="store.Position.coordinates"
            :draggable="false"
          >
            <mapbox-popup>
              <div>
                <h4>{{ store.Chain.localized("eng")?.Name }}</h4>
                <router-link :to="`/stores/${store.id}`"
                  ><h3 class="text-lg font-semibold">
                    {{ store.Name }}
                  </h3></router-link
                >
                <p>{{ store.Address }}</p>
                <p>{{ store.City }}</p>
              </div>
            </mapbox-popup>
            <template v-slot:icon>
              <img
                :src="store.Chain.Logo"
                class="w-12 h-12 rounded-full border border-slate-200"
              />
            </template>
          </mapbox-marker>
        </mapbox-map>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, watch } from "vue";
import { storeToRefs } from "pinia";

import Sidebar from "../../partials/Sidebar.vue";
import Header from "../../partials/Header.vue";

import { getStores } from "../../services/api";
import { useGeolocationStore } from "../../stores/geolocation";

const mapBoxToken = import.meta.env.VITE_MAPBOX_TOKEN;

const geolocationStore = useGeolocationStore();

// Refs from stores
const { address: geolocationStoreAddress, coords: geolocationStoreCoords } =
  storeToRefs(geolocationStore);

const sidebarOpen = ref(false);
const stores = ref([]);

const loadNearbyStores = async () => {
  if (!geolocationStoreCoords.value) {
    return;
  }
  const { latitude, longitude } = geolocationStoreCoords.value;
  if (!latitude || !longitude) {
    return;
  }
  const nearbyStores = await getStores({
    latitude,
    limit: 100,
    longitude,
  });
  stores.value = nearbyStores;
};

loadNearbyStores();

watch(geolocationStoreCoords, async () => {
  loadNearbyStores();
});
</script>

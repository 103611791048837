<template>
  <!-- Animate the logo as a loading screen -->
  <div
    class="fixed inset-0 bg-slate-50 bg-slate-800 flex items-center justify-center"
  >
    <img class="w-32 h-32" src="../images/manypenny-logo.svg" />
  </div>
</template>

<script setup>
import { watch } from "vue";
import { useAuth0 } from "@auth0/auth0-vue";

const { isAuthenticated, isLoading } = useAuth0();

watch(isLoading, (newValue, oldValue) => {
  console.log("isLoading changed");
  console.log("new value", newValue);
  console.log("old value", oldValue);
});

watch(isAuthenticated, (newValue, oldValue) => {
  console.log("isAuthenticated changed");
  console.log("new value", newValue);
  console.log("old value", oldValue);
});

// const { handleRedirectCallback } = useAuth0();

// console.log('callback');
// handleRedirectCallback();
</script>

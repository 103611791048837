import { ref } from "vue";
import { defineStore } from "pinia";

export const useBackendStore = defineStore("backend", () => {
  // Socket
  const socketAuthenticated = ref(false);
  // Return the state
  return {
    socketAuthenticated,
  };
});

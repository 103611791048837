<template>
  <!-- Card 1 -->
  <div class="relative col-span-full sm:col-span-6 xl:col-span-3 bg-white dark:bg-slate-800 shadow-lg rounded-sm border border-slate-200 dark:border-transparent overflow-hidden">
    <!-- Image -->
    <img class="absolute w-full h-full object-cover" src="../../images/applications-image-17.jpg" width="286" height="160" alt="Application 17" />
    <!-- Gradient -->
    <div class="absolute inset-0 bg-gradient-to-t from-slate-800 to-transparent" aria-hidden="true"></div>
    <!-- Content -->
    <div class="relative h-full p-5 flex flex-col justify-end">
      <h3 class="text-lg text-white font-semibold mt-16 mb-0.5">Merchandise</h3>
      <a class="text-sm font-medium text-indigo-400 hover:text-indigo-300" href="#0">Explore -&gt;</a>
    </div>
  </div>

  <!-- Card 2 -->
  <div class="relative col-span-full sm:col-span-6 xl:col-span-3 bg-white dark:bg-slate-800 shadow-lg rounded-sm border border-slate-200 dark:border-transparent overflow-hidden">
    <!-- Image -->
    <img class="absolute w-full h-full object-cover" src="../../images/applications-image-18.jpg" width="286" height="160" alt="Application 18" />
    <!-- Gradient -->
    <div class="absolute inset-0 bg-gradient-to-t from-slate-800 to-transparent" aria-hidden="true"></div>
    <!-- Content -->
    <div class="relative h-full p-5 flex flex-col justify-end">
      <h3 class="text-lg text-white font-semibold mt-16 mb-0.5">Audiobooks</h3>
      <a class="text-sm font-medium text-indigo-400 hover:text-indigo-300" href="#0">Explore -&gt;</a>
    </div>
  </div>

  <!-- Card 3 -->
  <div class="relative col-span-full sm:col-span-6 xl:col-span-3 bg-white dark:bg-slate-800 shadow-lg rounded-sm border border-slate-200 dark:border-transparent overflow-hidden">
    <!-- Image -->
    <img class="absolute w-full h-full object-cover" src="../../images/applications-image-19.jpg" width="286" height="160" alt="Application 19" />
    <!-- Gradient -->
    <div class="absolute inset-0 bg-gradient-to-t from-slate-800 to-transparent" aria-hidden="true"></div>
    <!-- Content -->
    <div class="relative h-full p-5 flex flex-col justify-end">
      <h3 class="text-lg text-white font-semibold mt-16 mb-0.5">Design & Tech</h3>
      <a class="text-sm font-medium text-indigo-400 hover:text-indigo-300" href="#0">Explore -&gt;</a>
    </div>
  </div>

  <!-- Card 4 -->
  <div class="relative col-span-full sm:col-span-6 xl:col-span-3 bg-white dark:bg-slate-800 shadow-lg rounded-sm border border-slate-200 dark:border-transparent overflow-hidden">
    <!-- Image -->
    <img class="absolute w-full h-full object-cover" src="../../images/applications-image-20.jpg" width="286" height="160" alt="Application 20" />
    <!-- Gradient -->
    <div class="absolute inset-0 bg-gradient-to-t from-slate-800 to-transparent" aria-hidden="true"></div>
    <!-- Content -->
    <div class="relative h-full p-5 flex flex-col justify-end">
      <h3 class="text-lg text-white font-semibold mt-16 mb-0.5">Apps & Software</h3>
      <a class="text-sm font-medium text-indigo-400 hover:text-indigo-300" href="#0">Explore -&gt;</a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ShopCards06',
}
</script>
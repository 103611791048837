<template>
  <div
    class="col-span-full sm:col-span-6 xl:col-span-3 bg-white dark:bg-slate-800 shadow-lg rounded-sm border border-slate-200 dark:border-slate-700"
  >
    <div class="flex flex-col h-full text-center p-5">
      <div class="grow mb-1">
        <div
          v-if="category?.Icon && category?.Icon?.length > 0"
          class="inline-flex justify-center items-center w-12 h-12 bg-slate-100 dark:bg-slate-700 rounded-full mb-2"
        >
          <img
            class="w-6 h-6 fill-current"
            :src="`https://assets.manypenny.app/icons/${category?.Icon}.svg`"
            width="24"
            height="24"
          />
        </div>
        <h3
          class="text-lg text-slate-800 dark:text-slate-100 font-semibold mb-1"
        >
          {{ category?.localized("eng")?.Name }}
        </h3>
      </div>
      <div>
        <router-link
          class="text-sm font-medium text-indigo-500 hover:text-indigo-600 dark:hover:text-indigo-400"
          :to="`categories/${category?.id}`"
          >Explore -></router-link
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CategoryCard",
  props: {
    category: {
      type: Object,
      required: true,
    },
  },
  setup() {
    return {};
  },
};
</script>

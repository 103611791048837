<template>
  <!-- Card 1 -->
  <div class="col-span-full sm:col-span-6 xl:col-span-3 bg-white dark:bg-slate-800 shadow-lg rounded-sm border border-slate-200 dark:border-slate-700 overflow-hidden">
    <div class="flex flex-col h-full">
      <!-- Image -->
      <div class="relative">
        <img class="w-full" src="../../images/applications-image-13.jpg" width="286" height="160" alt="Application 13" />
        <!-- Like button -->
        <button class="absolute top-0 right-0 mt-4 mr-4">
          <div class="text-slate-100 bg-slate-900 bg-opacity-60 rounded-full">
            <span class="sr-only">Like</span>
            <svg class="h-8 w-8 fill-current" viewBox="0 0 32 32">
              <path d="M22.682 11.318A4.485 4.485 0 0019.5 10a4.377 4.377 0 00-3.5 1.707A4.383 4.383 0 0012.5 10a4.5 4.5 0 00-3.182 7.682L16 24l6.682-6.318a4.5 4.5 0 000-6.364zm-1.4 4.933L16 21.247l-5.285-5A2.5 2.5 0 0112.5 12c1.437 0 2.312.681 3.5 2.625C17.187 12.681 18.062 12 19.5 12a2.5 2.5 0 011.785 4.251h-.003z" />
            </svg>
          </div>
        </button>
      </div>
      <!-- Card Content -->
      <div class="grow flex flex-col p-5">
        <!-- Card body -->
        <div class="grow">
          <!-- Header -->
          <header class="mb-4">
            <a class="text-xs text-emerald-500 hover:text-emerald-600 font-semibold uppercase tracking-widest mb-1" href="#0">Funding</a>
            <h3 class="text-lg text-slate-800 dark:text-slate-100 font-semibold mb-1">SwiftNight Home Plus</h3>
            <div class="text-sm">Lorem ipsum is placeholder text commonly used.</div>
          </header>
          <!-- Info -->
          <div class="mb-5">
            <div class="flex justify-between text-sm space-x-2 mb-2">
              <div><span class="font-semibold text-slate-800 dark:text-slate-100">$789,784</span> <span class="italic">raised</span></div>
              <div>64%</div>
            </div>
            <!-- Bar -->
            <div class="relative w-full h-2 rounded-full bg-slate-200 dark:bg-slate-700 mb-2">
              <div class="absolute left-0 top-0 bottom-0 bg-emerald-500 rounded-full" style="width:55%;"></div>
            </div>
            <div class="font-medium text-xs">30 Days Left</div>
          </div>
        </div>
        <!-- Card footer -->
        <div>
          <a class="btn-sm w-full bg-indigo-500 hover:bg-indigo-600 text-white" href="#0">Back It</a>
        </div>
      </div>
    </div>
  </div>

  <!-- Card 2 -->
  <div class="col-span-full sm:col-span-6 xl:col-span-3 bg-white dark:bg-slate-800 shadow-lg rounded-sm border border-slate-200 dark:border-slate-700 overflow-hidden">
    <div class="flex flex-col h-full">
      <!-- Image -->
      <div class="relative">
        <img class="w-full" src="../../images/applications-image-14.jpg" width="286" height="160" alt="Application 14" />
        <!-- Like button -->
        <button class="absolute top-0 right-0 mt-4 mr-4">
          <div class="text-slate-100 bg-slate-900 bg-opacity-60 rounded-full">
            <span class="sr-only">Like</span>
            <svg class="h-8 w-8 fill-current" viewBox="0 0 32 32">
              <path d="M22.682 11.318A4.485 4.485 0 0019.5 10a4.377 4.377 0 00-3.5 1.707A4.383 4.383 0 0012.5 10a4.5 4.5 0 00-3.182 7.682L16 24l6.682-6.318a4.5 4.5 0 000-6.364zm-1.4 4.933L16 21.247l-5.285-5A2.5 2.5 0 0112.5 12c1.437 0 2.312.681 3.5 2.625C17.187 12.681 18.062 12 19.5 12a2.5 2.5 0 011.785 4.251h-.003z" />
            </svg>
          </div>
        </button>
      </div>
      <!-- Card Content -->
      <div class="grow flex flex-col p-5">
        <!-- Card body -->
        <div class="grow">
          <!-- Header -->
          <header class="mb-4">
            <a class="text-xs text-emerald-500 hover:text-emerald-600 font-semibold uppercase tracking-widest mb-1" href="#0">Funding</a>
            <h3 class="text-lg text-slate-800 dark:text-slate-100 font-semibold mb-1">SwiftNight Home Plus</h3>
            <div class="text-sm">Lorem ipsum is placeholder text commonly used.</div>
          </header>
          <!-- Info -->
          <div class="mb-5">
            <div class="flex justify-between text-sm space-x-2 mb-2">
              <div><span class="font-semibold text-slate-800 dark:text-slate-100">$789,784</span> <span class="italic">raised</span></div>
              <div>64%</div>
            </div>
            <!-- Bar -->
            <div class="relative w-full h-2 rounded-full bg-slate-200 dark:bg-slate-700 mb-2">
              <div class="absolute left-0 top-0 bottom-0 bg-emerald-500 rounded-full" style="width:55%;"></div>
            </div>
            <div class="font-medium text-xs">30 Days Left</div>
          </div>
        </div>
        <!-- Card footer -->
        <div>
          <a class="btn-sm w-full bg-indigo-500 hover:bg-indigo-600 text-white" href="#0">Back It</a>
        </div>
      </div>
    </div>
  </div>

  <!-- Card 3 -->
  <div class="col-span-full sm:col-span-6 xl:col-span-3 bg-white dark:bg-slate-800 shadow-lg rounded-sm border border-slate-200 dark:border-slate-700 overflow-hidden">
    <div class="flex flex-col h-full">
      <!-- Image -->
      <div class="relative">
        <img class="w-full" src="../../images/applications-image-15.jpg" width="286" height="160" alt="Application 15" />
        <!-- Like button -->
        <button class="absolute top-0 right-0 mt-4 mr-4">
          <div class="text-slate-100 bg-slate-900 bg-opacity-60 rounded-full">
            <span class="sr-only">Like</span>
            <svg class="h-8 w-8 fill-current" viewBox="0 0 32 32">
              <path d="M22.682 11.318A4.485 4.485 0 0019.5 10a4.377 4.377 0 00-3.5 1.707A4.383 4.383 0 0012.5 10a4.5 4.5 0 00-3.182 7.682L16 24l6.682-6.318a4.5 4.5 0 000-6.364zm-1.4 4.933L16 21.247l-5.285-5A2.5 2.5 0 0112.5 12c1.437 0 2.312.681 3.5 2.625C17.187 12.681 18.062 12 19.5 12a2.5 2.5 0 011.785 4.251h-.003z" />
            </svg>
          </div>
        </button>
      </div>
      <!-- Card Content -->
      <div class="grow flex flex-col p-5">
        <!-- Card body -->
        <div class="grow">
          <!-- Header -->
          <header class="mb-4">
            <a class="text-xs text-emerald-500 hover:text-emerald-600 font-semibold uppercase tracking-widest mb-1" href="#0">Funding</a>
            <h3 class="text-lg text-slate-800 dark:text-slate-100 font-semibold mb-1">SwiftNight Home Plus</h3>
            <div class="text-sm">Lorem ipsum is placeholder text commonly used.</div>
          </header>
          <!-- Info -->
          <div class="mb-5">
            <div class="flex justify-between text-sm space-x-2 mb-2">
              <div><span class="font-semibold text-slate-800 dark:text-slate-100">$789,784</span> <span class="italic">raised</span></div>
              <div>64%</div>
            </div>
            <!-- Bar -->
            <div class="relative w-full h-2 rounded-full bg-slate-200 dark:bg-slate-700 mb-2">
              <div class="absolute left-0 top-0 bottom-0 bg-emerald-500 rounded-full" style="width:55%;"></div>
            </div>
            <div class="font-medium text-xs">30 Days Left</div>
          </div>
        </div>
        <!-- Card footer -->
        <div>
          <a class="btn-sm w-full bg-indigo-500 hover:bg-indigo-600 text-white" href="#0">Back It</a>
        </div>
      </div>
    </div>
  </div>

  <!-- Card 4 -->
  <div class="col-span-full sm:col-span-6 xl:col-span-3 bg-white dark:bg-slate-800 shadow-lg rounded-sm border border-slate-200 dark:border-slate-700 overflow-hidden">
    <div class="flex flex-col h-full">
      <!-- Image -->
      <div class="relative">
        <img class="w-full" src="../../images/applications-image-16.jpg" width="286" height="160" alt="Application 16" />
        <!-- Like button -->
        <button class="absolute top-0 right-0 mt-4 mr-4">
          <div class="text-slate-100 bg-slate-900 bg-opacity-60 rounded-full">
            <span class="sr-only">Like</span>
            <svg class="h-8 w-8 fill-current" viewBox="0 0 32 32">
              <path d="M22.682 11.318A4.485 4.485 0 0019.5 10a4.377 4.377 0 00-3.5 1.707A4.383 4.383 0 0012.5 10a4.5 4.5 0 00-3.182 7.682L16 24l6.682-6.318a4.5 4.5 0 000-6.364zm-1.4 4.933L16 21.247l-5.285-5A2.5 2.5 0 0112.5 12c1.437 0 2.312.681 3.5 2.625C17.187 12.681 18.062 12 19.5 12a2.5 2.5 0 011.785 4.251h-.003z" />
            </svg>
          </div>
        </button>
      </div>
      <!-- Card Content -->
      <div class="grow flex flex-col p-5">
        <!-- Card body -->
        <div class="grow">
          <!-- Header -->
          <header class="mb-4">
            <a class="text-xs text-emerald-500 hover:text-emerald-600 font-semibold uppercase tracking-widest mb-1" href="#0">Funding</a>
            <h3 class="text-lg text-slate-800 dark:text-slate-100 font-semibold mb-1">SwiftNight Home Plus</h3>
            <div class="text-sm">Lorem ipsum is placeholder text commonly used.</div>
          </header>
          <!-- Info -->
          <div class="mb-5">
            <div class="flex justify-between text-sm space-x-2 mb-2">
              <div><span class="font-semibold text-slate-800 dark:text-slate-100">$789,784</span> <span class="italic">raised</span></div>
              <div>64%</div>
            </div>
            <!-- Bar -->
            <div class="relative w-full h-2 rounded-full bg-slate-200 dark:bg-slate-700 mb-2">
              <div class="absolute left-0 top-0 bottom-0 bg-emerald-500 rounded-full" style="width:55%;"></div>
            </div>
            <div class="font-medium text-xs">30 Days Left</div>
          </div>
        </div>
        <!-- Card footer -->
        <div>
          <a class="btn-sm w-full bg-indigo-500 hover:bg-indigo-600 text-white" href="#0">Back It</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ShopCards04',
}
</script>
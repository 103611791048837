<template>
  <header
    class="sticky top-0 bg-white dark:bg-[#182235] border-b border-slate-200 dark:border-slate-700 z-30"
  >
    <div class="px-4 sm:px-6 lg:px-8">
      <div class="flex items-center justify-between h-16 -mb-px">
        <!-- Header: Left side -->
        <div class="flex">
          <!-- Hamburger button -->
          <button
            class="text-slate-500 hover:text-slate-600 lg:hidden"
            @click.stop="$emit('toggle-sidebar')"
            aria-controls="sidebar"
            :aria-expanded="sidebarOpen"
          >
            <span class="sr-only">Open sidebar</span>
            <font-awesome-icon icon="fa-solid fa-bars" class="w-6 h-6" />
          </button>
        </div>

        <!-- Header: Right side -->
        <div class="flex items-center space-x-3">
          <div class="relative inline-flex">
            <span class="sr-only">Realtime</span>
            <font-awesome-icon
              :icon="[
                'far',
                socketAuthenticated ? 'fa-rabbit-running' : 'fa-rabbit',
              ]"
              class="w-4 h-4"
            />
          </div>
          <div>
            <button
              class="w-8 h-8 flex items-center justify-center bg-slate-100 hover:bg-slate-200 dark:bg-slate-700 dark:hover:bg-slate-600/80 rounded-full ml-3"
              :class="{ 'bg-slate-200': searchModalOpen }"
              @click.stop="searchModalOpen = true"
              aria-controls="search-modal"
            >
              <span class="sr-only">Search</span>
              <font-awesome-icon
                icon="fa-solid fa-magnifying-glass"
                class="w-4 h-4"
              />
            </button>
            <SearchModal
              id="search-modal"
              searchId="search"
              :modalOpen="searchModalOpen"
              @open-modal="searchModalOpen = true"
              @close-modal="searchModalOpen = false"
            />
          </div>
          <Notifications align="right" />
          <Help align="right" />
          <ThemeToggle />
          <!-- Divider -->
          <hr class="w-px h-6 bg-slate-200 dark:bg-slate-700 border-none" />
          <UserMenu align="right" />
        </div>
      </div>
    </div>
  </header>
</template>

<script setup>
import { ref } from "vue";
import { storeToRefs } from "pinia";

// Import components
import SearchModal from "../components/ModalSearch.vue";
import Notifications from "../components/DropdownNotifications.vue";
import Help from "../components/DropdownHelp.vue";
import ThemeToggle from "../components/ThemeToggle.vue";
import UserMenu from "../components/DropdownProfile.vue";

// Import store
import { useBackendStore } from "../stores/backend";

// Define props
defineProps({
  sidebarOpen: Boolean,
});

const backendStore = useBackendStore();

// Reactive state
const searchModalOpen = ref(false);

const { socketAuthenticated } = storeToRefs(backendStore);
</script>

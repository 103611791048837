import { nanoid } from "nanoid";
import { defineStore } from "pinia";

import { getAllCategories } from "../services/api";

export const useCategoryStore = defineStore("category", {
  state: () => ({
    categories: [...Array(12)].map(() => ({
      id: nanoid(),
    })),
  }),
  actions: {
    async fetchCategories() {
      const categories = await getAllCategories();
      this.categories = categories;
    },
  },
});

<template>
  <main class="bg-white dark:bg-slate-900">
    <div class="relative flex">
      <!-- Content -->
      <div class="w-full md:w-1/2">
        <div class="min-h-[100dvh] h-full flex flex-col after:flex-1">
          <div class="flex-1">
            <!-- Header -->
            <div
              class="flex items-center justify-between h-16 px-4 sm:px-6 lg:px-8"
            >
              <!-- Logo -->
              <router-link class="block" to="/">
                <img
                  width="32"
                  height="32"
                  src="../images/manypenny-logo.svg"
                  alt="Logo"
                />
              </router-link>
              <!-- <div class="text-sm">
                Have an account?
                <router-link
                  class="font-medium text-indigo-500 hover:text-indigo-600 dark:hover:text-indigo-400"
                  to="/signin"
                  >Sign In</router-link
                >
              </div> -->
            </div>

            <!-- Progress bar -->
            <div class="px-4 pt-12 pb-8">
              <div class="max-w-md mx-auto w-full">
                <div class="relative">
                  <div
                    class="absolute left-0 top-1/2 -mt-px w-full h-0.5 bg-slate-200 dark:bg-slate-700"
                    aria-hidden="true"
                  ></div>
                  <ul class="relative flex justify-between w-full">
                    <li>
                      <router-link
                        class="flex items-center justify-center w-6 h-6 rounded-full text-xs font-semibold bg-indigo-500 text-white"
                        to="/onboarding-01"
                        >1</router-link
                      >
                    </li>
                    <li>
                      <router-link
                        class="flex items-center justify-center w-6 h-6 rounded-full text-xs font-semibold bg-slate-100 dark:bg-slate-700 text-slate-500 dark:text-slate-400"
                        to="/onboarding-02"
                        >2</router-link
                      >
                    </li>
                    <li>
                      <router-link
                        class="flex items-center justify-center w-6 h-6 rounded-full text-xs font-semibold bg-slate-100 dark:bg-slate-700 text-slate-500 dark:text-slate-400"
                        to="/onboarding-03"
                        >3</router-link
                      >
                    </li>
                    <li>
                      <router-link
                        class="flex items-center justify-center w-6 h-6 rounded-full text-xs font-semibold bg-slate-100 dark:bg-slate-700 text-slate-500 dark:text-slate-400"
                        to="/onboarding-04"
                        >4</router-link
                      >
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <div class="px-4 py-8">
            <div class="max-w-md mx-auto">
              <h1
                class="text-3xl text-slate-800 dark:text-slate-100 font-bold mb-6"
              >
                {{ greeting }} {{ userStore?.details?.Name }} 👋
              </h1>
              <p class="text-slate-600 dark:text-slate-400 mb-8">
                Let’s get you set up with your account. We’ll ask you a few
                questions to get started.
              </p>
              <!-- Form -->
              <form>
                <div>
                  <div>
                    <label
                      class="block text-sm font-medium mb-1"
                      for="full-name"
                      >What is your full name?</label
                    >
                    <input
                      id="full-name"
                      class="form-input w-full"
                      type="text"
                      :value="userStore?.details?.Name"
                      @input="validateNotEmail"
                      :disabled="detailsLoading || !canUpdateName"
                    />
                  </div>
                  <div v-if="isEmail" class="text-xs mt-1 text-rose-500">
                    Your full name should not be an email.
                  </div>
                  <div class="text-xs mt-1">
                    If you connected through Apple or Google, we might already
                    have that information.
                  </div>
                </div>
                <!-- <div class="space-y-3 mb-8">
                  <label class="relative block cursor-pointer">
                    <input
                      type="radio"
                      name="radio-buttons"
                      class="peer sr-only"
                      checked
                    />
                    <div
                      class="flex items-center bg-white text-sm font-medium text-slate-800 dark:text-slate-100 p-4 rounded dark:bg-slate-800 border border-slate-200 dark:border-slate-700 hover:border-slate-300 dark:hover:border-slate-600 shadow-sm duration-150 ease-in-out"
                    >
                      <svg
                        class="w-6 h-6 shrink-0 fill-current mr-4"
                        viewBox="0 0 24 24"
                      >
                        <path
                          class="text-indigo-500"
                          d="m12 10.856 9-5-8.514-4.73a1 1 0 0 0-.972 0L3 5.856l9 5Z"
                        />
                        <path
                          class="text-indigo-300"
                          d="m11 12.588-9-5V18a1 1 0 0 0 .514.874L11 23.588v-11Z"
                        />
                        <path
                          class="text-indigo-200"
                          d="M13 12.588v11l8.486-4.714A1 1 0 0 0 22 18V7.589l-9 4.999Z"
                        />
                      </svg>
                      <span>I have a company</span>
                    </div>
                    <div
                      class="absolute inset-0 border-2 border-transparent peer-checked:border-indigo-400 dark:peer-checked:border-indigo-500 rounded pointer-events-none"
                      aria-hidden="true"
                    ></div>
                  </label>
                  <label class="relative block cursor-pointer">
                    <input
                      type="radio"
                      name="radio-buttons"
                      class="peer sr-only"
                    />
                    <div
                      class="flex items-center bg-white text-sm font-medium text-slate-800 dark:text-slate-100 p-4 rounded dark:bg-slate-800 border border-slate-200 dark:border-slate-700 hover:border-slate-300 dark:hover:border-slate-600 shadow-sm duration-150 ease-in-out"
                    >
                      <svg
                        class="w-6 h-6 shrink-0 fill-current mr-4"
                        viewBox="0 0 24 24"
                      >
                        <path
                          class="text-indigo-500"
                          d="m12 10.856 9-5-8.514-4.73a1 1 0 0 0-.972 0L3 5.856l9 5Z"
                        />
                        <path
                          class="text-indigo-300"
                          d="m11 12.588-9-5V18a1 1 0 0 0 .514.874L11 23.588v-11Z"
                        />
                      </svg>
                      <span>I’m a freelance / contractor</span>
                    </div>
                    <div
                      class="absolute inset-0 border-2 border-transparent peer-checked:border-indigo-400 dark:peer-checked:border-indigo-500 rounded pointer-events-none"
                      aria-hidden="true"
                    ></div>
                  </label>
                  <label class="relative block cursor-pointer">
                    <input
                      type="radio"
                      name="radio-buttons"
                      class="peer sr-only"
                    />
                    <div
                      class="flex items-center bg-white text-sm font-medium text-slate-800 dark:text-slate-100 p-4 rounded dark:bg-slate-800 border border-slate-200 dark:border-slate-700 hover:border-slate-300 dark:hover:border-slate-600 shadow-sm duration-150 ease-in-out"
                    >
                      <svg
                        class="w-6 h-6 shrink-0 fill-current mr-4"
                        viewBox="0 0 24 24"
                      >
                        <path
                          class="text-indigo-500"
                          d="m12 10.856 9-5-8.514-4.73a1 1 0 0 0-.972 0L3 5.856l9 5Z"
                        />
                      </svg>
                      <span>I’m just getting started</span>
                    </div>
                    <div
                      class="absolute inset-0 border-2 border-transparent peer-checked:border-indigo-400 dark:peer-checked:border-indigo-500 rounded pointer-events-none"
                      aria-hidden="true"
                    ></div>
                  </label>
                </div> -->
                <!-- Start -->
                <div v-if="geoSupported">
                  <div class="flex items-center mt-5 space-x-2">
                    <label
                      class="block text-sm font-medium mb-1"
                      for="geo-toggle"
                      >Allow geolocation?</label
                    >
                    <div class="form-switch">
                      <input
                        type="checkbox"
                        id="geo-toggle"
                        class="sr-only"
                        v-model="geoToggle"
                        true-value="Yes"
                        false-value="No"
                        :disabled="detailsLoading"
                      />
                      <label
                        class="bg-slate-400 dark:bg-slate-700"
                        for="geo-toggle"
                      >
                        <span
                          class="bg-white shadow-sm"
                          aria-hidden="true"
                        ></span>
                        <span class="sr-only">Toggle</span>
                      </label>
                    </div>
                    <div
                      class="text-sm text-slate-400 dark:text-slate-500 italic ml-2"
                    >
                      {{ geoToggle }}
                    </div>
                  </div>
                  <div class="text-xs mt-1">{{ geoLocation }}</div>
                </div>
                <!-- End -->
                <div class="flex items-center justify-between">
                  <router-link
                    class="btn bg-indigo-500 hover:bg-indigo-600 text-white ml-auto"
                    to="/onboarding-02"
                    >Next Step -&gt;</router-link
                  >
                </div>
              </form>
              <ModalBlank
                id="name-modal"
                :modalOpen="nameModalOpen"
                @close-modal="nameModalOpen = false"
              >
                <div class="p-5 flex space-x-4">
                  <!-- Icon -->
                  <div
                    class="w-10 h-10 rounded-full flex items-center justify-center shrink-0 bg-indigo-100 dark:bg-indigo-500/30"
                  >
                    <svg
                      class="w-4 h-4 shrink-0 fill-current text-indigo-500"
                      viewBox="0 0 16 16"
                    >
                      <path
                        d="M8 0C3.6 0 0 3.6 0 8s3.6 8 8 8 8-3.6 8-8-3.6-8-8-8zm1 12H7V7h2v5zM8 6c-.6 0-1-.4-1-1s.4-1 1-1 1 .4 1 1-.4 1-1 1z"
                      />
                    </svg>
                  </div>
                  <!-- Content -->
                  <div>
                    <!-- Modal header -->
                    <div class="mb-2">
                      <div
                        class="text-lg font-semibold text-slate-800 dark:text-slate-100"
                      >
                        You can’t change your name
                      </div>
                    </div>
                    <!-- Modal content -->
                    <div class="text-sm mb-10">
                      <div class="space-y-2">
                        <p>
                          Your full name can't be changed because you signed up
                          with Apple or Google.
                        </p>
                        <p>
                          Your name will be synced from your Apple or Google
                          account upon each login. If you want to change your
                          name, you can do so in your Apple or Google account.
                        </p>
                      </div>
                    </div>
                    <!-- Modal footer -->
                    <div class="flex flex-wrap justify-end space-x-2">
                      <button
                        class="btn-sm border-slate-200 dark:border-slate-700 hover:border-slate-300 dark:hover:border-slate-600 text-slate-600 dark:text-slate-300"
                        @click.stop="nameModalOpen = false"
                      >
                        Got it
                      </button>
                    </div>
                  </div>
                </div>
              </ModalBlank>
            </div>
          </div>
        </div>
      </div>

      <!-- Image -->
      <div
        class="hidden md:block absolute top-0 bottom-0 right-0 md:w-1/2"
        aria-hidden="true"
      >
        <img
          class="object-cover object-center w-full h-full"
          src="../images/onboarding-image.jpg"
          width="760"
          height="1024"
          alt="Onboarding"
        />
        <img
          class="absolute top-1/4 left-0 -translate-x-1/2 ml-8 hidden lg:block"
          src="../images/auth-decoration.png"
          width="218"
          height="224"
          alt="Authentication decoration"
        />
      </div>
    </div>
  </main>
</template>

<script>
import { ref, watch } from "vue";
import debounce from "lodash.debounce";
import getGreetingTime from "greeting-time";
import { storeToRefs } from "pinia";

import ModalBlank from "../components/ModalBlank.vue";

import { updateMyDetails } from "../services/api";
import { useGeolocationStore } from "../stores/geolocation";
import { useUserStore } from "../stores/user";

export default {
  name: "onboarding-step-01",
  components: {
    ModalBlank,
  },
  setup() {
    const geolocationStore = useGeolocationStore();
    const userStore = useUserStore();

    const greeting = getGreetingTime(new Date());

    const { 
      allowed: geolocationStoreAllowed, 
      address: geolocationStoreAddress,
      coords: geolocationStoreCoords,
      supported: geolocationStoreSupported,
    } = storeToRefs(geolocationStore);

    const canUpdateName = ref(true);
    const geoToggle = ref("No");

    if (geolocationStore.supported) {
      let geoToggleWatcher; // Variable to hold the watcher

      // Watcher logic for geoToggle
      const watchGeoToggle = () => {
        geoToggleWatcher = watch(
          () => geoToggle.value,
          async (newVal) => {
            await updateMyDetails({
              UserMetadata: {
                WebappGeoLocation: newVal === "Yes",
              },
            });
            await userStore.fetchDetails();
          },
        );
      };

      // watch(
      //   () => detailsResult.value,
      //   (newVal) => {
      //     geoToggle.value = newVal?.UserMetadata?.WebappGeoLocation
      //       ? "Yes"
      //       : "No";
      //     if (newVal?.UserMetadata?.WebappGeoLocation) {
      //       geoRefresh();
      //     }
      //     if (!geoToggleWatcher) {
      //       watchGeoToggle();
      //     }
      //   },
      // );

      // watch(
      //   () => geoCoords.value,
      //   async (newVal) => {
      //     console.log("Geolocation coordinates", newVal);
      //     const location = await reverseGeocode(
      //       newVal.latitude,
      //       newVal.longitude,
      //     );
      //     console.log("Geolocation location", location);
      //     if (location.results && location.results.length > 0) {
      //       geoLocation.value = location.results[0].formatted_address;
      //     }
      //   },
      // );

      // watch(
      //   () => geoError.value,
      //   async (newVal) => {
      //     console.log("Geolocation error", newVal);
      //   },
      // );
    }

    return {
      canUpdateName,
      geoToggle,
      greeting,
      geolocationStore,
      isEmail: ref(false),
      nameModalOpen: ref(false),
      userStore,
    };
  },
  methods: {
    validateNotEmail(event) {
      const value = event.target.value;
      this.name = value;
      const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
      this.isEmail.value = emailPattern.test(value);
    },
  },
  watch: {
    name: {
      handler: debounce(async function (newVal) {
        if (!this.isEmail.value) {
          try {
            await updateMyDetails({
              Name: newVal,
            });
            await this.userStore.fetchDetails();
          } catch (err) {
            this.nameModalOpen = true;
            this.canUpdateName = false;
          }
        }
      }, 500),
    },
  },
};
</script>

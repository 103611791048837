import { ref, watch } from "vue";
import { defineStore } from "pinia";

const storedSidebarExpanded = localStorage.getItem("sidebar-expanded");

export const useUIStore = defineStore("ui", () => {
  // Sidebar
  const sidebarExpanded = ref(
    storedSidebarExpanded === null ? false : storedSidebarExpanded === "true",
  );
  watch(sidebarExpanded, () => {
    localStorage.setItem("sidebar-expanded", sidebarExpanded.value);
    if (sidebarExpanded.value) {
      document.querySelector("body").classList.add("sidebar-expanded");
    } else {
      document.querySelector("body").classList.remove("sidebar-expanded");
    }
  });
  // Return the state
  return {
    sidebarExpanded,
  };
});

<template>
  <main class="bg-white dark:bg-slate-900">
    <div class="relative flex">
      <!-- Content -->
      <div class="w-full md:w-1/2">
        <div class="min-h-[100dvh] h-full flex flex-col after:flex-1">
          <div class="flex-1">
            <!-- Header -->
            <div
              class="flex items-center justify-between h-16 px-4 sm:px-6 lg:px-8"
            >
              <!-- Logo -->
              <router-link class="block" to="/">
                <img
                  width="32"
                  height="32"
                  src="../images/manypenny-logo.svg"
                  alt="Logo"
                />
              </router-link>
              <!-- <div class="text-sm">
                Have an account?
                <router-link
                  class="font-medium text-indigo-500 hover:text-indigo-600 dark:hover:text-indigo-400"
                  to="/signin"
                  >Sign In</router-link
                >
              </div> -->
            </div>

            <!-- Progress bar -->
            <div class="px-4 pt-12 pb-8">
              <div class="max-w-md mx-auto w-full">
                <div class="relative">
                  <div
                    class="absolute left-0 top-1/2 -mt-px w-full h-0.5 bg-slate-200 dark:bg-slate-700"
                    aria-hidden="true"
                  ></div>
                  <ul class="relative flex justify-between w-full">
                    <li>
                      <router-link
                        class="flex items-center justify-center w-6 h-6 rounded-full text-xs font-semibold bg-indigo-500 text-white"
                        to="/onboarding-01"
                        >1</router-link
                      >
                    </li>
                    <li>
                      <router-link
                        class="flex items-center justify-center w-6 h-6 rounded-full text-xs font-semibold bg-indigo-500 text-white"
                        to="/onboarding-02"
                        >2</router-link
                      >
                    </li>
                    <li>
                      <router-link
                        class="flex items-center justify-center w-6 h-6 rounded-full text-xs font-semibold bg-indigo-500 text-white"
                        to="/onboarding-03"
                        >3</router-link
                      >
                    </li>
                    <li>
                      <router-link
                        class="flex items-center justify-center w-6 h-6 rounded-full text-xs font-semibold bg-indigo-500 text-white"
                        to="/onboarding-04"
                        >4</router-link
                      >
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <div class="px-4 py-8">
            <div class="max-w-md mx-auto">
              <div class="text-center">
                <svg
                  class="inline-flex w-16 h-16 fill-current mb-6"
                  viewBox="0 0 64 64"
                >
                  <circle
                    class="text-emerald-100 dark:text-emerald-400/30"
                    cx="32"
                    cy="32"
                    r="32"
                  />
                  <path
                    class="text-emerald-500 dark:text-emerald-400"
                    d="m28.5 41-8-8 3-3 5 5 12-12 3 3z"
                  />
                </svg>
                <h1
                  class="text-3xl text-slate-800 dark:text-slate-100 font-bold mb-8"
                >
                  Nice to have you, {{ name }} 🙌
                </h1>
                <router-link
                  class="btn bg-indigo-500 hover:bg-indigo-600 text-white"
                  to="/"
                  >Go To Dashboard -&gt;</router-link
                >
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Image -->
      <div
        class="hidden md:block absolute top-0 bottom-0 right-0 md:w-1/2"
        aria-hidden="true"
      >
        <img
          class="object-cover object-center w-full h-full"
          src="../images/onboarding-image.jpg"
          width="760"
          height="1024"
          alt="Onboarding"
        />
        <img
          class="absolute top-1/4 left-0 -translate-x-1/2 ml-8 hidden lg:block"
          src="../images/auth-decoration.png"
          width="218"
          height="224"
          alt="Authentication decoration"
        />
      </div>
    </div>
  </main>
</template>

<script>
import { useAuth0 } from "@auth0/auth0-vue";

export default {
  name: "Onboarding04",
  setup() {
    const { user } = useAuth0(); // user is a reactive ref

    return {
      name: user.value?.name || "Guest",
    };
  },
};
</script>

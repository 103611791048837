import { defineStore } from "pinia";

import {
  getAllFavouriteProducts,
  getAllFavouriteStores,
  getAllLists,
  getMyDetails,
  getAllNotifications,
} from "../services/api";

export const useUserStore = defineStore("user", {
  state: () => ({
    details: {},
    favouriteProducts: [],
    favouriteStores: [],
    initials: "",
    lists: [],
    unreadNotifications: [],
    unreadNotificationsCount: 0,
  }),
  getters: {
    favouriteProductIds: (state) => {
      return [...new Set(state.favouriteProducts.map((product) => product.id))];
    },
    favouriteStoreIds: (state) => {
      return [...new Set(state.favouriteStores.map((store) => store.id))];
    },
    favouriteZoneIds: (state) => {
      return [...new Set(state.favouriteStores.map((store) => store.Zone?.id))];
    },
  },
  actions: {
    async fetchDetails() {
      const details = await getMyDetails();
      this.details = details;
      if (!details.Name) return;
      this.initials = details.Name.split(" ")
        .map((name) => name[0])
        .join("");
    },
    async fetchFavouriteProducts() {
      const favouriteProducts = await getAllFavouriteProducts();
      this.favouriteProducts = favouriteProducts;
    },
    async fetchFavouriteStores() {
      const favouriteStores = await getAllFavouriteStores();
      this.favouriteStores = favouriteStores;
    },
    async fetchLists() {
      const lists = await getAllLists();
      this.lists = lists;
    },
    async fetchNotifications() {
      const notifications = await getAllNotifications({ read: false });
      this.unreadNotifications = notifications;
      this.unreadNotificationsCount = notifications.length;
    },
  },
});

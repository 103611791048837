<template>
  <div class="relative inline-flex">
    <content-loader
      v-if="!userStoreDetails"
      viewBox="0 0 177 38"
      :speed="2"
      primaryColor="#f3f3f3"
      secondaryColor="#ecebeb"
      width="177"
      height="38"
    >
      <circle cx="19" cy="19" r="19" />
      <rect x="56" y="11" rx="0" ry="0" width="121" height="12" />
    </content-loader>
    <button
      v-else
      ref="trigger"
      class="inline-flex justify-center items-center group"
      aria-haspopup="true"
      @click.prevent="dropdownOpen = !dropdownOpen"
      :aria-expanded="dropdownOpen"
    >
      <img
        v-if="
          userStoreDetails?.Picture &&
          userStoreDetails?.Picture.includes('.manypenny.')
        "
        class="w-8 h-8 rounded-full"
        :src="userStoreDetails?.Picture"
        width="32"
        height="32"
        alt="User"
      />
      <span
        v-else
        class="inline-flex h-8 w-8 items-center justify-center rounded-full bg-slate-400"
      >
        <span class="text-sm font-medium leading-none text-white">{{
          initials
        }}</span>
      </span>
      <div class="flex items-center truncate">
        <span
          class="hidden md:inline truncate ml-2 text-sm font-medium dark:text-slate-300 group-hover:text-slate-800 dark:group-hover:text-slate-200"
          >{{ userStoreDetails?.Name }}</span
        >
        <font-awesome-icon
          icon="fa-solid fa-chevron-down"
          class="w-3 h-3 ml-2"
        />
      </div>
    </button>
    <transition
      enter-active-class="transition ease-out duration-200 transform"
      enter-from-class="opacity-0 -translate-y-2"
      enter-to-class="opacity-100 translate-y-0"
      leave-active-class="transition ease-out duration-200"
      leave-from-class="opacity-100"
      leave-to-class="opacity-0"
    >
      <div
        v-show="dropdownOpen"
        class="origin-top-right z-10 absolute top-full min-w-44 bg-white dark:bg-slate-800 border border-slate-200 dark:border-slate-700 py-1.5 rounded shadow-lg overflow-hidden mt-1"
        :class="align === 'right' ? 'right-0' : 'left-0'"
      >
        <div
          class="md:hidden pt-0.5 pb-2 px-3 mb-1 border-b border-slate-200 dark:border-slate-700"
        >
          <div class="font-medium text-slate-800 dark:text-slate-100">
            {{ userStoreDetails?.Name }}
          </div>
          <!-- <div class="text-xs text-slate-500 dark:text-slate-400 italic">Administrator</div> -->
        </div>
        <ul
          ref="dropdown"
          @focusin="dropdownOpen = true"
          @focusout="dropdownOpen = false"
        >
          <li>
            <router-link
              class="font-medium text-sm text-indigo-500 hover:text-indigo-600 dark:hover:text-indigo-400 flex items-center py-1 px-3"
              to="/settings/account"
              @click="dropdownOpen = false"
              >Settings</router-link
            >
          </li>
          <li>
            <a
              class="font-medium text-sm text-indigo-500 hover:text-indigo-600 dark:hover:text-indigo-400 flex items-center py-1 px-3"
              href="#"
              @click="signOut"
              >Sign Out</a
            >
          </li>
        </ul>
      </div>
    </transition>
  </div>
</template>

<script>
import { ref, onMounted, onUnmounted } from "vue";
import { ContentLoader } from "vue-content-loader";
import { useAuth0 } from "@auth0/auth0-vue";
import { storeToRefs } from "pinia";

import { useUserStore } from "../stores/user";

export default {
  name: "DropdownProfile",
  props: ["align"],
  components: {
    ContentLoader,
  },
  setup() {
    const { logout } = useAuth0(); // user is a reactive ref
    const userStore = useUserStore();

    const { details: userStoreDetails, initials } = storeToRefs(userStore);

    const dropdownOpen = ref(false);
    const trigger = ref(null);
    const dropdown = ref(null);

    // close on click outside
    const clickHandler = ({ target }) => {
      if (
        !dropdownOpen.value ||
        dropdown.value.contains(target) ||
        trigger.value.contains(target)
      )
        return;
      dropdownOpen.value = false;
    };

    // close if the esc key is pressed
    const keyHandler = ({ keyCode }) => {
      if (!dropdownOpen.value || keyCode !== 27) return;
      dropdownOpen.value = false;
    };

    const signOut = () => {
      logout({
        returnTo: "https://www.manypenny.ca",
      });
    };

    onMounted(() => {
      document.addEventListener("click", clickHandler);
      document.addEventListener("keydown", keyHandler);
    });

    onUnmounted(() => {
      document.removeEventListener("click", clickHandler);
      document.removeEventListener("keydown", keyHandler);
    });

    return {
      dropdown,
      dropdownOpen,
      signOut,
      trigger,
      userStore,
      userStoreDetails,
      initials,
    };
  },
};
</script>

<template>
  <li
    v-intersection-observer="[
      onIntersectionObserver,
      {
        threshold: 1,
      },
    ]"
    class="border-b border-slate-200 dark:border-slate-700 last:border-0"
  >
    <router-link
      class="block py-2 px-4 hover:bg-slate-50 dark:hover:bg-slate-700/20"
      :to="`/products/${notification.Data.data.productId}`"
      @click="dropdownOpen = false"
    >
      <span class="block text-sm mb-2">
        <span class="font-medium text-slate-800 dark:text-slate-100">{{
          notification.Data.title[$i18n.locale === "fr" ? "fre" : "eng"]
        }}</span>
        {{
          notification.Data.alert[$i18n.locale === "fr" ? "fre" : "eng"]
        }}</span
      >
      <span
        class="block text-xs font-medium text-slate-400 dark:text-slate-500"
        >{{ new Date(notification.CreatedAt).toLocaleString() }}</span
      >
    </router-link>
  </li>
</template>

<script setup>
import { vIntersectionObserver } from "@vueuse/components";

import { markNotificationAsRead } from "../services/api";

const props = defineProps(["notification"]);

const markAsRead = async (notification) => {
  if (!notification?.id) return;
  if (notification?.Read) return;
  await markNotificationAsRead(notification.id);
};

const onIntersectionObserver = ([{ isIntersecting }]) => {
  if (!isIntersecting) return;
  if (!props?.notification) return;
  if (!props?.notification?.id) return;
  if (props?.notification?.Read) return;
  markAsRead(props.notification);
};
</script>

<template>
  <div class="min-w-fit">
    <!-- Sidebar backdrop (mobile only) -->
    <div
      class="fixed inset-0 bg-slate-900 bg-opacity-30 z-40 lg:hidden lg:z-auto transition-opacity duration-200"
      :class="sidebarOpen ? 'opacity-100' : 'opacity-0 pointer-events-none'"
      aria-hidden="true"
    ></div>

    <!-- Sidebar -->
    <div
      id="sidebar"
      ref="sidebar"
      class="flex flex-col absolute z-40 left-0 top-0 lg:static lg:left-auto lg:top-auto lg:translate-x-0 h-[100dvh] overflow-y-scroll lg:overflow-y-auto no-scrollbar w-64 lg:w-20 lg:sidebar-expanded:!w-64 2xl:!w-64 shrink-0 bg-slate-800 p-4 transition-all duration-200 ease-in-out"
      :class="sidebarOpen ? 'translate-x-0' : '-translate-x-64'"
    >
      <!-- Sidebar header -->
      <div class="flex justify-between mb-10 pr-3 sm:px-2">
        <!-- Close button -->
        <button
          ref="trigger"
          class="lg:hidden text-slate-500 hover:text-slate-400"
          @click.stop="$emit('close-sidebar')"
          aria-controls="sidebar"
          :aria-expanded="sidebarOpen"
        >
          <span class="sr-only">Close sidebar</span>
          <font-awesome-icon icon="fa-solid fa-arrow-left" class="w-6 h-6" />
        </button>
        <!-- Logo -->
        <router-link class="block" to="/">
          <img
            width="32"
            height="32"
            src="../images/manypenny-logo.svg"
            alt="Logo"
          />
        </router-link>
      </div>

      <!-- Links -->
      <div class="space-y-8">
        <!-- Pages group -->
        <div>
          <h3 class="text-xs uppercase text-slate-500 font-semibold pl-3">
            <span
              class="hidden lg:block lg:sidebar-expanded:hidden 2xl:hidden text-center w-6"
              aria-hidden="true"
              >•••</span
            >
            <span class="lg:hidden lg:sidebar-expanded:block 2xl:block"
              >Many Penny</span
            >
          </h3>
          <ul class="mt-3">
            <!-- Home -->
            <router-link
              to="/"
              custom
              v-slot="{ href, navigate, isExactActive }"
            >
              <li
                class="px-3 py-2 rounded-sm mb-0.5 last:mb-0"
                :class="isExactActive && 'bg-slate-900'"
              >
                <a
                  class="block text-slate-200 truncate transition duration-150"
                  :class="
                    isExactActive ? 'hover:text-slate-200' : 'hover:text-white'
                  "
                  :href="href"
                  @click="navigate"
                >
                  <div class="flex items-center">
                    <font-awesome-icon
                      icon="fa-duotone fa-house"
                      class="w-6 h-6"
                    />
                    <span
                      class="text-sm font-medium ml-3 lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200"
                      >Home</span
                    >
                  </div>
                </a>
              </li>
            </router-link>
            <!-- Lists -->
            <SidebarLinkGroup
              v-slot="parentLink"
              :activeCondition="currentRoute.fullPath.includes('lists')"
            >
              <a
                class="block text-slate-200 truncate transition duration-150"
                :class="
                  currentRoute.fullPath.includes('lists')
                    ? 'hover:text-slate-200'
                    : 'hover:text-white'
                "
                href="#0"
                @click.prevent="
                  parentLink.handleClick();
                  sidebarExpanded = true;
                "
              >
                <div class="flex items-center justify-between">
                  <div class="flex items-center">
                    <font-awesome-icon
                      icon="fa-duotone fa-list"
                      class="w-6 h-6"
                    />
                    <span
                      class="text-sm font-medium ml-3 lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200"
                      >Lists</span
                    >
                  </div>
                  <!-- Icon -->
                  <div class="flex shrink-0 ml-2">
                    <font-awesome-icon
                      :icon="[
                        'fas',
                        parentLink.expanded ? 'chevron-up' : 'chevron-down',
                      ]"
                      class="w-3 h-3"
                    />
                  </div>
                </div>
              </a>
              <div class="lg:hidden lg:sidebar-expanded:block 2xl:block">
                <ul class="pl-9 mt-1" :class="!parentLink.expanded && 'hidden'">
                  <router-link
                    to="/lists/show"
                    custom
                    v-slot="{ href, navigate, isExactActive }"
                  >
                    <li class="mb-1 last:mb-0">
                      <a
                        class="block transition duration-150 truncate"
                        :class="
                          isExactActive
                            ? 'text-indigo-500'
                            : 'text-slate-400 hover:text-slate-200'
                        "
                        :href="href"
                        @click="navigate"
                      >
                        <span
                          class="text-sm font-medium lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200"
                          >My Lists</span
                        >
                      </a>
                    </li>
                  </router-link>
                  <!-- <router-link
                    to="/community/users-tabs"
                    custom
                    v-slot="{ href, navigate, isExactActive }"
                  >
                    <li class="mb-1 last:mb-0">
                      <a
                        class="block transition duration-150 truncate"
                        :class="
                          isExactActive
                            ? 'text-indigo-500'
                            : 'text-slate-400 hover:text-slate-200'
                        "
                        :href="href"
                        @click="navigate"
                      >
                        <span
                          class="text-sm font-medium lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200"
                          >Users - Tabs</span
                        >
                      </a>
                    </li>
                  </router-link>
                  <router-link
                    to="/community/users-tiles"
                    custom
                    v-slot="{ href, navigate, isExactActive }"
                  >
                    <li class="mb-1 last:mb-0">
                      <a
                        class="block transition duration-150 truncate"
                        :class="
                          isExactActive
                            ? 'text-indigo-500'
                            : 'text-slate-400 hover:text-slate-200'
                        "
                        :href="href"
                        @click="navigate"
                      >
                        <span
                          class="text-sm font-medium lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200"
                          >Users - Tiles</span
                        >
                      </a>
                    </li>
                  </router-link>
                  <router-link
                    to="/community/profile"
                    custom
                    v-slot="{ href, navigate, isExactActive }"
                  >
                    <li class="mb-1 last:mb-0">
                      <a
                        class="block transition duration-150 truncate"
                        :class="
                          isExactActive
                            ? 'text-indigo-500'
                            : 'text-slate-400 hover:text-slate-200'
                        "
                        :href="href"
                        @click="navigate"
                      >
                        <span
                          class="text-sm font-medium lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200"
                          >Profile</span
                        >
                      </a>
                    </li>
                  </router-link>
                  <router-link
                    to="/community/feed"
                    custom
                    v-slot="{ href, navigate, isExactActive }"
                  >
                    <li class="mb-1 last:mb-0">
                      <a
                        class="block transition duration-150 truncate"
                        :class="
                          isExactActive
                            ? 'text-indigo-500'
                            : 'text-slate-400 hover:text-slate-200'
                        "
                        :href="href"
                        @click="navigate"
                      >
                        <span
                          class="text-sm font-medium lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200"
                          >Feed</span
                        >
                      </a>
                    </li>
                  </router-link>
                  <router-link
                    to="/community/forum"
                    custom
                    v-slot="{ href, navigate, isExactActive }"
                  >
                    <li class="mb-1 last:mb-0">
                      <a
                        class="block transition duration-150 truncate"
                        :class="
                          isExactActive
                            ? 'text-indigo-500'
                            : 'text-slate-400 hover:text-slate-200'
                        "
                        :href="href"
                        @click="navigate"
                      >
                        <span
                          class="text-sm font-medium lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200"
                          >Forum</span
                        >
                      </a>
                    </li>
                  </router-link>
                  <router-link
                    to="/community/forum-post"
                    custom
                    v-slot="{ href, navigate, isExactActive }"
                  >
                    <li class="mb-1 last:mb-0">
                      <a
                        class="block transition duration-150 truncate"
                        :class="
                          isExactActive
                            ? 'text-indigo-500'
                            : 'text-slate-400 hover:text-slate-200'
                        "
                        :href="href"
                        @click="navigate"
                      >
                        <span
                          class="text-sm font-medium lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200"
                          >Forum - Post</span
                        >
                      </a>
                    </li>
                  </router-link>
                  <router-link
                    to="/community/meetups"
                    custom
                    v-slot="{ href, navigate, isExactActive }"
                  >
                    <li class="mb-1 last:mb-0">
                      <a
                        class="block transition duration-150 truncate"
                        :class="
                          isExactActive
                            ? 'text-indigo-500'
                            : 'text-slate-400 hover:text-slate-200'
                        "
                        :href="href"
                        @click="navigate"
                      >
                        <span
                          class="text-sm font-medium lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200"
                          >Meetups</span
                        >
                      </a>
                    </li>
                  </router-link>
                  <router-link
                    to="/community/meetups-post"
                    custom
                    v-slot="{ href, navigate, isExactActive }"
                  >
                    <li class="mb-1 last:mb-0">
                      <a
                        class="block transition duration-150 truncate"
                        :class="
                          isExactActive
                            ? 'text-indigo-500'
                            : 'text-slate-400 hover:text-slate-200'
                        "
                        :href="href"
                        @click="navigate"
                      >
                        <span
                          class="text-sm font-medium lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200"
                          >Meetups - Post</span
                        >
                      </a>
                    </li>
                  </router-link> -->
                </ul>
              </div>
            </SidebarLinkGroup>
            <!-- Products  -->
            <SidebarLinkGroup
              v-slot="parentLink"
              :activeCondition="currentRoute.fullPath.includes('products')"
            >
              <a
                class="block text-slate-200 truncate transition duration-150"
                :class="
                  currentRoute.fullPath.includes('products')
                    ? 'hover:text-slate-200'
                    : 'hover:text-white'
                "
                href="#0"
                @click.prevent="
                  parentLink.handleClick();
                  sidebarExpanded = true;
                "
              >
                <div class="flex items-center justify-between">
                  <div class="flex items-center">
                    <font-awesome-icon
                      icon="fa-duotone fa-can-food"
                      class="w-6 h-6"
                    />
                    <span
                      class="text-sm font-medium ml-3 lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200"
                      >Products
                    </span>
                  </div>
                  <!-- Icon -->
                  <div class="flex shrink-0 ml-2">
                    <font-awesome-icon
                      :icon="[
                        'fas',
                        parentLink.expanded ? 'chevron-up' : 'chevron-down',
                      ]"
                      class="w-3 h-3"
                    />
                  </div>
                </div>
              </a>
              <div class="lg:hidden lg:sidebar-expanded:block 2xl:block">
                <ul class="pl-9 mt-1" :class="!parentLink.expanded && 'hidden'">
                  <router-link
                    to="/products/favorites"
                    custom
                    v-slot="{ href, navigate, isExactActive }"
                  >
                    <li class="mb-1 last:mb-0">
                      <a
                        class="block transition duration-150 truncate"
                        :class="
                          isExactActive
                            ? 'text-indigo-500'
                            : 'text-slate-400 hover:text-slate-200'
                        "
                        :href="href"
                        @click="navigate"
                      >
                        <span
                          class="text-sm font-medium lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200"
                          >Favorites</span
                        >
                      </a>
                    </li>
                  </router-link>
                  <router-link
                    to="/products"
                    custom
                    v-slot="{ href, navigate, isExactActive }"
                  >
                    <li class="mb-1 last:mb-0">
                      <a
                        class="block transition duration-150 truncate"
                        :class="
                          isExactActive
                            ? 'text-indigo-500'
                            : 'text-slate-400 hover:text-slate-200'
                        "
                        :href="href"
                        @click="navigate"
                      >
                        <span
                          class="text-sm font-medium lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200"
                          >Browse</span
                        >
                      </a>
                    </li>
                  </router-link>
                  <router-link
                    to="/products/search"
                    custom
                    v-slot="{ href, navigate, isExactActive }"
                  >
                    <li class="mb-1 last:mb-0">
                      <a
                        class="block transition duration-150 truncate"
                        :class="
                          isExactActive
                            ? 'text-indigo-500'
                            : 'text-slate-400 hover:text-slate-200'
                        "
                        :href="href"
                        @click="navigate"
                      >
                        <span
                          class="text-sm font-medium lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200"
                          >Search</span
                        >
                      </a>
                    </li>
                  </router-link>
                  <!-- <router-link
                    to="/ecommerce/customers"
                    custom
                    v-slot="{ href, navigate, isExactActive }"
                  >
                    <li class="mb-1 last:mb-0">
                      <a
                        class="block transition duration-150 truncate"
                        :class="
                          isExactActive
                            ? 'text-indigo-500'
                            : 'text-slate-400 hover:text-slate-200'
                        "
                        :href="href"
                        @click="navigate"
                      >
                        <span
                          class="text-sm font-medium lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200"
                          >Customers</span
                        >
                      </a>
                    </li>
                  </router-link>
                  <router-link
                    to="/ecommerce/orders"
                    custom
                    v-slot="{ href, navigate, isExactActive }"
                  >
                    <li class="mb-1 last:mb-0">
                      <a
                        class="block transition duration-150 truncate"
                        :class="
                          isExactActive
                            ? 'text-indigo-500'
                            : 'text-slate-400 hover:text-slate-200'
                        "
                        :href="href"
                        @click="navigate"
                      >
                        <span
                          class="text-sm font-medium lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200"
                          >Orders</span
                        >
                      </a>
                    </li>
                  </router-link>
                  <router-link
                    to="/ecommerce/invoices"
                    custom
                    v-slot="{ href, navigate, isExactActive }"
                  >
                    <li class="mb-1 last:mb-0">
                      <a
                        class="block transition duration-150 truncate"
                        :class="
                          isExactActive
                            ? 'text-indigo-500'
                            : 'text-slate-400 hover:text-slate-200'
                        "
                        :href="href"
                        @click="navigate"
                      >
                        <span
                          class="text-sm font-medium lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200"
                          >Invoices</span
                        >
                      </a>
                    </li>
                  </router-link>
                  <router-link
                    to="/ecommerce/shop"
                    custom
                    v-slot="{ href, navigate, isExactActive }"
                  >
                    <li class="mb-1 last:mb-0">
                      <a
                        class="block transition duration-150 truncate"
                        :class="
                          isExactActive
                            ? 'text-indigo-500'
                            : 'text-slate-400 hover:text-slate-200'
                        "
                        :href="href"
                        @click="navigate"
                      >
                        <span
                          class="text-sm font-medium lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200"
                          >Shop</span
                        >
                      </a>
                    </li>
                  </router-link>
                  <router-link
                    to="/ecommerce/shop-2"
                    custom
                    v-slot="{ href, navigate, isExactActive }"
                  >
                    <li class="mb-1 last:mb-0">
                      <a
                        class="block transition duration-150 truncate"
                        :class="
                          isExactActive
                            ? 'text-indigo-500'
                            : 'text-slate-400 hover:text-slate-200'
                        "
                        :href="href"
                        @click="navigate"
                      >
                        <span
                          class="text-sm font-medium lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200"
                          >Shop 2</span
                        >
                      </a>
                    </li>
                  </router-link>
                  <router-link
                    to="/ecommerce/product"
                    custom
                    v-slot="{ href, navigate, isExactActive }"
                  >
                    <li class="mb-1 last:mb-0">
                      <a
                        class="block transition duration-150 truncate"
                        :class="
                          isExactActive
                            ? 'text-indigo-500'
                            : 'text-slate-400 hover:text-slate-200'
                        "
                        :href="href"
                        @click="navigate"
                      >
                        <span
                          class="text-sm font-medium lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200"
                          >Single Product</span
                        >
                      </a>
                    </li>
                  </router-link>
                  <router-link
                    to="/ecommerce/cart"
                    custom
                    v-slot="{ href, navigate, isExactActive }"
                  >
                    <li class="mb-1 last:mb-0">
                      <a
                        class="block transition duration-150 truncate"
                        :class="
                          isExactActive
                            ? 'text-indigo-500'
                            : 'text-slate-400 hover:text-slate-200'
                        "
                        :href="href"
                        @click="navigate"
                      >
                        <span
                          class="text-sm font-medium lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200"
                          >Cart</span
                        >
                      </a>
                    </li>
                  </router-link>
                  <router-link
                    to="/ecommerce/cart-2"
                    custom
                    v-slot="{ href, navigate, isExactActive }"
                  >
                    <li class="mb-1 last:mb-0">
                      <a
                        class="block transition duration-150 truncate"
                        :class="
                          isExactActive
                            ? 'text-indigo-500'
                            : 'text-slate-400 hover:text-slate-200'
                        "
                        :href="href"
                        @click="navigate"
                      >
                        <span
                          class="text-sm font-medium lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200"
                          >Cart 2</span
                        >
                      </a>
                    </li>
                  </router-link>
                  <router-link
                    to="/ecommerce/cart-3"
                    custom
                    v-slot="{ href, navigate, isExactActive }"
                  >
                    <li class="mb-1 last:mb-0">
                      <a
                        class="block transition duration-150 truncate"
                        :class="
                          isExactActive
                            ? 'text-indigo-500'
                            : 'text-slate-400 hover:text-slate-200'
                        "
                        :href="href"
                        @click="navigate"
                      >
                        <span
                          class="text-sm font-medium lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200"
                          >Cart 3</span
                        >
                      </a>
                    </li>
                  </router-link>
                  <router-link
                    to="/ecommerce/pay"
                    custom
                    v-slot="{ href, navigate, isExactActive }"
                  >
                    <li class="mb-1 last:mb-0">
                      <a
                        class="block transition duration-150 truncate"
                        :class="
                          isExactActive
                            ? 'text-indigo-500'
                            : 'text-slate-400 hover:text-slate-200'
                        "
                        :href="href"
                        @click="navigate"
                      >
                        <span
                          class="text-sm font-medium lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200"
                          >Pay</span
                        >
                      </a>
                    </li>
                  </router-link> -->
                </ul>
              </div>
            </SidebarLinkGroup>
            <!-- Prices -->
            <SidebarLinkGroup
              v-slot="parentLink"
              :activeCondition="currentRoute.fullPath.includes('prices')"
            >
              <a
                class="block text-slate-200 truncate transition duration-150"
                :class="
                  currentRoute.fullPath.includes('prices')
                    ? 'hover:text-slate-200'
                    : 'hover:text-white'
                "
                href="#0"
                @click.prevent="
                  parentLink.handleClick();
                  sidebarExpanded = true;
                "
              >
                <div class="flex items-center justify-between">
                  <div class="flex items-center">
                    <font-awesome-icon
                      icon="fa-duotone fa-money-bill-1-wave"
                      class="w-6 h-6"
                    />
                    <span
                      class="text-sm font-medium ml-3 lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200"
                      >Prices</span
                    >
                  </div>
                  <!-- Icon -->
                  <div class="flex shrink-0 ml-2">
                    <font-awesome-icon
                      :icon="[
                        'fas',
                        parentLink.expanded ? 'chevron-up' : 'chevron-down',
                      ]"
                      class="w-3 h-3"
                    />
                  </div>
                </div>
              </a>
              <div class="lg:hidden lg:sidebar-expanded:block 2xl:block">
                <ul class="pl-9 mt-1" :class="!parentLink.expanded && 'hidden'">
                  <router-link
                    to="/prices/kanban"
                    custom
                    v-slot="{ href, navigate, isExactActive }"
                  >
                    <li class="mb-1 last:mb-0">
                      <a
                        class="block transition duration-150 truncate"
                        :class="
                          isExactActive
                            ? 'text-indigo-500'
                            : 'text-slate-400 hover:text-slate-200'
                        "
                        :href="href"
                        @click="navigate"
                      >
                        <span
                          class="text-sm font-medium lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200"
                          >Kanban</span
                        >
                      </a>
                    </li>
                  </router-link>
                  <router-link
                    to="/prices/list"
                    custom
                    v-slot="{ href, navigate, isExactActive }"
                  >
                    <li class="mb-1 last:mb-0">
                      <a
                        class="block transition duration-150 truncate"
                        :class="
                          isExactActive
                            ? 'text-indigo-500'
                            : 'text-slate-400 hover:text-slate-200'
                        "
                        :href="href"
                        @click="navigate"
                      >
                        <span
                          class="text-sm font-medium lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200"
                          >List</span
                        >
                      </a>
                    </li>
                  </router-link>
                </ul>
              </div>
            </SidebarLinkGroup>
            <!-- Promotions -->
            <SidebarLinkGroup
              v-slot="parentLink"
              :activeCondition="currentRoute.fullPath.includes('promotions')"
            >
              <a
                class="block text-slate-200 truncate transition duration-150"
                :class="
                  currentRoute.fullPath.includes('promotions')
                    ? 'hover:text-slate-200'
                    : 'hover:text-white'
                "
                href="#0"
                @click.prevent="
                  parentLink.handleClick();
                  sidebarExpanded = true;
                "
              >
                <div class="flex items-center justify-between">
                  <div class="flex items-center">
                    <font-awesome-icon
                      icon="fa-duotone fa-percent"
                      class="w-6 h-6"
                    />
                    <span
                      class="text-sm font-medium ml-3 lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200"
                      >Promotions</span
                    >
                  </div>
                  <!-- Icon -->
                  <div class="flex shrink-0 ml-2">
                    <font-awesome-icon
                      :icon="[
                        'fas',
                        parentLink.expanded ? 'chevron-up' : 'chevron-down',
                      ]"
                      class="w-3 h-3"
                    />
                  </div>
                </div>
              </a>
              <div class="lg:hidden lg:sidebar-expanded:block 2xl:block">
                <ul class="pl-9 mt-1" :class="!parentLink.expanded && 'hidden'">
                  <router-link
                    to="/prices/kanban"
                    custom
                    v-slot="{ href, navigate, isExactActive }"
                  >
                    <li class="mb-1 last:mb-0">
                      <a
                        class="block transition duration-150 truncate"
                        :class="
                          isExactActive
                            ? 'text-indigo-500'
                            : 'text-slate-400 hover:text-slate-200'
                        "
                        :href="href"
                        @click="navigate"
                      >
                        <span
                          class="text-sm font-medium lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200"
                          >Kanban</span
                        >
                      </a>
                    </li>
                  </router-link>
                  <router-link
                    to="/prices/list"
                    custom
                    v-slot="{ href, navigate, isExactActive }"
                  >
                    <li class="mb-1 last:mb-0">
                      <a
                        class="block transition duration-150 truncate"
                        :class="
                          isExactActive
                            ? 'text-indigo-500'
                            : 'text-slate-400 hover:text-slate-200'
                        "
                        :href="href"
                        @click="navigate"
                      >
                        <span
                          class="text-sm font-medium lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200"
                          >List</span
                        >
                      </a>
                    </li>
                  </router-link>
                </ul>
              </div>
            </SidebarLinkGroup>
            <!-- Stores -->
            <SidebarLinkGroup
              v-slot="parentLink"
              :activeCondition="currentRoute.fullPath.includes('stores')"
            >
              <a
                class="block text-slate-200 truncate transition duration-150"
                :class="
                  currentRoute.fullPath.includes('stores')
                    ? 'hover:text-slate-200'
                    : 'hover:text-white'
                "
                href="#0"
                @click.prevent="
                  parentLink.handleClick();
                  sidebarExpanded = true;
                "
              >
                <div class="flex items-center justify-between">
                  <div class="flex items-center">
                    <font-awesome-icon
                      icon="fa-duotone fa-store"
                      class="w-6 h-6"
                    />
                    <span
                      class="text-sm font-medium ml-3 lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200"
                      >Stores</span
                    >
                  </div>
                  <!-- Icon -->
                  <div class="flex shrink-0 ml-2">
                    <font-awesome-icon
                      :icon="[
                        'fas',
                        parentLink.expanded ? 'chevron-up' : 'chevron-down',
                      ]"
                      class="w-3 h-3"
                    />
                  </div>
                </div>
              </a>
              <div class="lg:hidden lg:sidebar-expanded:block 2xl:block">
                <ul class="pl-9 mt-1" :class="!parentLink.expanded && 'hidden'">
                  <router-link
                    to="/stores/favorites"
                    custom
                    v-slot="{ href, navigate, isExactActive }"
                  >
                    <li class="mb-1 last:mb-0">
                      <a
                        class="block transition duration-150 truncate"
                        :class="
                          isExactActive
                            ? 'text-indigo-500'
                            : 'text-slate-400 hover:text-slate-200'
                        "
                        :href="href"
                        @click="navigate"
                      >
                        <span
                          class="text-sm font-medium lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200"
                          >Favorites</span
                        >
                      </a>
                    </li>
                  </router-link>
                  <router-link
                    to="/stores"
                    custom
                    v-slot="{ href, navigate, isExactActive }"
                  >
                    <li class="mb-1 last:mb-0">
                      <a
                        class="block transition duration-150 truncate"
                        :class="
                          isExactActive
                            ? 'text-indigo-500'
                            : 'text-slate-400 hover:text-slate-200'
                        "
                        :href="href"
                        @click="navigate"
                      >
                        <span
                          class="text-sm font-medium lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200"
                          >Browse</span
                        >
                      </a>
                    </li>
                  </router-link>
                  <router-link
                    to="/stores/search"
                    custom
                    v-slot="{ href, navigate, isExactActive }"
                  >
                    <li class="mb-1 last:mb-0">
                      <a
                        class="block transition duration-150 truncate"
                        :class="
                          isExactActive
                            ? 'text-indigo-500'
                            : 'text-slate-400 hover:text-slate-200'
                        "
                        :href="href"
                        @click="navigate"
                      >
                        <span
                          class="text-sm font-medium lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200"
                          >Search</span
                        >
                      </a>
                    </li>
                  </router-link>
                </ul>
              </div>
            </SidebarLinkGroup>
            <!-- Chains -->
            <SidebarLinkGroup
              v-slot="parentLink"
              :activeCondition="currentRoute.fullPath.includes('chains')"
            >
              <a
                class="block text-slate-200 truncate transition duration-150"
                :class="
                  currentRoute.fullPath.includes('chains')
                    ? 'hover:text-slate-200'
                    : 'hover:text-white'
                "
                href="#0"
                @click.prevent="
                  parentLink.handleClick();
                  sidebarExpanded = true;
                "
              >
                <div class="flex items-center justify-between">
                  <div class="flex items-center">
                    <font-awesome-icon
                      icon="fa-duotone fa-flag-pennant"
                      class="w-6 h-6"
                    />
                    <span
                      class="text-sm font-medium ml-3 lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200"
                      >Chains</span
                    >
                  </div>
                  <!-- Icon -->
                  <div class="flex shrink-0 ml-2">
                    <font-awesome-icon
                      :icon="[
                        'fas',
                        parentLink.expanded ? 'chevron-up' : 'chevron-down',
                      ]"
                      class="w-3 h-3"
                    />
                  </div>
                </div>
              </a>
              <div class="lg:hidden lg:sidebar-expanded:block 2xl:block">
                <ul class="pl-9 mt-1" :class="!parentLink.expanded && 'hidden'">
                  <router-link
                    to="/prices/kanban"
                    custom
                    v-slot="{ href, navigate, isExactActive }"
                  >
                    <li class="mb-1 last:mb-0">
                      <a
                        class="block transition duration-150 truncate"
                        :class="
                          isExactActive
                            ? 'text-indigo-500'
                            : 'text-slate-400 hover:text-slate-200'
                        "
                        :href="href"
                        @click="navigate"
                      >
                        <span
                          class="text-sm font-medium lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200"
                          >Kanban</span
                        >
                      </a>
                    </li>
                  </router-link>
                  <router-link
                    to="/prices/list"
                    custom
                    v-slot="{ href, navigate, isExactActive }"
                  >
                    <li class="mb-1 last:mb-0">
                      <a
                        class="block transition duration-150 truncate"
                        :class="
                          isExactActive
                            ? 'text-indigo-500'
                            : 'text-slate-400 hover:text-slate-200'
                        "
                        :href="href"
                        @click="navigate"
                      >
                        <span
                          class="text-sm font-medium lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200"
                          >List</span
                        >
                      </a>
                    </li>
                  </router-link>
                </ul>
              </div>
            </SidebarLinkGroup>
            <!-- History -->
            <SidebarLinkGroup
              v-slot="parentLink"
              :activeCondition="currentRoute.fullPath.includes('history')"
            >
              <a
                class="block text-slate-200 truncate transition duration-150"
                :class="
                  currentRoute.fullPath.includes('history')
                    ? 'hover:text-slate-200'
                    : 'hover:text-white'
                "
                href="#0"
                @click.prevent="
                  parentLink.handleClick();
                  sidebarExpanded = true;
                "
              >
                <div class="flex items-center justify-between">
                  <div class="flex items-center">
                    <font-awesome-icon
                      icon="fa-duotone fa-clock-rotate-left"
                      class="w-6 h-6"
                    />
                    <span
                      class="text-sm font-medium ml-3 lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200"
                      >History</span
                    >
                  </div>
                  <!-- Icon -->
                  <div class="flex shrink-0 ml-2">
                    <font-awesome-icon
                      :icon="[
                        'fas',
                        parentLink.expanded ? 'chevron-up' : 'chevron-down',
                      ]"
                      class="w-3 h-3"
                    />
                  </div>
                </div>
              </a>
              <div class="lg:hidden lg:sidebar-expanded:block 2xl:block">
                <ul class="pl-9 mt-1" :class="!parentLink.expanded && 'hidden'">
                  <router-link
                    to="/job/job-listing"
                    custom
                    v-slot="{ href, navigate, isExactActive }"
                  >
                    <li class="mb-1 last:mb-0">
                      <a
                        class="block transition duration-150 truncate"
                        :class="
                          isExactActive
                            ? 'text-indigo-500'
                            : 'text-slate-400 hover:text-slate-200'
                        "
                        :href="href"
                        @click="navigate"
                      >
                        <span
                          class="text-sm font-medium lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200"
                          >Listing</span
                        >
                      </a>
                    </li>
                  </router-link>
                  <router-link
                    to="/job/job-post"
                    custom
                    v-slot="{ href, navigate, isExactActive }"
                  >
                    <li class="mb-1 last:mb-0">
                      <a
                        class="block transition duration-150 truncate"
                        :class="
                          isExactActive
                            ? 'text-indigo-500'
                            : 'text-slate-400 hover:text-slate-200'
                        "
                        :href="href"
                        @click="navigate"
                      >
                        <span
                          class="text-sm font-medium lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200"
                          >Job Post</span
                        >
                      </a>
                    </li>
                  </router-link>
                  <router-link
                    to="/job/company-profile"
                    custom
                    v-slot="{ href, navigate, isExactActive }"
                  >
                    <li class="mb-1 last:mb-0">
                      <a
                        class="block transition duration-150 truncate"
                        :class="
                          isExactActive
                            ? 'text-indigo-500'
                            : 'text-slate-400 hover:text-slate-200'
                        "
                        :href="href"
                        @click="navigate"
                      >
                        <span
                          class="text-sm font-medium lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200"
                          >Company Profile</span
                        >
                      </a>
                    </li>
                  </router-link>
                </ul>
              </div>
            </SidebarLinkGroup>
            <!-- Settings -->
            <SidebarLinkGroup
              v-slot="parentLink"
              :activeCondition="currentRoute.fullPath.includes('settings')"
            >
              <a
                class="block text-slate-200 truncate transition duration-150"
                :class="
                  currentRoute.fullPath.includes('settings')
                    ? 'hover:text-slate-200'
                    : 'hover:text-white'
                "
                href="#0"
                @click.prevent="
                  parentLink.handleClick();
                  sidebarExpanded = true;
                "
              >
                <div class="flex items-center justify-between">
                  <div class="flex items-center">
                    <font-awesome-icon
                      icon="fa-duotone fa-gears"
                      class="w-6 h-6"
                    />
                    <span
                      class="text-sm font-medium ml-3 lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200"
                      >Settings</span
                    >
                  </div>
                  <!-- Icon -->
                  <div class="flex shrink-0 ml-2">
                    <font-awesome-icon
                      :icon="[
                        'fas',
                        parentLink.expanded ? 'chevron-up' : 'chevron-down',
                      ]"
                      class="w-3 h-3"
                    />
                  </div>
                </div>
              </a>
              <div class="lg:hidden lg:sidebar-expanded:block 2xl:block">
                <ul class="pl-9 mt-1" :class="!parentLink.expanded && 'hidden'">
                  <router-link
                    to="/settings/account"
                    custom
                    v-slot="{ href, navigate, isExactActive }"
                  >
                    <li class="mb-1 last:mb-0">
                      <a
                        class="block transition duration-150 truncate"
                        :class="
                          isExactActive
                            ? 'text-indigo-500'
                            : 'text-slate-400 hover:text-slate-200'
                        "
                        :href="href"
                        @click="navigate"
                      >
                        <span
                          class="text-sm font-medium lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200"
                          >My Account</span
                        >
                      </a>
                    </li>
                  </router-link>
                  <router-link
                    to="/settings/notifications"
                    custom
                    v-slot="{ href, navigate, isExactActive }"
                  >
                    <li class="mb-1 last:mb-0">
                      <a
                        class="block transition duration-150 truncate"
                        :class="
                          isExactActive
                            ? 'text-indigo-500'
                            : 'text-slate-400 hover:text-slate-200'
                        "
                        :href="href"
                        @click="navigate"
                      >
                        <span
                          class="text-sm font-medium lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200"
                          >My Notifications</span
                        >
                      </a>
                    </li>
                  </router-link>
                  <router-link
                    to="/settings/apps"
                    custom
                    v-slot="{ href, navigate, isExactActive }"
                  >
                    <li class="mb-1 last:mb-0">
                      <a
                        class="block transition duration-150 truncate"
                        :class="
                          isExactActive
                            ? 'text-indigo-500'
                            : 'text-slate-400 hover:text-slate-200'
                        "
                        :href="href"
                        @click="navigate"
                      >
                        <span
                          class="text-sm font-medium lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200"
                          >Connected Apps</span
                        >
                      </a>
                    </li>
                  </router-link>
                  <router-link
                    to="/settings/plans"
                    custom
                    v-slot="{ href, navigate, isExactActive }"
                  >
                    <li class="mb-1 last:mb-0">
                      <a
                        class="block transition duration-150 truncate"
                        :class="
                          isExactActive
                            ? 'text-indigo-500'
                            : 'text-slate-400 hover:text-slate-200'
                        "
                        :href="href"
                        @click="navigate"
                      >
                        <span
                          class="text-sm font-medium lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200"
                          >Plans</span
                        >
                      </a>
                    </li>
                  </router-link>
                  <router-link
                    to="/settings/billing"
                    custom
                    v-slot="{ href, navigate, isExactActive }"
                  >
                    <li class="mb-1 last:mb-0">
                      <a
                        class="block transition duration-150 truncate"
                        :class="
                          isExactActive
                            ? 'text-indigo-500'
                            : 'text-slate-400 hover:text-slate-200'
                        "
                        :href="href"
                        @click="navigate"
                      >
                        <span
                          class="text-sm font-medium lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200"
                          >Billing & Invoices</span
                        >
                      </a>
                    </li>
                  </router-link>
                  <router-link
                    to="/settings/feedback"
                    custom
                    v-slot="{ href, navigate, isExactActive }"
                  >
                    <li class="mb-1 last:mb-0">
                      <a
                        class="block transition duration-150 truncate"
                        :class="
                          isExactActive
                            ? 'text-indigo-500'
                            : 'text-slate-400 hover:text-slate-200'
                        "
                        :href="href"
                        @click="navigate"
                      >
                        <span
                          class="text-sm font-medium lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200"
                          >Give Feedback</span
                        >
                      </a>
                    </li>
                  </router-link>
                </ul>
              </div>
            </SidebarLinkGroup>
          </ul>
        </div>
        <!-- <SidebarAdvanced /> -->
      </div>

      <!-- Expand / collapse button -->
      <div class="pt-3 hidden lg:inline-flex 2xl:hidden justify-end mt-auto">
        <div class="px-3 py-2">
          <button @click.prevent="sidebarExpanded = !sidebarExpanded">
            <span class="sr-only">Expand / collapse sidebar</span>
            <font-awesome-icon
              :icon="[
                'fad',
                sidebarExpanded
                  ? 'arrow-left-from-line'
                  : 'arrow-right-from-line',
              ]"
              class="w-6 h-6"
            />
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted, onUnmounted, watch } from "vue";
import { useRouter } from "vue-router";
import { storeToRefs } from "pinia";

import { useUIStore } from "../stores/ui";
import SidebarAdvanced from "./SidebarAdvanced.vue";
import SidebarLinkGroup from "./SidebarLinkGroup.vue";

export default {
  name: "Sidebar",
  props: ["sidebarOpen"],
  components: {
    SidebarAdvanced,
    SidebarLinkGroup,
  },
  setup(props, { emit }) {
    const uiStore = useUIStore();

    const trigger = ref(null);
    const sidebar = ref(null);

    const { sidebarExpanded } = storeToRefs(uiStore);

    const currentRoute = useRouter().currentRoute.value;

    // close on click outside
    const clickHandler = ({ target }) => {
      if (!sidebar.value || !trigger.value) return;
      if (
        !props.sidebarOpen ||
        sidebar.value.contains(target) ||
        trigger.value.contains(target)
      )
        return;
      emit("close-sidebar");
    };

    // close if the esc key is pressed
    const keyHandler = ({ keyCode }) => {
      if (!props.sidebarOpen || keyCode !== 27) return;
      emit("close-sidebar");
    };

    onMounted(() => {
      document.addEventListener("click", clickHandler);
      document.addEventListener("keydown", keyHandler);
    });

    onUnmounted(() => {
      document.removeEventListener("click", clickHandler);
      document.removeEventListener("keydown", keyHandler);
    });

    return {
      trigger,
      sidebar,
      sidebarExpanded,
      currentRoute,
    };
  },
};
</script>

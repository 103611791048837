<template>
  <div class="flex h-[100dvh] overflow-hidden">
    <!-- Sidebar -->
    <Sidebar :sidebarOpen="sidebarOpen" @close-sidebar="sidebarOpen = false" />

    <!-- Content area -->
    <div
      class="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden"
    >
      <!-- Site header -->
      <Header
        :sidebarOpen="sidebarOpen"
        @toggle-sidebar="sidebarOpen = !sidebarOpen"
      />

      <main class="grow">
        <div class="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
          <!-- Page header -->
          <div class="mb-5">
            <!-- Title -->
            <h1
              class="text-2xl md:text-3xl text-slate-800 dark:text-slate-100 font-bold"
            >
              Find the right product for you ✨
            </h1>
          </div>

          <!-- Search form -->
          <div class="max-w-xl mb-5">
            <form class="relative">
              <label for="app-search" class="sr-only">Search</label>
              <input
                id="app-search"
                class="form-input w-full pl-9 py-3 bg-white dark:bg-slate-800"
                type="search"
                placeholder="Search…"
              />
              <button
                class="absolute inset-0 right-auto group"
                type="submit"
                aria-label="Search"
              >
                <svg
                  class="w-4 h-4 shrink-0 fill-current text-slate-400 dark:text-slate-500 group-hover:text-slate-500 dark:group-hover:text-slate-400 ml-3 mr-2"
                  viewBox="0 0 16 16"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M7 14c-3.86 0-7-3.14-7-7s3.14-7 7-7 7 3.14 7 7-3.14 7-7 7zM7 2C4.243 2 2 4.243 2 7s2.243 5 5 5 5-2.243 5-5-2.243-5-5-5z"
                  />
                  <path
                    d="M15.707 14.293L13.314 11.9a8.019 8.019 0 01-1.414 1.414l2.393 2.393a.997.997 0 001.414 0 .999.999 0 000-1.414z"
                  />
                </svg>
              </button>
            </form>
          </div>

          <!-- Page content -->
          <div
            class="flex flex-col space-y-10 sm:flex-row sm:space-x-6 sm:space-y-0 md:flex-col md:space-x-0 md:space-y-10 xl:flex-row xl:space-x-6 xl:space-y-0 mt-9"
          >
            <!-- Sidebar -->
            <ShopSidebar />

            <!-- Content -->
            <div>
              <!-- Filters -->
              <div class="mb-5">
                <ul class="flex flex-wrap -m-1">
                  <li class="m-1">
                    <button
                      class="inline-flex items-center justify-center text-sm font-medium leading-5 rounded-full px-3 py-1 border border-transparent shadow-sm bg-indigo-500 text-white duration-150 ease-in-out"
                    >
                      View All
                    </button>
                  </li>
                  <li class="m-1">
                    <button
                      class="inline-flex items-center justify-center text-sm font-medium leading-5 rounded-full px-3 py-1 border border-slate-200 dark:border-slate-700 hover:border-slate-300 dark:hover:border-slate-600 shadow-sm bg-white dark:bg-slate-800 text-slate-500 dark:text-slate-400 duration-150 ease-in-out"
                    >
                      Featured
                    </button>
                  </li>
                  <li class="m-1">
                    <button
                      class="inline-flex items-center justify-center text-sm font-medium leading-5 rounded-full px-3 py-1 border border-slate-200 dark:border-slate-700 hover:border-slate-300 dark:hover:border-slate-600 shadow-sm bg-white dark:bg-slate-800 text-slate-500 dark:text-slate-400 duration-150 ease-in-out"
                    >
                      Newest
                    </button>
                  </li>
                  <li class="m-1">
                    <button
                      class="inline-flex items-center justify-center text-sm font-medium leading-5 rounded-full px-3 py-1 border border-slate-200 dark:border-slate-700 hover:border-slate-300 dark:hover:border-slate-600 shadow-sm bg-white dark:bg-slate-800 text-slate-500 dark:text-slate-400 duration-150 ease-in-out"
                    >
                      Price - Low To High
                    </button>
                  </li>
                  <li class="m-1">
                    <button
                      class="inline-flex items-center justify-center text-sm font-medium leading-5 rounded-full px-3 py-1 border border-slate-200 dark:border-slate-700 hover:border-slate-300 dark:hover:border-slate-600 shadow-sm bg-white dark:bg-slate-800 text-slate-500 dark:text-slate-400 duration-150 ease-in-out"
                    >
                      Price - High to Low
                    </button>
                  </li>
                </ul>
              </div>

              <div
                class="text-sm text-slate-500 dark:text-slate-400 italic mb-4"
              >
                {{ result?.getTotalResults()?.toLocaleString() }} products
              </div>

              <div v-if="!loading && result">
                <div class="grid grid-cols-12 gap-6">
                  <ProductCard
                    v-for="product in result"
                    :key="product.id"
                    :product="product"
                  />
                </div>
              </div>

              <!-- Pagination -->
              <div class="mt-6">
                <PaginationClassic
                  :currentPage="currentPage"
                  :totalItems="totalItems"
                  :itemsPerPage="limit"
                  @change-page="handleChangePage"
                />
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  </div>
</template>

<script>
import { ref, watch } from "vue";
import { usePromiseLazy } from "vue-composable";

import Sidebar from "../../partials/Sidebar.vue";
import Header from "../../partials/Header.vue";
import ShopSidebar from "../../partials/ecommerce/ShopSidebar.vue";
import PaginationClassic from "../../components/PaginationClassic.vue";

import ProductCard from "../../components/products/Card.vue";

import { getProducts } from "../../services/api";

const limit = 12;

export default {
  name: "Shop2",
  components: {
    Sidebar,
    Header,
    ShopSidebar,
    ProductCard,
    PaginationClassic,
  },
  setup() {
    const currentPage = ref(1);
    const sidebarOpen = ref(false);
    const totalItems = ref(0);
    const offset = ref(0);

    const { exec, error, loading, result } = usePromiseLazy((l, o) =>
      getProducts({ limit: l, offset: o }),
    );

    exec(limit, offset.value).then((res) => {
      totalItems.value = res.getTotalResults();
    });

    watch(
      () => currentPage.value,
      async () => {
        offset.value = (currentPage.value - 1) * limit;
        await exec(limit, offset.value);
      },
    );

    return {
      currentPage,
      error,
      limit,
      loading,
      offset,
      result,
      sidebarOpen,
      totalItems,
    };
  },
  methods: {
    handleChangePage(page) {
      this.currentPage = page;
    },
  },
};
</script>

import _ from "lodash";
import axios from "axios";
import { backOff } from "exponential-backoff";
import localforage from "localforage";
import set from "lodash.set";

import { getAccessToken } from "../plugins/auth0";
import { useGeolocationStore } from "../stores/geolocation";

const baseURL = import.meta.env.VITE_API_URL;
const etagHeader = import.meta.env.VITE_API_ETAG_HEADER;
// const newsletterOrigin = import.meta.env.VITE_NEWSLETTER_ORIGIN;
const positionHeader = import.meta.env.VITE_API_POSITION_HEADER;
const totalResultsHeader = import.meta.env.VITE_API_TOTAL_RESULTS_HEADER;

const defaultLimit = 25;
const maxLimit = 100;

// Initialize localForage
const cache = localforage.createInstance({
  name: "manypenny",
  storeName: "api",
});

// Initialize axios with default config
const api = axios.create({
  baseURL,
  timeout: 15000,
});

// Helper function to generate a SHA-256 hash
const generateHash = async (input) => {
  const encoder = new TextEncoder();
  const data = encoder.encode(input);
  const hash = await crypto.subtle.digest("SHA-256", data);
  return Array.from(new Uint8Array(hash))
    .map((b) => b.toString(16).padStart(2, "0"))
    .join("");
};

// Helper function to generate a cache key based on the URL and its parameters
const generateCacheKey = async (config) =>
  generateHash(
    JSON.stringify({
      method: config.method,
      url: config.url,
      data: config.data || null,
      params: config.params || null,
    }),
  );

// Interceptor to include Auth0 Bearer token
api.interceptors.request.use(
  async (config) => {
    try {
      const token = await getAccessToken();
      set(config, "headers.Authorization", `Bearer ${token}`);
    } catch (error) {
      console.error("Error adding Auth0 Bearer token:", error);
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

// Interceptor to include cache headers
api.interceptors.request.use(
  async (config) => {
    if (config.method !== "get") {
      return config;
    }
    // Generate cache key
    config.cacheKey = await generateCacheKey(config);
    // Check localForage for an ETag for this cache key
    const etag = await cache.getItem(`${config.cacheKey}:etag`);
    if (etag) {
      // Set the X-If-None-Match header
      config.headers["X-If-None-Match"] = etag;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

// Interceptor to include position query parameter
// api.interceptors.request.use(async (config) => {
//   if (config.method !== "get") {
//     return config;
//   }
//   const geolocationStore = useGeolocationStore();
//   if (
//     geolocationStore.coords &&
//     geolocationStore.coords.latitude &&
//     geolocationStore.coords.longitude
//   ) {
//     const { latitude, longitude } = geolocationStore.coords;
//     config.params = {
//       ...config.params,
//       p: `${longitude},${latitude}`,
//     };
//   }
//   return config;
// });

// Approximate position interceptor
api.interceptors.response.use(async (response) => {
  if (response && response.headers && response.headers[positionHeader]) {
    const geolocationStore = useGeolocationStore();
    const position = String(response.headers[positionHeader]).split(",");
    if (position && position.length === 2) {
      geolocationStore.approximatePosition = position;
    }
  }
  return response;
});

// Cache interceptor
api.interceptors.response.use(
  async (response) => {
    if (response.config.cacheKey) {
      const etag = response.headers[etagHeader];
      if (etag) {
        // Store the ETag and response data in localForage
        await cache.setItem(`${response.config.cacheKey}:etag`, etag);
        await cache.setItem(`${response.config.cacheKey}:data`, response.data);
      }
    }
    return response;
  },
  async (error) => {
    if (
      error.response &&
      error.response.status === 304 &&
      error.response.config.cacheKey
    ) {
      // If 304, retrieve cached data from localForage
      error.response.data = await cache.getItem(
        `${error.response.config.cacheKey}:data`,
      );
      return Promise.resolve(error.response);
    }
    return Promise.reject(error);
  },
);

// Function to add the localized helper method
const addLocalizedMethod = (data) => {
  if (Array.isArray(data)) {
    // Add the helper method to each item in the array
    data.forEach((item) => addLocalizedMethod(item));
  } else if (typeof data === "object") {
    // Add the localized helper method to this object
    if (data.Localized) {
      data.localized = function (lang) {
        let localizedData = this.Localized?.find(
          (item) => item.Language === lang,
        );

        if (!localizedData) {
          localizedData = this.Localized?.find(
            (item) => item.Language === "eng",
          );
        }

        if (!localizedData) {
          localizedData = this.Localized?.[0] || null;
        }

        return localizedData;
      };
    }

    // Recursively apply to all properties
    for (const key of Object.keys(data)) {
      addLocalizedMethod(data[key]);
    }
  }
};

// Response Interceptor to add helper methods
api.interceptors.response.use(
  (response) => {
    if (!response.data) {
      return response;
    }

    // Apply the localized helper method recursively
    addLocalizedMethod(response.data);

    // Add a method to get the integer-parsed value of the 'X-Total-Results' header
    response.data.getTotalResults = function () {
      const totalResults = response.headers[totalResultsHeader];
      return totalResults ? parseInt(totalResults, 10) : null;
    };

    return response;
  },
  (error) => {
    return Promise.reject(error);
  },
);

// ******************
// ASINs
// *

export const countASINs = async () => {
  try {
    const response = await backOff(() => api.get(`/asins/count`));
    return _.get(response, "data.count", 0);
  } catch (error) {
    throw new Error(`Could not Count ASINs: ${error}`);
  }
};

export const getASIN = async (asin) => {
  try {
    const response = await backOff(() => api.get(`/asins/${asin}`));
    return _.get(response, "data", null);
  } catch (error) {
    throw new Error(`Could not get ASIN ${asin}: ${error}`);
  }
};

export const getASINsMetrics = async () => {
  try {
    const response = await backOff(() => api.get(`/asins/metrics`));
    return _.get(response, "data", null);
  } catch (error) {
    throw new Error(`Could not get ASINs Metrics: ${error}`);
  }
};

// ******************
// Availabilities
// *

export const countAvailabilities = async () => {
  try {
    const response = await backOff(() => api.get(`/availabilities/count`));
    return _.get(response, "data.count", 0);
  } catch (error) {
    throw new Error(`Could not Count Availabilities: ${error}`);
  }
};

export const getAvailability = async (id) => {
  try {
    const response = await backOff(() => api.get(`/availabilities/${id}`));
    return _.get(response, "data", null);
  } catch (error) {
    throw new Error(`Could not get Availability ${id}: ${error}`);
  }
};

export const getAvailabilitiesMetrics = async () => {
  try {
    const response = await backOff(() => api.get(`/availabilities/metrics`));
    return _.get(response, "data", null);
  } catch (error) {
    throw new Error(`Could not get Availabilities Metrics: ${error}`);
  }
};

// ******************
// Brands
// *

export const countBrands = async () => {
  try {
    const response = await backOff(() => api.get(`/brands/count`));
    return _.get(response, "data.count", 0);
  } catch (error) {
    throw new Error(`Could not Count Brands: ${error}`);
  }
};

export const getBrand = async (id) => {
  try {
    const response = await backOff(() => api.get(`/brands/${id}`));
    return _.get(response, "data", null);
  } catch (error) {
    throw new Error(`Could not get Brand ${id}: ${error}`);
  }
};

export const getBrands = async ({ limit = defaultLimit, offset = 0 }) => {
  try {
    const response = await backOff(() =>
      api.get("/brands", {
        params: {
          limit,
          offset,
        },
      }),
    );
    return _.get(response, "data", null);
  } catch (error) {
    throw new Error(
      `Could not get Brands with Limit ${limit} and Offset ${offset}: ${error}`,
    );
  }
};

export const getAllBrands = async () => {
  const limit = maxLimit;
  let offset = 0;
  let totalResults = 0;
  let brands = [];
  do {
    const response = await getBrands({ limit, offset });
    brands = brands.concat(response);
    totalResults = response.getTotalResults();
    offset += limit;
  } while (offset < totalResults);
  return brands;
};

export const getBrandsMetrics = async () => {
  try {
    const response = await backOff(() => api.get(`/brands/metrics`));
    return _.get(response, "data", null);
  } catch (error) {
    throw new Error(`Could not get Brands Metrics: ${error}`);
  }
};

export const getBrandProducts = async (
  id,
  { limit = defaultLimit, offset = 0 },
) => {
  try {
    const response = await backOff(() =>
      api.get(`/brands/${id}/products`, {
        params: {
          limit,
          offset,
        },
      }),
    );
    return _.get(response, "data", null);
  } catch (error) {
    throw new Error(`Could not get Brand ${id} Products: ${error}`);
  }
};

export const getBrandProductsCount = async (id) => {
  try {
    const data = await getBrandProducts(id, { limit: 0, offset: 0 });
    return data.getTotalResults();
  } catch (error) {
    throw new Error(`Could not get Brand ${id} Products Count: ${error}`);
  }
};

export const searchBrands = async ({
  query,
  limit = defaultLimit,
  offset = 0,
}) => {
  try {
    const response = await backOff(() =>
      api.get("/brands/search", {
        params: {
          query,
          limit,
          offset,
        },
      }),
    );
    return _.get(response, "data", null);
  } catch (error) {
    throw new Error(`Could not search Brands with Query ${query}: ${error}`);
  }
};

export const getSimilarBrands = async (
  id,
  { limit = defaultLimit, offset = 0 },
) => {
  try {
    const response = await backOff(() =>
      api.get(`/brands/${id}/similar`, {
        params: {
          limit,
          offset,
        },
      }),
    );
    return _.get(response, "data", null);
  } catch (error) {
    throw new Error(`Could not get Similar Brands for Brand ${id}: ${error}`);
  }
};

// ******************
// Categories
// *

export const countCategories = async () => {
  try {
    const response = await backOff(() => api.get(`/categories/count`));
    return _.get(response, "data.count", 0);
  } catch (error) {
    throw new Error(`Could not Count Categories: ${error}`);
  }
};

export const getCategory = async (id) => {
  try {
    const response = await backOff(() => api.get(`/categories/${id}`));
    return _.get(response, "data", null);
  } catch (error) {
    throw new Error(`Could not get Category ${id}: ${error}`);
  }
};

export const getCategories = async ({ limit = defaultLimit, offset = 0 }) => {
  try {
    const response = await backOff(() =>
      api.get("/categories", {
        params: {
          limit,
          offset,
        },
      }),
    );
    return _.get(response, "data", null);
  } catch (error) {
    throw new Error(
      `Could not get Categories with Limit ${limit} and Offset ${offset}: ${error}`,
    );
  }
};

export const getAllCategories = async () => {
  // Find Grocery category in the first level of categories
  let rootCategoryId;
  const firstLevelCategories = await getCategories();
  if (firstLevelCategories && firstLevelCategories.length > 0) {
    // Get the identifier of the one category called 'Grocery'
    for (const category of firstLevelCategories) {
      if (category.localized("eng").Name === "Grocery") {
        rootCategoryId = category.id;
        break;
      }
    }
  }
  // Get the children of the root category
  const rootCategory = await getCategory(rootCategoryId);
  let categories = [];
  if (
    rootCategory &&
    rootCategory.Children &&
    rootCategory.Children.length > 0
  ) {
    categories = rootCategory.Children;
  }
  return categories;
};

export const getCategoriesMetrics = async () => {
  try {
    const response = await backOff(() => api.get(`/categories/metrics`));
    return _.get(response, "data", null);
  } catch (error) {
    throw new Error(`Could not get Categories Metrics: ${error}`);
  }
};

export const getCategoryProducts = async (
  id,
  { limit = defaultLimit, offset = 0 },
) => {
  try {
    const response = await backOff(() =>
      api.get(`/categories/${id}/products`, {
        params: {
          limit,
          offset,
        },
      }),
    );
    return response.data;
  } catch (error) {
    throw new Error(`Could not get Category ${id} Products: ${error}`);
  }
};

export const getCategoryProductsCount = async (id) => {
  try {
    const data = await getCategoryProducts(id, { limit: 0, offset: 0 });
    return data.getTotalResults();
  } catch (error) {
    throw new Error(`Could not get Category ${id} Products Count: ${error}`);
  }
};

export const searchCategories = async ({
  query,
  limit = defaultLimit,
  offset = 0,
}) => {
  try {
    const response = await backOff(() =>
      api.get("/categories/search", {
        params: {
          query,
          limit,
          offset,
        },
      }),
    );
    return response.data;
  } catch (error) {
    throw new Error(
      `Could not search Categories with Query ${query}: ${error}`,
    );
  }
};

export const getSimilarCategories = async (
  id,
  { limit = defaultLimit, offset = 0 },
) => {
  try {
    const response = await backOff(() =>
      api.get(`/categories/${id}/similar`, {
        params: {
          limit,
          offset,
        },
      }),
    );
    return response.data;
  } catch (error) {
    throw new Error(
      `Could not get Similar Categories for Category ${id}: ${error}`,
    );
  }
};

// ******************
// Chains
// *

export const countChains = async () => {
  try {
    const response = await backOff(() => api.get(`/chains/count`));
    return _.get(response, "data.count", 0);
  } catch (error) {
    throw new Error(`Could not Count Chains: ${error}`);
  }
};

export const getChain = async (id) => {
  try {
    const response = await backOff(() => api.get(`/chains/${id}`));
    return _.get(response, "data", null);
  } catch (error) {
    throw new Error(`Could not get Chain ${id}: ${error}`);
  }
};

export const getChains = async ({ limit = defaultLimit, offset = 0 }) => {
  try {
    const response = await backOff(() =>
      api.get("/chains", {
        params: {
          limit,
          offset,
        },
      }),
    );
    return _.get(response, "data", null);
  } catch (error) {
    throw new Error(
      `Could not get Chains with Limit ${limit} and Offset ${offset}: ${error}`,
    );
  }
};

export const getAllChains = async () => {
  const limit = maxLimit;
  let offset = 0;
  let totalResults = 0;
  let chains = [];
  do {
    const response = await getChains({ limit, offset });
    chains = chains.concat(response);
    totalResults = response.getTotalResults();
    offset += limit;
  } while (offset < totalResults);
  return chains;
};

export const getChainsMetrics = async () => {
  try {
    const response = await backOff(() => api.get(`/chains/metrics`));
    return _.get(response, "data", null);
  } catch (error) {
    throw new Error(`Could not get Chains Metrics: ${error}`);
  }
};

export const searchChains = async ({
  query,
  limit = defaultLimit,
  offset = 0,
}) => {
  try {
    const response = await backOff(() =>
      api.get("/chains/search", {
        params: {
          query,
          limit,
          offset,
        },
      }),
    );
    return _.get(response, "data", null);
  } catch (error) {
    throw new Error(`Could not search Chains with Query ${query}: ${error}`);
  }
};

export const getSimilarChains = async (
  id,
  { limit = defaultLimit, offset = 0 },
) => {
  try {
    const response = await backOff(() =>
      api.get(`/chains/${id}/similar`, {
        params: {
          limit,
          offset,
        },
      }),
    );
    return _.get(response, "data", null);
  } catch (error) {
    throw new Error(`Could not get Similar Chains for Chain ${id}: ${error}`);
  }
};

export const getChainStores = async (
  id,
  { limit = defaultLimit, offset = 0 },
) => {
  try {
    const response = await backOff(() =>
      api.get(`/chains/${id}/stores`, {
        params: {
          limit,
          offset,
        },
      }),
    );
    return _.get(response, "data", null);
  } catch (error) {
    throw new Error(`Could not get Chain ${id} Stores: ${error}`);
  }
};

export const getChainStoresCount = async (id) => {
  try {
    const data = await getChainStores(id, { limit: 0, offset: 0 });
    return data.getTotalResults();
  } catch (error) {
    throw new Error(`Could not get Chain ${id} Stores Count: ${error}`);
  }
};

export const getChainZones = async (
  id,
  { limit = defaultLimit, offset = 0 },
) => {
  try {
    const response = await backOff(() =>
      api.get(`/chains/${id}/zones`, {
        params: {
          limit,
          offset,
        },
      }),
    );
    return _.get(response, "data", null);
  } catch (error) {
    throw new Error(`Could not get Chain ${id} Zones: ${error}`);
  }
};

export const getChainZonesCount = async (id) => {
  try {
    const data = await getChainZones(id, { limit: 0, offset: 0 });
    return data.getTotalResults();
  } catch (error) {
    throw new Error(`Could not get Chain ${id} Zones Count: ${error}`);
  }
};

// ******************
// Groups
// *

export const getGroupChains = async (
  id,
  { limit = defaultLimit, offset = 0 },
) => {
  try {
    const response = await backOff(() =>
      api.get(`/groups/${id}/chains`, {
        params: {
          limit,
          offset,
        },
      }),
    );
    return _.get(response, "data", null);
  } catch (error) {
    throw new Error(`Could not get Group ${id} Chains: ${error}`);
  }
};

export const getGroupChainsCount = async (id) => {
  try {
    const data = await getGroupChains(id, { limit: 0, offset: 0 });
    return data.getTotalResults();
  } catch (error) {
    throw new Error(`Could not get Group ${id} Chains Count: ${error}`);
  }
};

export const countGroups = async () => {
  try {
    const response = await backOff(() => api.get(`/groups/count`));
    return _.get(response, "data.count", 0);
  } catch (error) {
    throw new Error(`Could not Count Groups: ${error}`);
  }
};

export const getGroup = async (id) => {
  try {
    const response = await backOff(() => api.get(`/groups/${id}`));
    return _.get(response, "data", null);
  } catch (error) {
    throw new Error(`Could not get Group ${id}: ${error}`);
  }
};

export const getGroups = async ({ limit = defaultLimit, offset = 0 }) => {
  try {
    const response = await backOff(() =>
      api.get("/groups", {
        params: {
          limit,
          offset,
        },
      }),
    );
    return _.get(response, "data", null);
  } catch (error) {
    throw new Error(
      `Could not get Groups with Limit ${limit} and Offset ${offset}: ${error}`,
    );
  }
};

export const getAllGroups = async () => {
  const limit = maxLimit;
  let offset = 0;
  let totalResults = 0;
  let groups = [];
  do {
    const response = await getGroups({ limit, offset });
    groups = groups.concat(response);
    totalResults = response.getTotalResults();
    offset += limit;
  } while (offset < totalResults);
  return groups;
};

export const getGroupsMetrics = async () => {
  try {
    const response = await backOff(() => api.get(`/groups/metrics`));
    return _.get(response, "data", null);
  } catch (error) {
    throw new Error(`Could not get Groups Metrics: ${error}`);
  }
};

// ******************
// GS1 Prefixes
// *

export const countGS1Prefixes = async () => {
  try {
    const response = await backOff(() => api.get(`/gs1/count`));
    return _.get(response, "data.count", 0);
  } catch (error) {
    throw new Error(`Could not Count GS1 Prefixes: ${error}`);
  }
};

export const getGS1Prefix = async (prefix) => {
  try {
    const response = await backOff(() => api.get(`/gs1/${prefix}`));
    return _.get(response, "data", null);
  } catch (error) {
    throw new Error(`Could not get GS1 Prefix ${prefix}: ${error}`);
  }
};

export const getGS1PrefixGTINs = async (
  prefix,
  { limit = defaultLimit, offset = 0 },
) => {
  try {
    const response = await backOff(() =>
      api.get(`/gs1/${prefix}/gtins`, {
        params: {
          limit,
          offset,
        },
      }),
    );
    return _.get(response, "data", null);
  } catch (error) {
    throw new Error(`Could not get GS1 Prefix ${prefix} GTINs: ${error}`);
  }
};

export const getGS1PrefixGTINsCount = async (prefix) => {
  try {
    const data = await getGS1PrefixGTINs(prefix, { limit: 0, offset: 0 });
    return data.getTotalResults();
  } catch (error) {
    throw new Error(`Could not get GS1 Prefix ${prefix} GTINs Count: ${error}`);
  }
};

export const getGS1Prefixes = async ({ limit = defaultLimit, offset = 0 }) => {
  try {
    const response = await backOff(() =>
      api.get("/gs1", {
        params: {
          limit,
          offset,
        },
      }),
    );
    return _.get(response, "data", null);
  } catch (error) {
    throw new Error(
      `Could not get GS1 Prefixes with Limit ${limit} and Offset ${offset}: ${error}`,
    );
  }
};

export const getAllGS1Prefixes = async () => {
  const limit = maxLimit;
  let offset = 0;
  let totalResults = 0;
  let gs1Prefixes = [];
  do {
    const response = await getGS1Prefixes({ limit, offset });
    gs1Prefixes = gs1Prefixes.concat(response);
    totalResults = response.getTotalResults();
    offset += limit;
  } while (offset < totalResults);
  return gs1Prefixes;
};

export const getGS1PrefixesMetrics = async () => {
  try {
    const response = await backOff(() => api.get(`/gs1/metrics`));
    return _.get(response, "data", null);
  } catch (error) {
    throw new Error(`Could not get GS1 Prefixes Metrics: ${error}`);
  }
};

// ******************
// GTINs
// *

export const countGTINs = async () => {
  try {
    const response = await backOff(() => api.get(`/gtins/count`));
    return _.get(response, "data.count", 0);
  } catch (error) {
    throw new Error(`Could not Count GTINs: ${error}`);
  }
};

export const getGTIN = async (gtin) => {
  try {
    const response = await backOff(() => api.get(`/gtins/${gtin}`));
    return _.get(response, "data", null);
  } catch (error) {
    throw new Error(`Could not get GTIN ${gtin}: ${error}`);
  }
};

export const getGTINsMetrics = async () => {
  try {
    const response = await backOff(() => api.get(`/gtins/metrics`));
    return _.get(response, "data", null);
  } catch (error) {
    throw new Error(`Could not get GTINs Metrics: ${error}`);
  }
};

export const scanGTIN = async (gtin) => {
  try {
    const response = await backOff(() => api.get(`/gtins/${gtin}/scan`));
    return _.get(response, "data", null);
  } catch (error) {
    throw new Error(`Could not scan GTIN ${gtin}: ${error}`);
  }
};

// ******************
// Health
// *

export const getHealthStatus = async () => {
  try {
    const response = await backOff(() => api.get("/health/status"));
    return _.get(response, "data", null);
  } catch (error) {
    throw new Error(`Could not get Health Status: ${error}`);
  }
};

export const getHealthDiagnostics = async () => {
  try {
    const response = await backOff(() => api.get("/health/diagnostics"));
    return _.get(response, "data", null);
  } catch (error) {
    throw new Error(`Could not get Health Diagnostics: ${error}`);
  }
};

// ******************
// Images
// *

export const getImageAnnotations = async (id) => {
  try {
    const response = await backOff(() => api.get(`/images/${id}/annotations`));
    return _.get(response, "data", null);
  } catch (error) {
    throw new Error(`Could not get Image ${id} Annotations: ${error}`);
  }
};

export const countImages = async () => {
  try {
    const response = await backOff(() => api.get(`/images/count`));
    return _.get(response, "data.count", 0);
  } catch (error) {
    throw new Error(`Could not Count Images: ${error}`);
  }
};

export const getImage = async (id) => {
  try {
    const response = await backOff(() => api.get(`/images/${id}`));
    return _.get(response, "data", null);
  } catch (error) {
    throw new Error(`Could not get Image ${id}: ${error}`);
  }
};

export const getImagesMetrics = async () => {
  try {
    const response = await backOff(() => api.get(`/images/metrics`));
    return _.get(response, "data", null);
  } catch (error) {
    throw new Error(`Could not get Images Metrics: ${error}`);
  }
};

// ******************
// PLUs
// *

export const countPLUs = async () => {
  try {
    const response = await backOff(() => api.get(`/plus/count`));
    return _.get(response, "data.count", 0);
  } catch (error) {
    throw new Error(`Could not Count PLUs: ${error}`);
  }
};

export const getPLU = async (plu) => {
  try {
    const response = await backOff(() => api.get(`/plus/${plu}`));
    return _.get(response, "data", null);
  } catch (error) {
    throw new Error(`Could not get PLU ${plu}: ${error}`);
  }
};

export const getPLUsList = async ({ limit = defaultLimit, offset = 0 }) => {
  try {
    const response = await backOff(() =>
      api.get("/plus", {
        params: {
          limit,
          offset,
        },
      }),
    );
    return _.get(response, "data", null);
  } catch (error) {
    throw new Error(
      `Could not get PLUs with Limit ${limit} and Offset ${offset}: ${error}`,
    );
  }
};

export const getPLUsMetrics = async () => {
  try {
    const response = await backOff(() => api.get(`/plus/metrics`));
    return _.get(response, "data", null);
  } catch (error) {
    throw new Error(`Could not get PLUs Metrics: ${error}`);
  }
};

export const getPLUProducts = async (
  plu,
  { limit = defaultLimit, offset = 0 },
) => {
  try {
    const response = await backOff(() =>
      api.get(`/plus/${plu}/products`, {
        params: {
          limit,
          offset,
        },
      }),
    );
    return _.get(response, "data", null);
  } catch (error) {
    throw new Error(`Could not get PLU ${plu} Products: ${error}`);
  }
};

export const getPLUProductsCount = async (plu) => {
  try {
    const data = await getPLUProducts(plu, { limit: 0, offset: 0 });
    return data.getTotalResults();
  } catch (error) {
    throw new Error(`Could not get PLU ${plu} Products Count: ${error}`);
  }
};

// ******************
// Prices
// *

export const addPricePromotion = async (id, promotion) => {
  try {
    const response = await backOff(() =>
      api.post(`/prices/${id}/promotions`, promotion),
    );
    return _.get(response, "data", null);
  } catch (error) {
    throw new Error(`Could not add Price ${id} Promotion: ${error}`);
  }
};

export const getPricePromotions = async (id) => {
  try {
    const response = await backOff(() => api.get(`/prices/${id}/promotions`));
    return _.get(response, "data", null);
  } catch (error) {
    throw new Error(`Could not get Price ${id} Promotions: ${error}`);
  }
};

export const getPricePromotionsCount = async (id) => {
  try {
    const data = await getPricePromotions(id);
    return data.length;
  } catch (error) {
    throw new Error(`Could not get Price ${id} Promotions Count: ${error}`);
  }
};

export const countPrices = async () => {
  try {
    const response = await backOff(() => api.get(`/prices/count`));
    return _.get(response, "data.count", 0);
  } catch (error) {
    throw new Error(`Could not Count Prices: ${error}`);
  }
};

export const getPrice = async (id) => {
  try {
    const response = await backOff(() => api.get(`/prices/${id}`));
    return _.get(response, "data", null);
  } catch (error) {
    throw new Error(`Could not get Price ${id}: ${error}`);
  }
};

export const getPriceHistory = async (
  id,
  { interval = "1 day", lookback = "3 months" },
) => {
  try {
    const response = await backOff(() =>
      api.get(`/prices/${id}/history`, {
        params: {
          interval,
          lookback,
        },
      }),
    );
    return _.get(response, "data", null);
  } catch (error) {
    throw new Error(`Could not get Price ${id} History: ${error}`);
  }
};

export const getPricesMetrics = async () => {
  try {
    const response = await backOff(() => api.get(`/prices/metrics`));
    return _.get(response, "data", null);
  } catch (error) {
    throw new Error(`Could not get Prices Metrics: ${error}`);
  }
};

// ******************
// Products
// *

// export const addProductImage = async (id, image) => {
//   try {
//     const response = await backOff(() =>
//       api.post(`/products/${id}/images`, image),
//     );
//     return _.get(response, "data", null);
//   } catch (error) {
//     throw new Error(`Could not add Product ${id} Image: ${error}`);
//   }
// };

export const getProductImages = async (id) => {
  try {
    const response = await backOff(() => api.get(`/products/${id}/images`));
    return _.get(response, "data", null);
  } catch (error) {
    throw new Error(`Could not get Product ${id} Images: ${error}`);
  }
};

export const getProductImagesCount = async (id) => {
  try {
    const data = await getProductImages(id);
    return data.getTotalResults();
  } catch (error) {
    throw new Error(`Could not get Product ${id} Images Count: ${error}`);
  }
};

export const addProductPrice = async (id, price) => {
  try {
    const response = await backOff(() =>
      api.post(`/products/${id}/prices`, price),
    );
    return _.get(response, "data", null);
  } catch (error) {
    throw new Error(`Could not add Product ${id} Price: ${error}`);
  }
};

export const getProductPrices = async (id) => {
  try {
    const response = await backOff(() => api.get(`/products/${id}/prices`));
    return _.get(response, "data", null);
  } catch (error) {
    throw new Error(`Could not get Product ${id} Prices: ${error}`);
  }
};

export const getProductPricesCount = async (id) => {
  try {
    const data = await getProductPrices(id);
    return data.getTotalResults();
  } catch (error) {
    throw new Error(`Could not get Product ${id} Prices Count: ${error}`);
  }
};

export const getProductPricesStats = async (id) => {
  try {
    const response = await backOff(() =>
      api.get(`/products/${id}/prices/stats`),
    );
    return _.get(response, "data", null);
  } catch (error) {
    throw new Error(`Could not get Product ${id} Prices Stats: ${error}`);
  }
};

export const getProductPromotions = async (id) => {
  try {
    const response = await backOff(() => api.get(`/products/${id}/promotions`));
    return _.get(response, "data", null);
  } catch (error) {
    throw new Error(`Could not get Product ${id} Promotions: ${error}`);
  }
};

export const getProductPromotionsCount = async (id) => {
  try {
    const data = await getProductPromotions(id);
    return data.getTotalResults();
  } catch (error) {
    throw new Error(`Could not get Product ${id} Promotions Count: ${error}`);
  }
};

export const addProduct = async (product) => {
  try {
    const response = await backOff(() => api.post("/products", product));
    return _.get(response, "data", null);
  } catch (error) {
    throw new Error(`Could not add Product: ${error}`);
  }
};

export const getProductAvailabilities = async (id) => {
  try {
    const response = await backOff(() =>
      api.get(`/products/${id}/availabilities`),
    );
    return _.get(response, "data", null);
  } catch (error) {
    throw new Error(`Could not get Product ${id} Availabilities: ${error}`);
  }
};

export const getProductAvailabilitiesCount = async (id) => {
  try {
    const data = await getProductAvailabilities(id);
    return data.getTotalResults();
  } catch (error) {
    throw new Error(
      `Could not get Product ${id} Availabilities Count: ${error}`,
    );
  }
};

export const getProductChains = async (id) => {
  try {
    const response = await backOff(() => api.get(`/products/${id}/chains`));
    return _.get(response, "data", null);
  } catch (error) {
    throw new Error(`Could not get Product ${id} Chains: ${error}`);
  }
};

export const getProductChainsCount = async (id) => {
  try {
    const data = await getProductChains(id);
    return data.getTotalResults();
  } catch (error) {
    throw new Error(`Could not get Product ${id} Chains Count: ${error}`);
  }
};

export const countProducts = async () => {
  try {
    const response = await backOff(() => api.get(`/products/count`));
    return _.get(response, "data.count", 0);
  } catch (error) {
    throw new Error(`Could not Count Products: ${error}`);
  }
};

export const getProduct = async (id) => {
  try {
    const response = await backOff(() => api.get(`/products/${id}`));
    return _.get(response, "data", null);
  } catch (error) {
    throw new Error(`Could not get Product ${id}: ${error}`);
  }
};

export const getProducts = async ({ limit = defaultLimit, offset = 0 }) => {
  try {
    const response = await backOff(() =>
      api.get("/products", {
        params: {
          limit,
          offset,
        },
      }),
    );
    return _.get(response, "data", null);
  } catch (error) {
    throw new Error(
      `Could not get Products with Limit ${limit} and Offset ${offset}: ${error}`,
    );
  }
};

export const getAllProducts = async () => {
  const limit = maxLimit;
  let offset = 0;
  let totalResults = 0;
  let products = [];
  do {
    const response = await getProducts({ limit, offset });
    products = products.concat(response);
    totalResults = response.getTotalResults();
    offset += limit;
  } while (offset < totalResults);
  return products;
};

export const lookupProduct = async ({ chain, type = "ID", reference }) => {
  try {
    const response = await backOff(() =>
      api.get(`/products/lookup`, {
        params: {
          chain,
          type,
          reference,
        },
      }),
    );
    return _.get(response, "data", null);
  } catch (error) {
    throw new Error(`Could not lookup Product: ${error}`);
  }
};

export const getProductsMetrics = async () => {
  try {
    const response = await backOff(() => api.get(`/products/metrics`));
    return _.get(response, "data", null);
  } catch (error) {
    throw new Error(`Could not get Products Metrics: ${error}`);
  }
};

export const getProductReferences = async (id) => {
  try {
    const response = await backOff(() => api.get(`/products/${id}/references`));
    return _.get(response, "data", null);
  } catch (error) {
    throw new Error(`Could not get Product ${id} References: ${error}`);
  }
};

export const getProductReferencesCount = async (id) => {
  try {
    const data = await getProductReferences(id);
    return data.getTotalResults();
  } catch (error) {
    throw new Error(`Could not get Product ${id} References Count: ${error}`);
  }
};

export const searchProducts = async ({
  query,
  limit = defaultLimit,
  offset = 0,
}) => {
  try {
    const response = await backOff(() =>
      api.get("/products/search", {
        params: {
          query,
          limit,
          offset,
        },
      }),
    );
    return _.get(response, "data", null);
  } catch (error) {
    throw new Error(`Could not search Products with Query ${query}: ${error}`);
  }
};

export const getSimilarProducts = async (
  id,
  { limit = defaultLimit, offset = 0 },
) => {
  try {
    const response = await backOff(() =>
      api.get(`/products/${id}/similar`, {
        params: {
          limit,
          offset,
        },
      }),
    );
    return _.get(response, "data", null);
  } catch (error) {
    throw new Error(
      `Could not get Similar Products for Product ${id}: ${error}`,
    );
  }
};

export const getProductSKUs = async (id) => {
  try {
    const response = await backOff(() => api.get(`/products/${id}/skus`));
    return _.get(response, "data", null);
  } catch (error) {
    throw new Error(`Could not get Product ${id} SKUs: ${error}`);
  }
};

export const getProductSKUsCount = async (id) => {
  try {
    const data = await getProductSKUs(id);
    return data.getTotalResults();
  } catch (error) {
    throw new Error(`Could not get Product ${id} SKUs Count: ${error}`);
  }
};

export const getProductURLs = async (id) => {
  try {
    const response = await backOff(() => api.get(`/products/${id}/urls`));
    return _.get(response, "data", null);
  } catch (error) {
    throw new Error(`Could not get Product ${id} URLs: ${error}`);
  }
};

export const getProductURLsCount = async (id) => {
  try {
    const data = await getProductURLs(id);
    return data.getTotalResults();
  } catch (error) {
    throw new Error(`Could not get Product ${id} URLs Count: ${error}`);
  }
};

export const getProductZones = async (id) => {
  try {
    const response = await backOff(() => api.get(`/products/${id}/zones`));
    return _.get(response, "data", null);
  } catch (error) {
    throw new Error(`Could not get Product ${id} Zones: ${error}`);
  }
};

export const getProductZonesCount = async (id) => {
  try {
    const data = await getProductZones(id);
    return data.getTotalResults();
  } catch (error) {
    throw new Error(`Could not get Product ${id} Zones Count: ${error}`);
  }
};

// ******************
// Promotions
// *

export const countPromotions = async () => {
  try {
    const response = await backOff(() => api.get(`/promotions/count`));
    return _.get(response, "data.count", 0);
  } catch (error) {
    throw new Error(`Could not Count Promotions: ${error}`);
  }
};

export const getPromotion = async (id) => {
  try {
    const response = await backOff(() => api.get(`/promotions/${id}`));
    return _.get(response, "data", null);
  } catch (error) {
    throw new Error(`Could not get Promotion ${id}: ${error}`);
  }
};

export const getPromotionsMetrics = async () => {
  try {
    const response = await backOff(() => api.get(`/promotions/metrics`));
    return _.get(response, "data", null);
  } catch (error) {
    throw new Error(`Could not get Promotions Metrics: ${error}`);
  }
};

export const getPromotionPrice = async (id) => {
  try {
    const response = await backOff(() => api.get(`/promotions/${id}/price`));
    return _.get(response, "data", null);
  } catch (error) {
    throw new Error(`Could not get Promotion ${id} Price: ${error}`);
  }
};

// ******************
// Search
// *

export const search = async ({ query, limit = defaultLimit, offset = 0 }) => {
  try {
    const response = await backOff(() =>
      api.get("/search", {
        params: {
          query,
          limit,
          offset,
        },
      }),
    );
    return _.get(response, "data", null);
  } catch (error) {
    throw new Error(`Could not search with Query ${query}: ${error}`);
  }
};

// ******************
// Search Queries
// *

export const countSearchQueries = async () => {
  try {
    const response = await backOff(() => api.get(`/queries/count`));
    return _.get(response, "data.count", 0);
  } catch (error) {
    throw new Error(`Could not Count Search Queries: ${error}`);
  }
};

export const getSearchQuery = async (id) => {
  try {
    const response = await backOff(() => api.get(`/queries/${id}`));
    return _.get(response, "data", null);
  } catch (error) {
    throw new Error(`Could not get Search Query ${id}: ${error}`);
  }
};

export const getSearchQueries = async ({
  limit = defaultLimit,
  offset = 0,
}) => {
  try {
    const response = await backOff(() =>
      api.get("/queries", {
        params: {
          limit,
          offset,
        },
      }),
    );
    return _.get(response, "data", null);
  } catch (error) {
    throw new Error(
      `Could not get Search Queries with Limit ${limit} and Offset ${offset}: ${error}`,
    );
  }
};

export const getAllSearchQueries = async () => {
  const limit = maxLimit;
  let offset = 0;
  let totalResults = 0;
  let searchQueries = [];
  do {
    const response = await getSearchQueries({ limit, offset });
    searchQueries = searchQueries.concat(response);
    totalResults = response.getTotalResults();
    offset += limit;
  } while (offset < totalResults);
  return searchQueries;
};

// ******************
// Stores
// *

export const countStores = async () => {
  try {
    const response = await backOff(() => api.get(`/stores/count`));
    return _.get(response, "data.count", 0);
  } catch (error) {
    throw new Error(`Could not Count Stores: ${error}`);
  }
};

export const getStore = async (id) => {
  try {
    const response = await backOff(() => api.get(`/stores/${id}`));
    return _.get(response, "data", null);
  } catch (error) {
    throw new Error(`Could not get Store ${id}: ${error}`);
  }
};

export const getStores = async ({
  latitude,
  limit = defaultLimit,
  longitude,
  offset = 0,
}) => {
  try {
    const params = {
      limit,
      offset,
    };
    if (latitude && longitude) {
      params.p = `${longitude},${latitude}`;
    }
    const response = await backOff(() =>
      api.get("/stores", {
        params,
      }),
    );
    return _.get(response, "data", null);
  } catch (error) {
    throw new Error(
      `Could not get Stores with Limit ${limit} and Offset ${offset}: ${error}`,
    );
  }
};

export const getAllStores = async () => {
  const limit = maxLimit;
  let offset = 0;
  let totalResults = 0;
  let stores = [];
  do {
    const response = await getStores({ limit, offset });
    stores = stores.concat(response);
    totalResults = response.getTotalResults();
    offset += limit;
  } while (offset < totalResults);
  return stores;
};

export const getStoresMetrics = async () => {
  try {
    const response = await backOff(() => api.get(`/stores/metrics`));
    return _.get(response, "data", null);
  } catch (error) {
    throw new Error(`Could not get Stores Metrics: ${error}`);
  }
};

export const searchStores = async ({
  query,
  limit = defaultLimit,
  offset = 0,
}) => {
  try {
    const response = await backOff(() =>
      api.get("/stores/search", {
        params: {
          query,
          limit,
          offset,
        },
      }),
    );
    return _.get(response, "data", null);
  } catch (error) {
    throw new Error(`Could not search Stores with Query ${query}: ${error}`);
  }
};

// ******************
// Users
// *

export const getMyDetails = async () => {
  try {
    const response = await backOff(() => api.get("/users/self"));
    return response.data;
  } catch (error) {
    throw new Error(`Could not get User Details: ${error}`);
  }
};

export const startBillingPortalSession = async ({ returnUrl }) => {
  try {
    const response = await backOff(() =>
      api.post("/users/self/portal", {
        ReturnURL: returnUrl,
      }),
    );
    return response.data;
  } catch (error) {
    throw new Error(`Could not get User Portal: ${error}`);
  }
};

export const subscribeToPlan = async ({ priceId }) => {
  try {
    const response = await backOff(() =>
      api.post("/users/self/subscribe", {
        CancelURL: `${window.location.origin}/subscribe/cancel`,
        Coupon: window?.Rewardful?.coupon?.id || null,
        PriceId: priceId,
        Referral: window?.Rewardful?.referral || null,
        SuccessURL: `${window.location.origin}/subscribe/success`,
      }),
    );
    return response.data;
  } catch (error) {
    throw new Error(`Could not subscribe to plan: ${error}`);
  }
};

export const getSubscriberData = async () => {
  try {
    const response = await backOff(() => api.get("/users/self/subscriber"));
    return response.data;
  } catch (error) {
    throw new Error(`Could not get User Subscriber Data: ${error}`);
  }
};

export const updateMyDetails = async (details) => {
  try {
    const response = await backOff(() => api.patch("/users/self", details));
    return response.status === 204;
  } catch (error) {
    throw new Error(`Could not update User Details: ${error}`);
  }
};

export const getMyZones = async () => {
  try {
    const response = await backOff(() => api.get("/users/self/zones"));
    return response.data;
  } catch (error) {
    throw new Error(`Could not get User Zones: ${error}`);
  }
};

export const getMyZonesCount = async () => {
  try {
    const data = await getMyZones();
    return data.getTotalResults();
  } catch (error) {
    throw new Error(`Could not get User Zones Count: ${error}`);
  }
};

export const addList = async (list) => {
  try {
    const response = await backOff(() => api.post("/users/self/lists", list));
    return response.data;
  } catch (error) {
    throw new Error(`Could not add User List: ${error}`);
  }
};

export const countLists = async () => {
  try {
    const response = await backOff(() => api.get("/users/self/lists/count"));
    return response.data;
  } catch (error) {
    throw new Error(`Could not Count User Lists: ${error}`);
  }
};

export const deleteList = async (id) => {
  try {
    const response = await backOff(() => api.delete(`/users/self/lists/${id}`));
    return response.status === 204;
  } catch (error) {
    throw new Error(`Could not delete User List: ${error}`);
  }
};

export const destroyList = async (id) => {
  try {
    const response = await backOff(() =>
      api.delete(`/users/self/lists/${id}/destroy`),
    );
    return response.status === 204;
  } catch (error) {
    throw new Error(`Could not destroy User List: ${error}`);
  }
};

export const getList = async (id) => {
  try {
    const response = await backOff(() => api.get(`/users/self/lists/${id}`));
    return response.data;
  } catch (error) {
    throw new Error(`Could not get User List: ${error}`);
  }
};

export const getLists = async ({ limit = defaultLimit, offset = 0 }) => {
  try {
    const response = await backOff(() =>
      api.get("/users/self/lists", {
        params: {
          limit,
          offset,
        },
      }),
    );
    return response.data;
  } catch (error) {
    throw new Error(`Could not get User Lists: ${error}`);
  }
};

export const getAllLists = async () => {
  const limit = maxLimit;
  let offset = 0;
  let totalResults = 0;
  let lists = [];
  do {
    const response = await getLists({ limit, offset });
    lists = lists.concat(response);
    totalResults = response.getTotalResults();
    offset += limit;
  } while (offset < totalResults);
  return lists;
};

export const getListShoppingItinerary = async (id) => {
  try {
    const response = await backOff(() =>
      api.get(`/users/self/lists/${id}/shopping`),
    );
    return response.data;
  } catch (error) {
    throw new Error(`Could not get User List Shopping Itinerary: ${error}`);
  }
};

export const updateList = async (id, list) => {
  try {
    const response = await backOff(() =>
      api.patch(`/users/self/lists/${id}`, list),
    );
    return response.data;
  } catch (error) {
    throw new Error(`Could not update User List: ${error}`);
  }
};

export const addProductToList = async (id, productId) => {
  try {
    const response = await backOff(() =>
      api.post(`/users/self/lists/${id}/products/${productId}`),
    );
    return response.data;
  } catch (error) {
    throw new Error(`Could not add Product to User List: ${error}`);
  }
};

export const countListProducts = async (id) => {
  try {
    const response = await backOff(() =>
      api.get(`/users/self/lists/${id}/products/count`),
    );
    return response.data;
  } catch (error) {
    throw new Error(`Could not Count User List Products: ${error}`);
  }
};

export const deleteProductFromList = async (id, productId) => {
  try {
    const response = await backOff(() =>
      api.delete(`/users/self/lists/${id}/products/${productId}`),
    );
    return response.status === 204;
  } catch (error) {
    throw new Error(`Could not delete Product from User List: ${error}`);
  }
};

export const getListProductsStats = async (id) => {
  try {
    const response = await backOff(() =>
      api.get(`/users/self/lists/${id}/products/stats`),
    );
    return response.data;
  } catch (error) {
    throw new Error(`Could not get User List Products Stats: ${error}`);
  }
};

export const getProductStatusForList = async (id, productId) => {
  try {
    const response = await backOff(() =>
      api.get(`/users/self/lists/${id}/products/${productId}`),
    );
    return response.data;
  } catch (error) {
    throw new Error(`Could not get Product Status for User List: ${error}`);
  }
};

export const getListProducts = async (
  id,
  { limit = defaultLimit, offset = 0 },
) => {
  try {
    const response = await backOff(() =>
      api.get(`/users/self/lists/${id}/products`, {
        params: {
          limit,
          offset,
        },
      }),
    );
    return response.data;
  } catch (error) {
    throw new Error(`Could not get User List Products: ${error}`);
  }
};

export const getMyMetadata = async () => {
  try {
    const response = await backOff(() => api.get("/users/self/metadata"));
    return response.data;
  } catch (error) {
    throw new Error(`Could not get User Metadata: ${error}`);
  }
};

export const updateMyMetadatum = async (key, value) => {
  try {
    const response = await backOff(() =>
      api.patch(`/users/self/metadata`, {
        Key: key,
        Value: value,
      }),
    );
    return response.data;
  } catch (error) {
    throw new Error(`Could not update User Metadatum: ${error}`);
  }
};

export const getNotifications = async ({
  limit = defaultLimit,
  offset = 0,
  read = false,
}) => {
  try {
    const response = await backOff(() =>
      api.get("/users/self/notifications", {
        params: {
          limit,
          offset,
          read: read ? "true" : "false",
        },
      }),
    );
    return response.data;
  } catch (error) {
    throw new Error(`Could not get User Notifications: ${error}`);
  }
};

export const getAllNotifications = async ({ read = false }) => {
  const limit = maxLimit;
  let offset = 0;
  let totalResults = 0;
  let notifications = [];
  do {
    const response = await getNotifications({ limit, offset, read });
    notifications = notifications.concat(response);
    totalResults = response.getTotalResults();
    offset += limit;
  } while (offset < totalResults);
  return notifications;
};

export const markNotificationAsRead = async (id) => {
  try {
    const response = await backOff(() =>
      api.patch(`/users/self/notifications/${id}`, {
        Read: true,
      }),
    );
    return response.status === 202;
  } catch (error) {
    throw new Error(`Could not mark Notification ${id} as Read: ${error}`);
  }
};

// ******************
// Zones
// *

export const countZones = async () => {
  try {
    const response = await backOff(() => api.get(`/zones/count`));
    return _.get(response, "data.count", 0);
  } catch (error) {
    throw new Error(`Could not Count Zones: ${error}`);
  }
};

export const getZone = async (id) => {
  try {
    const response = await backOff(() => api.get(`/zones/${id}`));
    return _.get(response, "data", null);
  } catch (error) {
    throw new Error(`Could not get Zone ${id}: ${error}`);
  }
};

export const getZones = async ({ limit = defaultLimit, offset = 0 }) => {
  try {
    const response = await backOff(() =>
      api.get("/zones", {
        params: {
          limit,
          offset,
        },
      }),
    );
    return _.get(response, "data", null);
  } catch (error) {
    throw new Error(
      `Could not get Zones with Limit ${limit} and Offset ${offset}: ${error}`,
    );
  }
};

export const getAllZones = async () => {
  const limit = maxLimit;
  let offset = 0;
  let totalResults = 0;
  let zones = [];
  do {
    const response = await getZones({ limit, offset });
    zones = zones.concat(response);
    totalResults = response.getTotalResults();
    offset += limit;
  } while (offset < totalResults);
  return zones;
};

export const getZonesMetrics = async () => {
  try {
    const response = await backOff(() => api.get(`/zones/metrics`));
    return _.get(response, "data", null);
  } catch (error) {
    throw new Error(`Could not get Zones Metrics: ${error}`);
  }
};

export const getZoneStores = async (id) => {
  try {
    const response = await backOff(() => api.get(`/zones/${id}/stores`));
    return _.get(response, "data", null);
  } catch (error) {
    throw new Error(`Could not get Zone ${id} Stores: ${error}`);
  }
};

export const getZoneStoresCount = async (id) => {
  try {
    const data = await getZoneStores(id);
    return data.getTotalResults();
  } catch (error) {
    throw new Error(`Could not get Zone ${id} Stores Count: ${error}`);
  }
};

// ----------------------------------------

export const getFavouriteProducts = async (
  limit = defaultLimit,
  offset = 0,
) => {
  try {
    const response = await backOff(() =>
      api.get("/users/self/products", {
        params: {
          limit,
          offset,
        },
      }),
    );
    return response.data;
  } catch (error) {
    throw new Error(`Could not get User Favourite Products: ${error}`);
  }
};

export const getAllFavouriteProducts = async () => {
  const limit = maxLimit;
  let offset = 0;
  let totalResults = 0;
  let favouriteProducts = [];
  do {
    const response = await getFavouriteProducts(limit, offset);
    favouriteProducts = favouriteProducts.concat(response);
    totalResults = response.getTotalResults();
    offset += limit;
  } while (offset < totalResults);
  favouriteProducts = favouriteProducts.filter(
    (product, index, self) =>
      index === self.findIndex((p) => p.id === product.id),
  );
  return favouriteProducts;
};

export const getFavouriteStores = async (limit = defaultLimit, offset = 0) => {
  try {
    const response = await backOff(() =>
      api.get("/users/self/stores", {
        params: {
          limit,
          offset,
        },
      }),
    );
    return response.data;
  } catch (error) {
    throw new Error(`Could not get User Favourite Stores: ${error}`);
  }
};

export const getAllFavouriteStores = async () => {
  const limit = maxLimit;
  let offset = 0;
  let totalResults = 0;
  let favouriteStores = [];
  do {
    const response = await getFavouriteStores(limit, offset);
    favouriteStores = favouriteStores.concat(response);
    totalResults = response.getTotalResults();
    offset += limit;
  } while (offset < totalResults);
  favouriteStores = favouriteStores.filter(
    (store, index, self) => index === self.findIndex((s) => s.id === store.id),
  );
  return favouriteStores;
};

export const reverseGeocode = async (latitude, longitude) => {
  try {
    const response = await backOff(() =>
      api.get("/utilities/geocoding/reverse", {
        params: {
          p: `${longitude},${latitude}`,
        },
      }),
    );
    return response.data;
  } catch (error) {
    throw new Error(`Error fetching reverse geocode: ${error}`);
  }
};

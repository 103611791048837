import { defineStore } from "pinia";

export const useGeolocationStore = defineStore("geolocation", {
  state: () => ({
    address: null,
    allowed: null,
    approximatePosition: null,
    coords: null,
    error: null,
    refresh: null,
    supported: null,
  }),
});

import { createRouter, createWebHistory } from "vue-router";
import { authGuard } from "@auth0/auth0-vue";

import Dashboard from "./pages/Dashboard.vue";
import AuthCallback from "./pages/AuthCallback.vue";
import FavoriteProducts from "./pages/lists/FavoriteProducts.vue";
import ProductBrowse from "./pages/products/Browse.vue";
import ProductDetails from "./pages/products/Details.vue";
import ShowLists from "./pages/lists/ShowLists.vue";

import Analytics from "./pages/Analytics.vue";
import Fintech from "./pages/Fintech.vue";
import Customers from "./pages/ecommerce/Customers.vue";
import Orders from "./pages/ecommerce/Orders.vue";
import Invoices from "./pages/ecommerce/Invoices.vue";
import Shop from "./pages/ecommerce/Shop.vue";
import Shop2 from "./pages/ecommerce/Shop2.vue";
import Product from "./pages/ecommerce/Product.vue";
import Cart from "./pages/ecommerce/Cart.vue";
import Cart2 from "./pages/ecommerce/Cart2.vue";
import Cart3 from "./pages/ecommerce/Cart3.vue";
import Pay from "./pages/ecommerce/Pay.vue";
import Campaigns from "./pages/Campaigns.vue";
import UsersTabs from "./pages/community/UsersTabs.vue";
import UsersTiles from "./pages/community/UsersTiles.vue";
import Profile from "./pages/community/Profile.vue";
import Feed from "./pages/community/Feed.vue";
import Forum from "./pages/community/Forum.vue";
import ForumPost from "./pages/community/ForumPost.vue";
import Meetups from "./pages/community/Meetups.vue";
import MeetupsPost from "./pages/community/MeetupsPost.vue";
import CreditCards from "./pages/finance/CreditCards.vue";
import Transactions from "./pages/finance/Transactions.vue";
import TransactionDetails from "./pages/finance/TransactionDetails.vue";
import JobListing from "./pages/job/JobListing.vue";
import JobPost from "./pages/job/JobPost.vue";
import CompanyProfile from "./pages/job/CompanyProfile.vue";
import Messages from "./pages/Messages.vue";
import TasksKanban from "./pages/tasks/TasksKanban.vue";
import TasksList from "./pages/tasks/TasksList.vue";
import Inbox from "./pages/Inbox.vue";
import Calendar from "./pages/Calendar.vue";
import Account from "./pages/settings/Account.vue";
import Notifications from "./pages/settings/Notifications.vue";
import Apps from "./pages/settings/Apps.vue";
import Plans from "./pages/settings/Plans.vue";
import Billing from "./pages/settings/Billing.vue";
import Feedback from "./pages/settings/Feedback.vue";
import StoresBrowse from "./pages/stores/Browse.vue";
import Changelog from "./pages/utility/Changelog.vue";
import Roadmap from "./pages/utility/Roadmap.vue";
import Faqs from "./pages/utility/Faqs.vue";
import EmptyState from "./pages/utility/EmptyState.vue";
import PageNotFound from "./pages/utility/PageNotFound.vue";
import KnowledgeBase from "./pages/utility/KnowledgeBase.vue";
import Signin from "./pages/Signin.vue";
import Signup from "./pages/Signup.vue";
import ResetPassword from "./pages/ResetPassword.vue";
import Onboarding01 from "./pages/Onboarding01.vue";
import Onboarding02 from "./pages/Onboarding02.vue";
import Onboarding03 from "./pages/Onboarding03.vue";
import Onboarding04 from "./pages/Onboarding04.vue";
import ButtonPage from "./pages/component/ButtonPage.vue";
import FormPage from "./pages/component/FormPage.vue";
import DropdownPage from "./pages/component/DropdownPage.vue";
import AlertPage from "./pages/component/AlertPage.vue";
import ModalPage from "./pages/component/ModalPage.vue";
import PaginationPage from "./pages/component/PaginationPage.vue";
import TabsPage from "./pages/component/TabsPage.vue";
import BreadcrumbPage from "./pages/component/BreadcrumbPage.vue";
import BadgePage from "./pages/component/BadgePage.vue";
import AvatarPage from "./pages/component/AvatarPage.vue";
import TooltipPage from "./pages/component/TooltipPage.vue";
import AccordionPage from "./pages/component/AccordionPage.vue";
import IconsPage from "./pages/component/IconsPage.vue";

const routerHistory = createWebHistory();

const router = createRouter({
  history: routerHistory,
  routes: [
    {
      path: "/",
      component: Dashboard,
      beforeEnter: authGuard,
    },
    {
      path: "/auth/callback",
      component: AuthCallback,
    },
    {
      path: "/products",
      component: ProductBrowse,
      beforeEnter: authGuard,
    },
    {
      path: "/products/:id",
      component: ProductDetails,
      beforeEnter: authGuard,
    },
    {
      path: "/lists/favorites",
      component: FavoriteProducts,
      beforeEnter: authGuard,
    },
    {
      path: "/lists/show",
      component: ShowLists,
      beforeEnter: authGuard,
    },
    // ** These routes are for demo purposes only. **
    {
      path: "/dashboard/analytics",
      component: Analytics,
      beforeEnter: authGuard,
    },
    {
      path: "/dashboard/fintech",
      component: Fintech,
      beforeEnter: authGuard,
    },
    {
      path: "/ecommerce/customers",
      component: Customers,
      beforeEnter: authGuard,
    },
    {
      path: "/ecommerce/orders",
      component: Orders,
      beforeEnter: authGuard,
    },
    {
      path: "/ecommerce/invoices",
      component: Invoices,
      beforeEnter: authGuard,
    },
    {
      path: "/ecommerce/shop",
      component: Shop,
      beforeEnter: authGuard,
    },
    {
      path: "/ecommerce/shop-2",
      component: Shop2,
      beforeEnter: authGuard,
    },
    {
      path: "/ecommerce/product",
      component: Product,
      beforeEnter: authGuard,
    },
    {
      path: "/ecommerce/cart",
      component: Cart,
      beforeEnter: authGuard,
    },
    {
      path: "/ecommerce/cart-2",
      component: Cart2,
      beforeEnter: authGuard,
    },
    {
      path: "/ecommerce/cart-3",
      component: Cart3,
      beforeEnter: authGuard,
    },
    {
      path: "/ecommerce/pay",
      component: Pay,
      beforeEnter: authGuard,
    },
    {
      path: "/campaigns",
      component: Campaigns,
      beforeEnter: authGuard,
    },
    {
      path: "/community/users-tabs",
      component: UsersTabs,
      beforeEnter: authGuard,
    },
    {
      path: "/community/users-tiles",
      component: UsersTiles,
      beforeEnter: authGuard,
    },
    {
      path: "/community/profile",
      component: Profile,
      beforeEnter: authGuard,
    },
    {
      path: "/community/feed",
      component: Feed,
      beforeEnter: authGuard,
    },
    {
      path: "/community/forum",
      component: Forum,
      beforeEnter: authGuard,
    },
    {
      path: "/community/forum-post",
      component: ForumPost,
      beforeEnter: authGuard,
    },
    {
      path: "/community/meetups",
      component: Meetups,
      beforeEnter: authGuard,
    },
    {
      path: "/community/meetups-post",
      component: MeetupsPost,
      beforeEnter: authGuard,
    },
    {
      path: "/finance/cards",
      component: CreditCards,
      beforeEnter: authGuard,
    },
    {
      path: "/finance/transactions",
      component: Transactions,
      beforeEnter: authGuard,
    },
    {
      path: "/finance/transaction-details",
      component: TransactionDetails,
      beforeEnter: authGuard,
    },
    {
      path: "/job/job-listing",
      component: JobListing,
      beforeEnter: authGuard,
    },
    {
      path: "/job/job-post",
      component: JobPost,
      beforeEnter: authGuard,
    },
    {
      path: "/job/company-profile",
      component: CompanyProfile,
      beforeEnter: authGuard,
    },
    {
      path: "/messages",
      component: Messages,
      beforeEnter: authGuard,
    },
    {
      path: "/tasks/kanban",
      component: TasksKanban,
      beforeEnter: authGuard,
    },
    {
      path: "/tasks/list",
      component: TasksList,
      beforeEnter: authGuard,
    },
    {
      path: "/inbox",
      component: Inbox,
      beforeEnter: authGuard,
    },
    {
      path: "/calendar",
      component: Calendar,
      beforeEnter: authGuard,
    },
    {
      path: "/settings/account",
      component: Account,
      beforeEnter: authGuard,
    },
    {
      path: "/settings/notifications",
      component: Notifications,
      beforeEnter: authGuard,
    },
    {
      path: "/settings/apps",
      component: Apps,
      beforeEnter: authGuard,
    },
    {
      path: "/settings/plans",
      component: Plans,
      beforeEnter: authGuard,
    },
    {
      path: "/settings/billing",
      component: Billing,
      beforeEnter: authGuard,
    },
    {
      path: "/settings/feedback",
      component: Feedback,
      beforeEnter: authGuard,
    },
    {
      path: "/stores",
      component: StoresBrowse,
      beforeEnter: authGuard,
    },
    {
      path: "/utility/changelog",
      component: Changelog,
      beforeEnter: authGuard,
    },
    {
      path: "/utility/roadmap",
      component: Roadmap,
      beforeEnter: authGuard,
    },
    {
      path: "/utility/faqs",
      component: Faqs,
      beforeEnter: authGuard,
    },
    {
      path: "/utility/empty-state",
      component: EmptyState,
      beforeEnter: authGuard,
    },
    {
      path: "/utility/404",
      component: PageNotFound,
    },
    {
      path: "/utility/knowledge-base",
      component: KnowledgeBase,
      beforeEnter: authGuard,
    },
    {
      path: "/signin",
      component: Signin,
    },
    {
      path: "/signup",
      component: Signup,
    },
    {
      path: "/reset-password",
      component: ResetPassword,
    },
    {
      path: "/onboarding-01",
      component: Onboarding01,
      beforeEnter: authGuard,
    },
    {
      path: "/onboarding-02",
      component: Onboarding02,
      beforeEnter: authGuard,
    },
    {
      path: "/onboarding-03",
      component: Onboarding03,
      beforeEnter: authGuard,
    },
    {
      path: "/onboarding-04",
      component: Onboarding04,
      beforeEnter: authGuard,
    },
    {
      path: "/component/button",
      component: ButtonPage,
      beforeEnter: authGuard,
    },
    {
      path: "/component/form",
      component: FormPage,
      beforeEnter: authGuard,
    },
    {
      path: "/component/dropdown",
      component: DropdownPage,
      beforeEnter: authGuard,
    },
    {
      path: "/component/alert",
      component: AlertPage,
      beforeEnter: authGuard,
    },
    {
      path: "/component/modal",
      component: ModalPage,
      beforeEnter: authGuard,
    },
    {
      path: "/component/pagination",
      component: PaginationPage,
      beforeEnter: authGuard,
    },
    {
      path: "/component/tabs",
      component: TabsPage,
      beforeEnter: authGuard,
    },
    {
      path: "/component/breadcrumb",
      component: BreadcrumbPage,
      beforeEnter: authGuard,
    },
    {
      path: "/component/badge",
      component: BadgePage,
      beforeEnter: authGuard,
    },
    {
      path: "/component/avatar",
      component: AvatarPage,
      beforeEnter: authGuard,
    },
    {
      path: "/component/tooltip",
      component: TooltipPage,
      beforeEnter: authGuard,
    },
    {
      path: "/component/accordion",
      component: AccordionPage,
      beforeEnter: authGuard,
    },
    {
      path: "/component/icons",
      component: IconsPage,
      beforeEnter: authGuard,
    },
    {
      path: "/:pathMatch(.*)*",
      component: PageNotFound,
    },
  ],
});

export default router;

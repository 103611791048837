<template>
  <!-- More group -->
  <div>
    <h3 class="text-xs uppercase text-slate-500 font-semibold pl-3">
      <span
        class="hidden lg:block lg:sidebar-expanded:hidden 2xl:hidden text-center w-6"
        aria-hidden="true"
        >•••</span
      >
      <span class="lg:hidden lg:sidebar-expanded:block 2xl:block">More</span>
    </h3>
    <ul class="mt-3">
      <!-- GPC -->
      <SidebarLinkGroup v-slot="parentLink">
        <a
          class="block text-slate-200 truncate transition duration-150"
          :class="
            parentLink.expanded ? 'hover:text-slate-200' : 'hover:text-white'
          "
          href="#0"
          @click.prevent="
            parentLink.handleClick();
            sidebarExpanded = true;
          "
        >
          <div class="flex items-center justify-between">
            <div class="flex items-center">
              <font-awesome-icon
                icon="fa-duotone fa-book-atlas"
                class="w-6 h-6"
              />
              <span
                class="text-sm font-medium ml-3 lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200"
                >GPC</span
              >
            </div>
            <!-- Icon -->
            <div class="flex shrink-0 ml-2">
              <font-awesome-icon
                :icon="[
                  'fas',
                  parentLink.expanded ? 'chevron-up' : 'chevron-down',
                ]"
                class="w-3 h-3"
              />
            </div>
          </div>
        </a>
        <div class="lg:hidden lg:sidebar-expanded:block 2xl:block">
          <ul class="pl-9 mt-1" :class="!parentLink.expanded && 'hidden'">
            <router-link to="/signin" custom v-slot="{ href, navigate }">
              <li class="mb-1 last:mb-0">
                <a
                  class="block text-slate-400 hover:text-slate-200 transition duration-150 truncate"
                  :href="href"
                  @click="navigate"
                >
                  <span
                    class="text-sm font-medium lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200"
                    >Sign in</span
                  >
                </a>
              </li>
            </router-link>
            <router-link to="/signup" custom v-slot="{ href, navigate }">
              <li class="mb-1 last:mb-0">
                <a
                  class="block text-slate-400 hover:text-slate-200 transition duration-150 truncate"
                  :href="href"
                  @click="navigate"
                >
                  <span
                    class="text-sm font-medium lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200"
                    >Sign up</span
                  >
                </a>
              </li>
            </router-link>
            <router-link
              to="/reset-password"
              custom
              v-slot="{ href, navigate }"
            >
              <li class="mb-1 last:mb-0">
                <a
                  class="block text-slate-400 hover:text-slate-200 transition duration-150 truncate"
                  :href="href"
                  @click="navigate"
                >
                  <span
                    class="text-sm font-medium lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200"
                    >Reset Password</span
                  >
                </a>
              </li>
            </router-link>
          </ul>
        </div>
      </SidebarLinkGroup>
      <!-- GTINs -->
      <SidebarLinkGroup v-slot="parentLink">
        <a
          class="block text-slate-200 truncate transition duration-150"
          :class="
            parentLink.expanded ? 'hover:text-slate-200' : 'hover:text-white'
          "
          href="#0"
          @click.prevent="
            parentLink.handleClick();
            sidebarExpanded = true;
          "
        >
          <div class="flex items-center justify-between">
            <div class="flex items-center">
              <font-awesome-icon
                icon="fa-duotone fa-scanner-gun"
                class="w-6 h-6"
              />
              <span
                class="text-sm font-medium ml-3 lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200"
                >GTINs</span
              >
            </div>
            <!-- Icon -->
            <div class="flex shrink-0 ml-2">
              <font-awesome-icon
                :icon="[
                  'fas',
                  parentLink.expanded ? 'chevron-up' : 'chevron-down',
                ]"
                class="w-3 h-3"
              />
            </div>
          </div>
        </a>
        <div class="lg:hidden lg:sidebar-expanded:block 2xl:block">
          <ul class="pl-9 mt-1" :class="!parentLink.expanded && 'hidden'">
            <router-link to="/onboarding-01" custom v-slot="{ href, navigate }">
              <li class="mb-1 last:mb-0">
                <a
                  class="block text-slate-400 hover:text-slate-200 transition duration-150 truncate"
                  :href="href"
                  @click="navigate"
                >
                  <span
                    class="text-sm font-medium lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200"
                    >Step 1</span
                  >
                </a>
              </li>
            </router-link>
            <router-link to="/onboarding-02" custom v-slot="{ href, navigate }">
              <li class="mb-1 last:mb-0">
                <a
                  class="block text-slate-400 hover:text-slate-200 transition duration-150 truncate"
                  :href="href"
                  @click="navigate"
                >
                  <span
                    class="text-sm font-medium lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200"
                    >Step 2</span
                  >
                </a>
              </li>
            </router-link>
            <router-link to="/onboarding-03" custom v-slot="{ href, navigate }">
              <li class="mb-1 last:mb-0">
                <a
                  class="block text-slate-400 hover:text-slate-200 transition duration-150 truncate"
                  :href="href"
                  @click="navigate"
                >
                  <span
                    class="text-sm font-medium lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200"
                    >Step 3</span
                  >
                </a>
              </li>
            </router-link>
            <router-link to="/onboarding-04" custom v-slot="{ href, navigate }">
              <li class="mb-1 last:mb-0">
                <a
                  class="block text-slate-400 hover:text-slate-200 transition duration-150 truncate"
                  :href="href"
                  @click="navigate"
                >
                  <span
                    class="text-sm font-medium lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200"
                    >Step 4</span
                  >
                </a>
              </li>
            </router-link>
          </ul>
        </div>
      </SidebarLinkGroup>
      <!-- Nutrients  -->
      <SidebarLinkGroup
        v-slot="parentLink"
        :activeCondition="currentRoute.fullPath.includes('component')"
      >
        <a
          class="block text-slate-200 truncate transition duration-150"
          :class="
            currentRoute.fullPath.includes('component')
              ? 'hover:text-slate-200'
              : 'hover:text-white'
          "
          href="#0"
          @click.prevent="
            parentLink.handleClick();
            sidebarExpanded = true;
          "
        >
          <div class="flex items-center justify-between">
            <div class="flex items-center">
              <font-awesome-icon
                icon="fa-duotone fa-magnifying-glass-chart"
                class="w-6 h-6"
              />
              <span
                class="text-sm font-medium ml-3 lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200"
                >Nutrients
              </span>
            </div>
            <!-- Icon -->
            <div class="flex shrink-0 ml-2">
              <font-awesome-icon
                :icon="[
                  'fas',
                  parentLink.expanded ? 'chevron-up' : 'chevron-down',
                ]"
                class="w-3 h-3"
              />
            </div>
          </div>
        </a>
        <div class="lg:hidden lg:sidebar-expanded:block 2xl:block">
          <ul class="pl-9 mt-1" :class="!parentLink.expanded && 'hidden'">
            <router-link
              to="/component/button"
              custom
              v-slot="{ href, navigate, isExactActive }"
            >
              <li class="mb-1 last:mb-0">
                <a
                  class="block transition duration-150 truncate"
                  :class="
                    isExactActive
                      ? 'text-indigo-500'
                      : 'text-slate-400 hover:text-slate-200'
                  "
                  :href="href"
                  @click="navigate"
                >
                  <span
                    class="text-sm font-medium lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200"
                    >Button</span
                  >
                </a>
              </li>
            </router-link>
            <router-link
              to="/component/form"
              custom
              v-slot="{ href, navigate, isExactActive }"
            >
              <li class="mb-1 last:mb-0">
                <a
                  class="block transition duration-150 truncate"
                  :class="
                    isExactActive
                      ? 'text-indigo-500'
                      : 'text-slate-400 hover:text-slate-200'
                  "
                  :href="href"
                  @click="navigate"
                >
                  <span
                    class="text-sm font-medium lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200"
                    >Input Form</span
                  >
                </a>
              </li>
            </router-link>
            <router-link
              to="/component/dropdown"
              custom
              v-slot="{ href, navigate, isExactActive }"
            >
              <li class="mb-1 last:mb-0">
                <a
                  class="block transition duration-150 truncate"
                  :class="
                    isExactActive
                      ? 'text-indigo-500'
                      : 'text-slate-400 hover:text-slate-200'
                  "
                  :href="href"
                  @click="navigate"
                >
                  <span
                    class="text-sm font-medium lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200"
                    >Dropdown</span
                  >
                </a>
              </li>
            </router-link>
            <router-link
              to="/component/alert"
              custom
              v-slot="{ href, navigate, isExactActive }"
            >
              <li class="mb-1 last:mb-0">
                <a
                  class="block transition duration-150 truncate"
                  :class="
                    isExactActive
                      ? 'text-indigo-500'
                      : 'text-slate-400 hover:text-slate-200'
                  "
                  :href="href"
                  @click="navigate"
                >
                  <span
                    class="text-sm font-medium lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200"
                    >Alert & Banner</span
                  >
                </a>
              </li>
            </router-link>
            <router-link
              to="/component/modal"
              custom
              v-slot="{ href, navigate, isExactActive }"
            >
              <li class="mb-1 last:mb-0">
                <a
                  class="block transition duration-150 truncate"
                  :class="
                    isExactActive
                      ? 'text-indigo-500'
                      : 'text-slate-400 hover:text-slate-200'
                  "
                  :href="href"
                  @click="navigate"
                >
                  <span
                    class="text-sm font-medium lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200"
                    >Modal</span
                  >
                </a>
              </li>
            </router-link>
            <router-link
              to="/component/pagination"
              custom
              v-slot="{ href, navigate, isExactActive }"
            >
              <li class="mb-1 last:mb-0">
                <a
                  class="block transition duration-150 truncate"
                  :class="
                    isExactActive
                      ? 'text-indigo-500'
                      : 'text-slate-400 hover:text-slate-200'
                  "
                  :href="href"
                  @click="navigate"
                >
                  <span
                    class="text-sm font-medium lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200"
                    >Pagination</span
                  >
                </a>
              </li>
            </router-link>
            <router-link
              to="/component/tabs"
              custom
              v-slot="{ href, navigate, isExactActive }"
            >
              <li class="mb-1 last:mb-0">
                <a
                  class="block transition duration-150 truncate"
                  :class="
                    isExactActive
                      ? 'text-indigo-500'
                      : 'text-slate-400 hover:text-slate-200'
                  "
                  :href="href"
                  @click="navigate"
                >
                  <span
                    class="text-sm font-medium lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200"
                    >Tabs</span
                  >
                </a>
              </li>
            </router-link>
            <router-link
              to="/component/breadcrumb"
              custom
              v-slot="{ href, navigate, isExactActive }"
            >
              <li class="mb-1 last:mb-0">
                <a
                  class="block transition duration-150 truncate"
                  :class="
                    isExactActive
                      ? 'text-indigo-500'
                      : 'text-slate-400 hover:text-slate-200'
                  "
                  :href="href"
                  @click="navigate"
                >
                  <span
                    class="text-sm font-medium lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200"
                    >Breadcrumb</span
                  >
                </a>
              </li>
            </router-link>
            <router-link
              to="/component/badge"
              custom
              v-slot="{ href, navigate, isExactActive }"
            >
              <li class="mb-1 last:mb-0">
                <a
                  class="block transition duration-150 truncate"
                  :class="
                    isExactActive
                      ? 'text-indigo-500'
                      : 'text-slate-400 hover:text-slate-200'
                  "
                  :href="href"
                  @click="navigate"
                >
                  <span
                    class="text-sm font-medium lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200"
                    >Badge</span
                  >
                </a>
              </li>
            </router-link>
            <router-link
              to="/component/avatar"
              custom
              v-slot="{ href, navigate, isExactActive }"
            >
              <li class="mb-1 last:mb-0">
                <a
                  class="block transition duration-150 truncate"
                  :class="
                    isExactActive
                      ? 'text-indigo-500'
                      : 'text-slate-400 hover:text-slate-200'
                  "
                  :href="href"
                  @click="navigate"
                >
                  <span
                    class="text-sm font-medium lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200"
                    >Avatar</span
                  >
                </a>
              </li>
            </router-link>
            <router-link
              to="/component/tooltip"
              custom
              v-slot="{ href, navigate, isExactActive }"
            >
              <li class="mb-1 last:mb-0">
                <a
                  class="block transition duration-150 truncate"
                  :class="
                    isExactActive
                      ? 'text-indigo-500'
                      : 'text-slate-400 hover:text-slate-200'
                  "
                  :href="href"
                  @click="navigate"
                >
                  <span
                    class="text-sm font-medium lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200"
                    >Tooltip</span
                  >
                </a>
              </li>
            </router-link>
            <router-link
              to="/component/accordion"
              custom
              v-slot="{ href, navigate, isExactActive }"
            >
              <li class="mb-1 last:mb-0">
                <a
                  class="block transition duration-150 truncate"
                  :class="
                    isExactActive
                      ? 'text-indigo-500'
                      : 'text-slate-400 hover:text-slate-200'
                  "
                  :href="href"
                  @click="navigate"
                >
                  <span
                    class="text-sm font-medium lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200"
                    >Accordion</span
                  >
                </a>
              </li>
            </router-link>
            <router-link
              to="/component/icons"
              custom
              v-slot="{ href, navigate, isExactActive }"
            >
              <li class="mb-1 last:mb-0">
                <a
                  class="block transition duration-150 truncate"
                  :class="
                    isExactActive
                      ? 'text-indigo-500'
                      : 'text-slate-400 hover:text-slate-200'
                  "
                  :href="href"
                  @click="navigate"
                >
                  <span
                    class="text-sm font-medium lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200"
                    >Icons</span
                  >
                </a>
              </li>
            </router-link>
          </ul>
        </div>
      </SidebarLinkGroup>
      <!-- PLUs -->
      <SidebarLinkGroup v-slot="parentLink">
        <a
          class="block text-slate-200 truncate transition duration-150"
          :class="
            parentLink.expanded ? 'hover:text-slate-200' : 'hover:text-white'
          "
          href="#0"
          @click.prevent="
            parentLink.handleClick();
            sidebarExpanded = true;
          "
        >
          <div class="flex items-center justify-between">
            <div class="flex items-center">
              <font-awesome-icon
                icon="fa-duotone fa-binary-circle-check"
                class="w-6 h-6"
              />
              <span
                class="text-sm font-medium ml-3 lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200"
                >PLUs</span
              >
            </div>
            <!-- Icon -->
            <div class="flex shrink-0 ml-2">
              <font-awesome-icon
                :icon="[
                  'fas',
                  parentLink.expanded ? 'chevron-up' : 'chevron-down',
                ]"
                class="w-3 h-3"
              />
            </div>
          </div>
        </a>
        <div class="lg:hidden lg:sidebar-expanded:block 2xl:block">
          <ul class="pl-9 mt-1" :class="!parentLink.expanded && 'hidden'">
            <router-link to="/onboarding-01" custom v-slot="{ href, navigate }">
              <li class="mb-1 last:mb-0">
                <a
                  class="block text-slate-400 hover:text-slate-200 transition duration-150 truncate"
                  :href="href"
                  @click="navigate"
                >
                  <span
                    class="text-sm font-medium lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200"
                    >Step 1</span
                  >
                </a>
              </li>
            </router-link>
            <router-link to="/onboarding-02" custom v-slot="{ href, navigate }">
              <li class="mb-1 last:mb-0">
                <a
                  class="block text-slate-400 hover:text-slate-200 transition duration-150 truncate"
                  :href="href"
                  @click="navigate"
                >
                  <span
                    class="text-sm font-medium lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200"
                    >Step 2</span
                  >
                </a>
              </li>
            </router-link>
            <router-link to="/onboarding-03" custom v-slot="{ href, navigate }">
              <li class="mb-1 last:mb-0">
                <a
                  class="block text-slate-400 hover:text-slate-200 transition duration-150 truncate"
                  :href="href"
                  @click="navigate"
                >
                  <span
                    class="text-sm font-medium lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200"
                    >Step 3</span
                  >
                </a>
              </li>
            </router-link>
            <router-link to="/onboarding-04" custom v-slot="{ href, navigate }">
              <li class="mb-1 last:mb-0">
                <a
                  class="block text-slate-400 hover:text-slate-200 transition duration-150 truncate"
                  :href="href"
                  @click="navigate"
                >
                  <span
                    class="text-sm font-medium lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200"
                    >Step 4</span
                  >
                </a>
              </li>
            </router-link>
          </ul>
        </div>
      </SidebarLinkGroup>
    </ul>
  </div>
</template>

<script setup>
import { useRouter } from "vue-router";
import { storeToRefs } from "pinia";

import { useUIStore } from "../stores/ui";
import SidebarLinkGroup from "./SidebarLinkGroup.vue";
import { defineComponent } from "vue";

defineComponent({
  components: {
    SidebarLinkGroup,
  },
});

const currentRoute = useRouter().currentRoute.value;
const uiStore = useUIStore();

const { sidebarExpanded } = storeToRefs(uiStore);
</script>

import { io } from "socket.io-client";
import { bus } from "../events/bus";

const baseURL = import.meta.env.VITE_EVENTS_URL;

export const socket = io(baseURL, {
  transports: ["websocket"],
});

socket.on("connect", () => {
  bus.emit("socket-connected", socket);
});

socket.on("connect_error", (err) => {
  bus.emit("socket-connect-error", err);
});

socket.on("disconnect", () => {
  bus.emit("socket-disconnected", socket);
});

socket.onAny((event, data) => {
  bus.emit(`socket-${event}`, data);
});

<template>
  <div class="grow">
    <!-- Panel body -->
    <div class="p-6 space-y-6">
      <h2 class="text-2xl text-slate-800 dark:text-slate-100 font-bold mb-5">
        My Account
      </h2>
      <!-- Picture -->
      <section>
        <div class="flex items-center">
          <div class="mr-4">
            <img
              v-if="userStore?.details?.Picture"
              class="w-20 h-20 rounded-full"
              :src="userStore?.details?.Picture"
              width="80"
              height="80"
              alt="User upload"
            />
          </div>
          <button
            class="btn-sm bg-indigo-500 hover:bg-indigo-600 text-white"
            @click="handleOpen"
          >
            Change
          </button>
        </div>
      </section>
      <!-- Business Profile -->
      <section>
        <h3
          class="text-xl leading-snug text-slate-800 dark:text-slate-100 font-bold mb-1"
        >
          Business Profile
        </h3>
        <div class="text-sm">
          Excepteur sint occaecat cupidatat non proident, sunt in culpa qui
          officia deserunt mollit.
        </div>
        <div
          class="sm:flex sm:items-center space-y-4 sm:space-y-0 sm:space-x-4 mt-5"
        >
          <div class="sm:w-1/3">
            <label class="block text-sm font-medium mb-1" for="name"
              >Business Name</label
            >
            <input id="name" class="form-input w-full" type="text" />
          </div>
          <div class="sm:w-1/3">
            <label class="block text-sm font-medium mb-1" for="business-id"
              >Business ID</label
            >
            <input id="business-id" class="form-input w-full" type="text" />
          </div>
          <div class="sm:w-1/3">
            <label class="block text-sm font-medium mb-1" for="location"
              >Location</label
            >
            <input id="location" class="form-input w-full" type="text" />
          </div>
        </div>
      </section>
      <!-- Email -->
      <section>
        <h3
          class="text-xl leading-snug text-slate-800 dark:text-slate-100 font-bold mb-1"
        >
          Email
        </h3>
        <div class="text-sm">
          Excepteur sint occaecat cupidatat non proident sunt in culpa qui
          officia.
        </div>
        <div class="flex flex-wrap mt-5">
          <div class="mr-2">
            <label class="sr-only" for="email">Business email</label>
            <input id="email" class="form-input" type="email" />
          </div>
          <button
            class="btn border-slate-200 dark:border-slate-700 hover:border-slate-300 dark:hover:border-slate-600 shadow-sm text-indigo-500"
          >
            Change
          </button>
        </div>
      </section>
      <!-- Password -->
      <section>
        <h3
          class="text-xl leading-snug text-slate-800 dark:text-slate-100 font-bold mb-1"
        >
          Password
        </h3>
        <div class="text-sm">
          You can set a permanent password if you don't want to use temporary
          login codes.
        </div>
        <div class="mt-5">
          <button
            class="btn border-slate-200 dark:border-slate-700 hover:border-slate-300 dark:hover:border-slate-600 shadow-sm text-indigo-500"
          >
            Set New Password
          </button>
        </div>
      </section>
      <!-- Smart Sync -->
      <section>
        <h3
          class="text-xl leading-snug text-slate-800 dark:text-slate-100 font-bold mb-1"
        >
          Smart Sync update for Mac
        </h3>
        <div class="text-sm">
          With this update, online-only files will no longer appear to take up
          hard drive space.
        </div>
        <div class="flex items-center mt-5">
          <div class="form-switch">
            <input
              type="checkbox"
              id="toggle"
              class="sr-only"
              v-model="sync"
              true-value="On"
              false-value="Off"
            />
            <label class="bg-slate-400 dark:bg-slate-700" for="toggle">
              <span class="bg-white shadow-sm" aria-hidden="true"></span>
              <span class="sr-only">Enable smart sync</span>
            </label>
          </div>
          <div class="text-sm text-slate-400 dark:text-slate-500 italic ml-2">
            {{ sync }}
          </div>
        </div>
      </section>
    </div>
    <!-- Panel footer -->
    <footer>
      <div
        class="flex flex-col px-6 py-5 border-t border-slate-200 dark:border-slate-700"
      >
        <div class="flex self-end">
          <button
            class="btn dark:bg-slate-800 border-slate-200 dark:border-slate-700 hover:border-slate-300 dark:hover:border-slate-600 text-slate-600 dark:text-slate-300"
          >
            Cancel
          </button>
          <button class="btn bg-indigo-500 hover:bg-indigo-600 text-white ml-3">
            Save Changes
          </button>
        </div>
      </div>
    </footer>
    <dashboard-modal
      :uppy="uppy"
      :open="open"
      :props="{
        onRequestCloseModal: handleClose,
      }"
    />
  </div>
</template>

<script setup>
import { ref, computed } from "vue";
import { DashboardModal } from "@uppy/vue";
import Uppy from "@uppy/core";
import AwsS3 from "@uppy/aws-s3";
// import Dropbox from "@uppy/dropbox";

import { useUserStore } from "../../stores/user";

// Using ref for reactive variables
const open = ref(false);
const sync = ref("Off");
const userStore = useUserStore();

// Computed property with ref
const uppy = computed(() =>
  new Uppy({
    autoProceed: true,
    meta: {
      context: "user",
      intent: "profile-picture",
      uuid: userStore?.details?.UUID,
    },
    restrictions: {
      allowedFileTypes: ["image/*"],
      maxNumberOfFiles: 1,
      minNumberOfFiles: 1,
    },
  })
    // .use(Dropbox, {
    //   companionUrl: "https://uppy.manypenny.ca",
    // })
    .use(AwsS3, {
      allowedMetaFields: ["context", "intent", "uuid"],
      companionUrl: "https://uppy.manypenny.ca",
      shouldUseMultipart: (file) => file.size > 100 * 2 ** 20,
    }),
);

// Methods
function handleOpen() {
  open.value = true;
}

function handleClose() {
  open.value = false;
}
</script>
